<template>
	<svg xmlns="http://www.w3.org/2000/svg">
		<slot></slot>
	</svg>
</template>

<script>
export default {
	name: "BaseSvg",
	props: {}
};
</script>
