<template>
	<div>
		<div class="tw-flex tw-items-center tw-justify-between">
			<h4
				class="tw-font-Poppins-text tw-text-22 tw-font-semibold tw-leading-7"
			>
				{{ $t('dashboard') }}
			</h4>
			<div>
				<!-- <v-select
					v-model="selectedType"
					:items="['viewReports', 'manageStore']"
				>
				</v-select> -->
				<!-- <v-btn
					:color="selectedType === 'viewReports' ? '#722432' : '#D8D8D8'"
					@click="selectedType = 'viewReports'"
					:outlined="selectedType == 'manageStore'"
					class=" lg:tw-h-12 tw-me-4"
					depressed
				>
					<span
						:class="
							selectedType === 'viewReports' ? 'tw-text-white' : 'tw-text-black'
						"
						class="tw-text-xs"
						>View Reports</span
					>
				</v-btn>
				<v-btn
					:color="selectedType === 'manageStore' ? '#722432' : '#D8D8D8'"
					@click="selectedType = 'manageStore'"
					class=" lg:tw-h-12"
					:outlined="selectedType == 'viewReports'"
					depressed
				>
					<span
						:class="
							selectedType === 'manageStore' ? 'tw-text-white' : 'tw-text-black'
						"
						class="tw-text-xs"
						>Manage Store</span
					>
				</v-btn> -->
			</div>
		</div>
		<v-row>
			<v-col cols="12" lg="8">
				<div id="chart">
					<div id="chart">
						<apexchart
							type="line"
							height="360"
							:options="customerChartOptions"
							:series="customerSeries"
						></apexchart>
					</div>
				</div>
			</v-col>
			<v-col cols="12" lg="4" class="tw-p-7">
				<h5 class="tw-font-Poppins-text tw-font-medium tw-text-base">
					Quick Stats
				</h5>
				<div
					class="tw-flex tw-items-center tw-rounded-lg tw-bg-fcf tw-p-3.5 tw-mt-6"
				>
					<div class="tw-px-4 tw-rounded-md tw-py-5 tw-bg-FF tw-me-7">
						<img src="../../assets/images/quick-stats-sales.png" alt="" />
					</div>
					<div class="tw-flex tw-flex-col tw-space-y-0">
						<h2 class="tw-font-Rubik-text tw-font-medium tw-text-2xl">
							2,870
						</h2>
						<span class="tw-font-Poppins-text tw-text-11px"
							>Sales this month</span
						>
					</div>
				</div>
				<div
					class="tw-flex tw-items-center tw-rounded-lg tw-bg-fcf tw-p-3.5 tw-mt-3"
				>
					<div class="tw-px-4 tw-rounded-md tw-py-5 tw-bg-FF tw-me-7">
						<img src="../../assets/images/quick-stats-sales.png" alt="" />
					</div>
					<div class="tw-flex tw-flex-col tw-space-y-0">
						<h2 class="tw-font-Rubik-text tw-font-medium tw-text-2xl">
							13,293
						</h2>
						<span class="tw-font-Poppins-text tw-text-11px"
							>New Users</span
						>
					</div>
				</div>
				<div
					class="tw-flex tw-items-center tw-rounded-lg tw-bg-fcf tw-p-3.5 tw-mt-3"
				>
					<div class="tw-px-4 tw-rounded-md tw-py-5 tw-bg-FF tw-me-7">
						<img src="../../assets/images/quick-stats-sales.png" alt="" />
					</div>
					<div class="tw-flex tw-flex-col tw-space-y-0">
						<h2 class="tw-font-Rubik-text tw-font-medium tw-text-2xl">
							$ 3,928
						</h2>
						<span class="tw-font-Poppins-text tw-text-11px"
							>Earned Today</span
						>
					</div>
				</div>
				<div
					class="tw-flex tw-items-center tw-rounded-lg tw-bg-fcf tw-p-3.5 tw-mt-3"
				>
					<div class="tw-px-4 tw-rounded-md tw-py-5 tw-bg-FF tw-me-7">
						<img src="../../assets/images/quick-stats-sales.png" alt="" />
					</div>
					<div class="tw-flex tw-flex-col tw-space-y-0">
						<h2 class="tw-font-Rubik-text tw-font-medium tw-text-2xl">
							$ 72,948
						</h2>
						<span class="tw-font-Poppins-text tw-text-11px"
							>Total Balance</span
						>
					</div>
				</div>
			</v-col>
			<v-col cols="12" lg="8">
				<div id="chart">
					<apexchart
						type="bar"
						height="360"
						:options="profitChartOptions"
						:series="profitSeries"
					></apexchart>
				</div>
			</v-col>
			<v-col cols="12" lg="4"> </v-col>
		</v-row>

		<!-- create login page -->
		<!-- <form action=""></form> -->
	</div>
</template>

<script>
export default {
	name: 'supplierDashboard',
	data() {
		return {
			selectedType: 'viewReports',
			customerSeries: [
				{
					name: 'Returning',
					data: [
						1230, 1531, 1380, 860, 1431, 1335, 1133, 1490, 962, 1069, 291,
						148,
					],
				},
				{
					name: 'New Comers',
					data: [
						230, 131, 980, 860, 431, 1335, 1133, 1490, 962, 69, 291, 148,
					],
				},
				{
					name: 'Abandoned',
					data: [
						30, 31, 98, 186, 1431, 335, 1133, 1490, 1162, 1169, 291, 148,
					],
				},
			],
			customerChartOptions: {
				chart: {
					height: 350,
					type: 'line',
					zoom: {
						enabled: false,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: 'smooth',
				},

				grid: {
					show: false,
				},
				xaxis: {
					categories: [
						'Jan',
						'Feb',
						'Mar',
						'Apr',
						'May',
						'Jun',
						'Jul',
						'Aug',
						'Sep',
						'Oct',
						'Nov',
						'Dec',
					],
				},
			},
			profitSeries: [
				{
					name: 'Net Profit',
					data: [4, 5, 15, 15, 6, 58, 56, 42, 86, 67, 45, 37],
					color: '#F2CA51',
				},
			],
			profitChartOptions: {
				chart: {
					type: 'bar',
					height: 350,
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '12px',
						endingShape: 'rounded',
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					show: true,
					width: 2,
					colors: ['transparent'],
				},
				xaxis: {
					categories: [
						'Jan',
						'Feb',
						'Mar',
						'Apr',
						'May',
						'Jun',
						'Jul',
						'Aug',
						'Sep',
						'Oct',
						'Nov',
						'Dec',
					],
				},

				tooltip: {
					y: {
						formatter: function (val) {
							return val + '%'
						},
					},
				},
			},
		}
	},
}
</script>

<style></style>
