var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"default-layout",attrs:{"app":""}},[_c('AppBar',{attrs:{"title":_vm.$t('admin'),"theme":_vm.$theme,"isAuth":_vm.$isAuth,"loading":_vm.$loading},on:{"drawer":_vm.toggleDrawer},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"tw-flex tw-items-center tw-me-20 tw-space-s-7"},[(_vm.$currentUser.isBuyer)?_c('cart-list',{attrs:{"dropdown":""}}):_vm._e(),(_vm.$currentLanguage)?_c('v-btn',{attrs:{"icon":"","width":"26","height":"26"},on:{"click":_vm.onInitLanguageChange}},[_c('v-avatar',{attrs:{"size":"26"}},[_c('BaseFlag',{staticClass:"tw-rounded-full",attrs:{"alt":_vm.$currentLanguage.label,"code":_vm.$currentLanguage.flag,"width":"26","height":"26"}})],1)],1):_vm._e()],1),(_vm.$currentUser)?_c('v-menu',{staticClass:"tw-z-20",attrs:{"close-on-content-click":false,"right":"","offset-y":"","rounded":"b","width":"100%","max-width":"350","nudge-bottom":"8","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('AppBarMenuActivator',_vm._g(_vm._b({},'AppBarMenuActivator',attrs,false),on))]}}],null,false,3751753757),model:{value:(_vm.menuCurrentUser),callback:function ($$v) {_vm.menuCurrentUser=$$v},expression:"menuCurrentUser"}},[_c('v-card',[_c('AppBarMenuCurrentUser'),_c('v-divider'),_c('v-list',{staticClass:"transparent",attrs:{"dense":""}},[_c('DialogFormCurrentUser',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-outline")])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s('Profile')}})],1)]}}],null,false,3772186402),model:{value:(_vm.dialogCurrentUser),callback:function ($$v) {_vm.dialogCurrentUser=$$v},expression:"dialogCurrentUser"}}),(!_vm.$currentUser.isAdmin)?_c('v-list-item',{attrs:{"to":_vm.routes.find(function (route) {
									return route.path.includes('orders')
								}).path},on:{"click":function($event){_vm.menuCurrentUser = false}}},[_c('v-list-item-icon',[_c('icon-orders')],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s('My Orders')}})],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.onOpenSettings}},[_c('v-list-item-icon',[_c('icon-settings')],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s('Settings')}})],1),_c('v-divider',{staticClass:"my-1"}),_c('v-list-item',{attrs:{"disabled":_vm.$loading},on:{"click":_vm.handleLogout}},[_c('v-list-item-icon',[_c('icon-exit')],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Logout')}})],1)],1)],1)],1)],1):_vm._e()]},proxy:true}])}),_c('v-main',{staticClass:"default-layout__main",class:_vm.$theme.dark ? 'darken-4' : 'lighten-1'},[_c('div',{staticClass:"tw-flex h-content tw-overflow-hidden"},[(_vm.isContractSigned && !_vm.$currentUser.isPending)?_c('Sidebar',{staticClass:"sidebar tw-flex-none tw-w-80",attrs:{"user":_vm.$currentUser,"xs":_vm.$vuetify.breakpoint.xs},on:{"drawer":function($event){return _vm.toggleDrawer($event)}}},[_c('SidebarNavList',{staticClass:"scrollbar-y tw-h-full",attrs:{"br":"","items":_vm.routes},scopedSlots:_vm._u([{key:"route",fn:function(ref){
								var route = ref.route;
return [_c('SidebarNavListItem',{key:route.name,attrs:{"exact":_vm.isExact(route),"to":route.path,"name":route.name,"icon":route.icon,"icon-component":route.iconComponent,"hasChildrens":route.childrens && route.childrens.length},on:{"click":function($event){return _vm.onClickSidebarNavListItem(route)}}},[_c('SidebarNavList',{attrs:{"items":route.childrens},scopedSlots:_vm._u([{key:"route",fn:function(ref){
								var child = ref.route;
return [_c('SidebarNavListItem',{key:child.name,attrs:{"exact":_vm.isExact(route),"name":child.name,"icon":child.icon,"icon-component":route.iconComponent,"to":(route.path || '') + child.path}})]}}],null,true)})],1)]}}],null,false,3225973347)})],1):_vm._e(),_c('router-wrapper',{staticClass:"tw-pb-2 lg:tw-pb-10"},[_c('router-view',{staticClass:"router-view tw-flex-1 tw-px-4 md:tw-px-8 lg:tw-px-20 tw-flex tw-flex-col tw-overflow-auto"}),_vm._t("default")],2)],1)]),(_vm.$currentUser.isBuyer)?_c('cart-preview'):_vm._e(),_c('dialog-settings'),_c('dialog-language'),_c('dialog-category'),_c('dialog-logout')],1)}
var staticRenderFns = []

export { render, staticRenderFns }