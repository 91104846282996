<template>
	<v-dialog
		v-model="vModel"
		scrollable
		max-width="1556"
		content-class="tw-rounded-xl md:tw-rounded-29px"
	>
		<v-card class="tw-p-4 md:tw-px-11 md:tw-py-10">
			<v-card-text class="tw-p-0 tw-overflow-x-hidden tw-pe-2">
				<v-row>
					<v-col cols="12" md="7" class="tw-pe-8">
						<h4
							class="tw-font-pop tw-text-22 tw-font-semibold tw-text-black"
						>
							{{ $t('shopping-cart') }}
						</h4>
						<cart-list
							hide-title
							hide-actions
							width="100%"
							class="tw--ms-8"
							:inline="$vuetify.breakpoint.mdAndUp"
						/>

						<h4
							class="tw-font-pop tw-text-22 tw-font-semibold tw-text-black tw-mt-6"
						>
							{{ $t('orderSummary') }}
						</h4>

						<!-- COUPON CODE -->
						<!-- <div class="tw-flex tw-mt-6">
							<v-text-field
								solo
								flat
								outlined
								height="60"
								hide-details="auto"
								class="tw-flex-1 tw-text-black custom-outlined"
								placeholder="Enter The Coupon"
							></v-text-field>
							<v-btn
								depressed
								color="#F2CA51"
								width="145"
								height="60"
								dark
								class="tw-flex-none tw--ml-1 tw-normal-case tw-text-base"
								>Apply</v-btn
							>
						</div> -->

						<!-- PRICE SUMMARY -->
						<div
							class="tw-w-full tw-flex tw-flex-col tw-space-y-4 tw-mt-6"
						>
							<h3 class="tw-flex tw-justify-between">
								<span class="tw-text-beb tw-text-lg tw-font-normal">
									{{ $t('subTotal') }}
								</span>
								<span
									class="tw-text-lg tw-font-rubi tw-text-black tw-font-light"
								>
									{{ $t('sar') }}
									{{ $commafy($summary.subtotal.toFixed(2)) }}
								</span>
							</h3>
							<h3 class="tw-flex tw-justify-between tw-mt-1">
								<span class="tw-text-beb tw-text-lg tw-font-normal">
									{{ $t('shippingFree') }}
								</span>
								<span
									class="tw-text-lg tw-font-rubi tw-font-light tw-uppercase tw-text-bad"
								>
									{{ $t('free') }}
								</span>
							</h3>
							<h3 class="tw-flex tw-justify-between">
								<span class="tw-text-beb tw-text-lg tw-font-normal">
									VAT (15%)
								</span>
								<span
									class="tw-text-lg tw-font-rubi tw-text-black tw-font-light"
								>
									{{ $t('sar') }}
									{{ $commafy($summary.vat.toFixed(2)) }}
								</span>
							</h3>
							<v-divider class="tw-border-70 tw-opacity-10 tw-my-5" />

							<h3 class="tw-flex tw-justify-between">
								<span class="tw-text-beb tw-text-lg tw-font-normal">
									Total
								</span>
								<span
									class="tw-text-lg tw-font-rubi tw-text-black tw-font-light"
								>
									{{ $t('sar') }}
									{{ $commafy($summary.total.toFixed(2)) }}
								</span>
							</h3>
							<h3 class="tw-flex tw-justify-between tw-mt-1">
								<span class="tw-text-beb tw-text-lg tw-font-normal">
									{{ $t('discount') }}
								</span>
								<span
									class="tw-text-lg tw-font-rubi tw-text-black tw-font-light error--text"
								>
									- {{ $t('sar') }}
									{{ $commafy($summary.discount.toFixed(2)) }}</span
								>
							</h3>
							<v-divider
								class="tw-border-70 tw-opacity-10 tw-my-5"
							></v-divider>
							<h3 class="tw-flex tw-justify-between tw-items-center">
								<div>
									<span class="tw-text-beb tw-text-lg tw-font-normal">
										{{ $t('total-price') }}
									</span>
									<span class="tw-text-F2 tw-text-2xl tw-mx-2">•</span>
									<span class="tw-text-beb tw-text-lg tw-font-normal">
										{{ $t('inclusive-of-value-added-tax') }}
									</span>
								</div>
								<span
									class="tw-font-rubi tw-text-black tw-font-medium tw-text-xl"
								>
									{{ $t('sar') }}
									{{ $commafy($summary.grandTotal.toFixed(2)) }}
								</span>
							</h3>
						</div>

						<!-- CARD LOGOS -->
						<div class="tw-flex tw-space-s-3 tw-mt-5">
							<v-img
								width="48"
								class="tw-flex-none"
								src="@/assets/images/logo-maestro.png"
							/>
							<v-img
								width="48"
								class="tw-flex-none"
								src="@/assets/images/logo-master-card.png"
							/>
							<v-img
								width="48"
								class="tw-flex-none"
								src="@/assets/images/logo-visa.png"
							/>
						</div>
					</v-col>
					<v-col cols="12" md="5" class="tw-ps-8">
						<v-form ref="formRef">
							<h4
								class="tw-font-pop tw-text-22 tw-font-semibold tw-text-black"
							>
								{{ $t('shippingAddress') }}
							</h4>

							<v-layout class="tw-mt-14 tw-space-x-4">
								<v-autocomplete
									v-model="form.selectedAddress"
									:items="addresses"
									:placeholder="$t('selectAddress')"
									:rules="[rules.required($t('address'))]"
									solo
									flat
									outlined
									return-object
									item-value="id"
									item-text="label"
									hide-details="auto"
									class="custom-outlined"
								>
									<template #item="{ item, on, attrs }">
										<v-list-item
											v-bind="attrs"
											class="tw-cursor-pointer"
										>
											<v-list-item-content v-on="on">
												<v-list-item-title>
													{{ item.label }}
												</v-list-item-title>
											</v-list-item-content>
											<v-list-item-action>
												<v-btn
													:loading="deletingAddressId === item.id"
													icon
													color="error"
													@click="onDeleteAddress(item)"
												>
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</v-list-item-action>
										</v-list-item>
									</template>
								</v-autocomplete>
								<v-btn
									:height="$vuetify.breakpoint.lgAndUp ? 56 : ''"
									depressed
									color="secondary"
									class="tw-flex-none"
									@click="onInitNewAddress"
								>
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</v-layout>

							<div
								v-if="form.selectedAddress"
								class="tw-flex tw-items-center tw-justify-between tw-mt-7"
							>
								<div
									class="tw-font-pop tw-text-black tw-font-normal tw-text-base"
								>
									<div>{{ form.selectedAddress.streetName }}</div>
									<div>{{ form.selectedAddress.city }}</div>
									<div>UAE</div>
								</div>
								<div>
									<v-btn
										tile
										icon
										outlined
										color=""
										width="44"
										height="40"
										class="tw-rounded-md tw-border-E9"
										@click="onInitUpdateAddress"
									>
										<v-icon color="black" size="22"
											>mdi-pencil</v-icon
										>
									</v-btn>
								</div>
							</div>

							<!-- ORDER NOTE -->
							<h4
								class="tw-font-pop tw-text-22 tw-font-semibold tw-text-black tw-mt-9"
							>
								{{ $t('orderNote') }}
							</h4>
							<v-textarea
								v-model="orderNote"
								:rows="6"
								:placeholder="$t('الرجاء إضافة ملاحظات طلبك')"
								solo
								flat
								outlined
								hide-details="auto"
								class="custom-outlined tw-mt-6"
							/>

							<!-- DELIVERY TIME -->
							<h4
								class="tw-font-pop tw-text-22 tw-font-semibold tw-text-black tw-mt-9"
							>
								{{ $t('deliveryTime') }}
							</h4>
							<v-select
								v-model="form.deliveryTime"
								:items="['Morning', 'Afternoon']"
								:placeholder="$t('selectDeliveryTime')"
								solo
								flat
								outlined
								hide-details="auto"
								class="custom-outlined tw-mt-6"
							/>
						</v-form>
					</v-col>
				</v-row>
			</v-card-text>

			<v-card-actions class="tw-p-0 tw-mt-8">
				<base-button
					block
					height="54"
					color="primary"
					:loading="isLoading"
					@click="onPlaceOrder"
					>{{ $t('confirmAndPlace') }}</base-button
				>
			</v-card-actions>
		</v-card>

		<DialogAddressForm />
	</v-dialog>
</template>

<script>
import api from '../api'
import CartList from './CartList.vue'
import DialogAddressForm from './dialogs/DialogAddressForm.vue'

import { omitEmpties } from 'vuelpers'
import { i18n } from '../plugins/vue-i18n'
import { mapActions, mapGetters } from 'vuex'
import { PAYTAB_PAYMENT_TYPE } from '../consts'
import { createFormMixin } from '../mixins/form-mixin'

export default {
	name: 'CartPreview',
	mixins: [
		createFormMixin({
			rules: ['required'],
		}),
	],
	components: {
		CartList,
		DialogAddressForm,
	},
	data: () => ({
		orderNote: '',
		isLoading: false,
		deletingAddressId: null,
		form: {
			selectedAddress: null,
			deliveryTime: i18n.t('morning'),
		},
	}),
	created() {
		this.getAddresses()
	},
	watch: {
		$cartItemsCount(val) {
			if (val == 0) {
				this.vModel = false
			}
		},
	},
	computed: {
		...mapGetters('settings', ['$addresses']),
		...mapGetters('cart', [
			'$cart',
			'$preview',
			'$summary',
			'$cartItemsCount',
		]),
		vModel: {
			get() {
				return this.$preview.dialog
			},
			set(v) {
				this.$store.commit('cart/SET', {
					'preview.dialog': v,
				})
			},
		},
		addresses() {
			return this.$addresses.data.map((addr) => ({
				...addr,
				label: `${addr.streetName}, ${addr.city}, ${addr.postalCode}`,
			}))
		},
	},
	methods: {
		...mapActions('orders', ['placeOrder']),
		...mapActions('settings', [
			'getAddresses',
			'deleteAddress',
			'setSettingsState',
		]),
		async onDeleteAddress(address) {
			this.deletingAddressId = address.value
			await this.deleteAddress(address.value)
			this.deletingAddressId = null
		},
		onInitNewAddress() {
			this.setSettingsState({
				addressForm: (form) => ({
					...form,
					isUpdate: false,
					isVisible: true,
				}),
			})
		},
		onInitUpdateAddress() {
			this.setSettingsState({
				addressForm: (form) => ({
					...form,
					isUpdate: true,
					isVisible: true,
					data: this.form.selectedAddress,
				}),
			})
		},
		getOffer(item) {
			if (this.isProduct(item)) {
				return item.cartable.offers.find((offer) => {
					return offer.isSingleProductOffer
				})
			}
			return item.cartable
		},
		isProduct(item) {
			return item.cartableType.toLowerCase().includes('product')
		},
		getPrice(carItem) {
			let offer = this.getOffer(carItem)
			return offer ? offer.finalPrice : carItem.cartable.price
		},
		async onPlaceOrder() {
			if (!this.$refs.formRef.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}
			this.isLoading = true
			let [err, res] = await this.placeOrder(
				omitEmpties({
					note: this.orderNote,
					paymentMethod: 'paytab',
					deliveryTime: this.form.deliveryTime,
					totalPaid: this.$summary.grandTotal,
					shippingAddress: {
						shippingCharge: 0,
						id: this.form.selectedAddress?.id,
					},
					products: this.$cart.products.data.map((cartItem) => {
						return {
							id: cartItem.cartable.id,
							quantity: cartItem.quantity,
							price: this.getPrice(cartItem),
						}
					}),
					offers: this.$cart.offers.data.map((cartItem) => {
						return {
							id: cartItem.cartable.id,
							quantity: cartItem.quantity,
							price: cartItem.cartable.finalPrice,
						}
					}),
				})
			)
			this.isLoading = false
			!err && this.onClickPayWithPaytab(res.order)
		},
		async onClickPayWithPaytab(order) {
			this.isLoading = true
			let [err, res] = await api.post('payment/create', {
				currency: 'SAR',
				orderId: order.id,
				type: PAYTAB_PAYMENT_TYPE.CART,
				shippingAddressId: order.shippingAddress[0].id,
			})
			this.isLoading = false
			this.setSettingsState({
				currentPayment: {
					tranRef: res.tranRef,
					type: PAYTAB_PAYMENT_TYPE.CART,
				},
			})
			!err && location.replace(res.url)
		},
	},
}
</script>
