import { render, staticRenderFns } from "./SidebarNavList.vue?vue&type=template&id=4da3d0d0&scoped=true&"
import script from "./SidebarNavList.vue?vue&type=script&lang=js&"
export * from "./SidebarNavList.vue?vue&type=script&lang=js&"
import style0 from "./SidebarNavList.vue?vue&type=style&index=0&id=4da3d0d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4da3d0d0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
installComponents(component, {VList,VListItemGroup})
