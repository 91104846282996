import API, { Supplier } from '../../api'

import { omit } from 'lodash'
import { initialList } from '../store.helpers'
import { createGetters, createMutations, handleAction } from 'vuelpers'

const initialState = () => {
	return {
		offer: {},
		buyerCount: 0,
		currentOfferId: null,
		offers: initialList(),
		suppliers: initialList(),
		marketingOffers: initialList(),
	}
}

const state = initialState()
const mutations = createMutations(
	'SET',
	'PUSH',
	'UNSHIFT',
	'DELETE',
	'UPDATE',
	'MERGE'
)
const getters = createGetters(
	'offers',
	'suppliers',
	'buyerCount',
	'marketingOffers',
	{
		offer(state) {
			if (state.offer && state.offer.id) return state.offer
			return state.offers.data.find((offer) => {
				return offer.id == state.currentOfferId
			})
		},
	}
)

const actions = {
	setOffersState({ commit }, payload) {
		commit('SET', payload)
	},
	createOffer({ commit }, payload) {
		return handleAction(API.offers.post(payload), (res) => {
			commit('UNSHIFT', { 'offers.data': res.offer })
		})
	},
	deleteOffer({ commit }, payload = {}) {
		return handleAction(API.offers.delete(payload), () => {
			commit('DELETE', ['offers.data', payload])
		})
	},
	updateOffer({ commit }, payload = {}) {
		return handleAction(API.offers.patch(payload), (res) => {
			commit('UPDATE', ['offers.data', res.offer])
		})
	},
	getOffer({ commit }, payload = {}) {
		return handleAction(
			API.offers.getById(payload.id, payload.query),
			(res) => {
				console.log('getOffer', res.offer)
				commit('SET', { offer: res.offer })
			}
		)
	},
	getOffers({ commit }, payload = {}) {
		return handleAction(API.offers.get(payload), (res) => {
			commit('SET', { offers: res.offers })
		})
	},
	getRunningOffers({ commit, state: { offers } }, payload = {}) {
		// LOADERS
		commit('SET', { 'offers.isRefreshing': true })
		if (!offers.data.length) {
			commit('SET', { 'offers.isLoading': true })
		}

		const paginate = payload.paginate || false
		delete payload.paginate

		return handleAction(API.offers.getRunning(payload), (res) => {
			commit('SET', {
				offers: (v) => ({
					...v,
					...(!paginate ? res.offers : omit(res.offers, 'data')),
					isLoaded: true,
					isLoading: false,
					isRefreshing: false,
				}),
			})
			if (paginate) {
				commit('MERGE', ['offers.data', res.offers.data, 'id', 'push'])
			}
		})
	},
	createMarketingOffer({ commit }, payload) {
		return handleAction(API.offers.Marketing.post(payload), (res) => {
			commit('PUSH', { 'marketingOffers.data': res.marketingOffer })
		})
	},
	deleteMarketingOffer({ commit }, payload = {}) {
		return handleAction(API.offers.Marketing.delete(payload), () => {
			commit('DELETE', ['marketingOffers.data', payload])
		})
	},
	updateMarketingOffer({ commit }, payload = {}) {
		return handleAction(API.offers.Marketing.patch(payload), (res) => {
			commit('UPDATE', ['marketingOffers.data', res.marketingOffer])
		})
	},
	getMarketingOffer({ commit }, payload = {}) {
		return handleAction(
			API.offers.Marketing.getById(payload.id, payload.query),
			(res) => {
				commit('UNSHIFT', ['marketingOffers.data', res.marketingOffer])
			}
		)
	},
	getMarketingOffers({ commit }, payload = {}) {
		return handleAction(API.offers.Marketing.get(payload), (res) => {
			commit('SET', { marketingOffers: res.marketingOffers })
		})
	},
	countBuyer({ commit }, payload = {}) {
		return handleAction(API.offers.Marketing.countBuyer(payload), (res) => {
			commit('SET', { buyerCount: res.buyerCount })
		})
	},
	resetState({ commit }) {
		commit('SET', initialState())
	},
	getSuppliers({ commit }, payload = {}) {
		commit('SET', {
			suppliers: (v) => ({
				...v,
				isLoading: !v.isLoaded,
				isRefetching: v.isLoaded,
			}),
		})
		return handleAction(Supplier.get(payload), (res) => {
			commit('SET', {
				suppliers: (v) => {
					return {
						...v,
						...omit(res.suppliers, 'data'),
						data:
							payload.page === 1
								? res.suppliers.data
								: [...v.data, ...res.suppliers.data],
						isLoaded: true,
						isLoading: false,
						isRefetching: false,
					}
				},
			})
		})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
