import axios from 'axios'
import API from '../../api'

import { useOrder } from '../../mixins'
import { _serverBaseURL } from '../../consts'
import { initialList } from '../store.helpers'

import {
	createElement,
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'

const initialState = () => {
	return {
		currentOrder: {
			paytabTranRef: null,
		},
		orders: initialList(),
	}
}

const state = initialState()

const getters = {
	...createGetters('orders', 'currentOrder'),
}

const mutations = {
	...createMutations('SET', 'PUSH', 'UNSHIFT', 'DELETE', 'UPDATE', 'MERGE'),
}

const actions = {
	setOrderState({ commit }, payload) {
		commit('SET', payload)
	},
	placeOrder({ commit }, payload) {
		commit('SET', { 'orders.isRefreshing': true })
		return handleAction(API.orders.post(payload), (res) => {
			commit('PUSH', { 'orders.data': res.order })
			commit('SET', { 'orders.isRefreshing': false })
		})
	},
	deleteOrder({ commit }, payload = {}) {
		return handleAction(API.orders.delete(payload), () => {
			commit('DELETE', ['orders.data', payload])
			commit('SET', { 'orders.isRefreshing': false })
		})
	},
	updateOrder({ commit }, payload = {}) {
		return handleAction(API.orders.patch(payload), (res) => {
			commit('UPDATE', ['orders.data', res.order])
		})
	},
	changeStatusOfOrder({ commit }, payload = {}) {
		commit('SET', { 'orders.isRefreshing': true })
		return handleAction(API.orders.changeStatus(payload), (res) => {
			commit('UPDATE', ['orders.data', res.order])
			commit('SET', { 'orders.isRefreshing': false })
		})
	},
	getOrder({ commit }, payload = {}) {
		commit('SET', { 'orders.isRefreshing': true })
		return handleAction(
			API.orders.getById(payload.id, payload.query),
			(res) => {
				commit('MERGE', ['orders.data', [useOrder(res.order)]])
				commit('SET', { 'orders.isRefreshing': false })
			}
		)
	},
	getOrders({ commit, state: { orders } }, payload = {}) {
		commit('SET', {
			orders: (v) => ({
				...v,
				isLoading: !orders.isLoaded,
				isRefreshing: orders.isLoaded,
			}),
		})
		return handleAction(API.orders.get(payload), (res) => {
			commit('SET', {
				orders: (v) => ({
					...v,
					...res.orders,
					data: res.orders.data.map(useOrder),
					isLoaded: true,
					isLoading: false,
					isRefreshing: false,
				}),
			})
		})
	},
	printOrder({ rootState }, payload) {
		return axios({
			url: `${_serverBaseURL}/api/purchase/order/print/${payload}`, 
			method: 'GET',
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${rootState.auth.accessToken}`,
			},
		})
			.then((response) => {
				const href = URL.createObjectURL(response.data)
				const attrs = { href, download: 'file.pdf' }
				createElement('a', attrs).click()
			})
			.catch((e) => {
				console.log('printOrder::ERR', e)
			})
	},
	resetState({ commit }) {
		commit('SET', initialState())
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
