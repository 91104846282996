import api from './base.api'

const Addresses = {
	get: (query) => api.get('/buyer/shipping-address', query),
	post: (body) => api.post('/buyer/shipping-address/create', body),
	update: (body) => api.post('/buyer/shipping-address/update', body),
	delete: (id) => api.delete('/buyer/shipping-address/delete/' + id),
}

export { Addresses }
