<template>
	<span>
		<slot :on="{ click: onSwitchTheme }">
			<v-btn icon height="26" width="26" @click="onSwitchTheme">
				<v-icon v-if="isDark"> mdi-weather-night </v-icon>
				<v-icon v-else> mdi-weather-sunny </v-icon>
			</v-btn>
		</slot>
	</span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'BaseThemeSwitcher',
	created() {
		this.$vuetify.theme.isDark = this.$theme.dark
	},
	computed: {
		...mapGetters(['$theme']),
		isDark() {
			return this.$vuetify.theme.isDark
		}
	},
	methods: {
		...mapActions(['toggleTheme']),
		onSwitchTheme() {
			// const isDark = !this.$vuetify.theme.isDark
			// this.$vuetify.theme.isDark = isDark
			// this.toggleTheme(isDark)
		}
	}
}
</script>
