import { upperCase } from 'lodash'

const useInitial = (name = '') => {
	return upperCase(
		name
			.split(' ')
			.reduce((initial, word) => {
				return initial + word.charAt(0)
			}, '')
			.substring(0, 2)
	)
}

export { useInitial }
