<template>
	<v-dialog
		v-model="dialog"
		persistent
		max-width="676"
		content-class="tw-rounded-3xl tw-bg-white"
	>
		<template #activator="{ on, attrs }">
			<slot name="activator" v-bind="{ on, attrs }" />
		</template>

		<v-card elevation="0" class="tw-p-0 tw-mx-0">
			<div class="tw-text-center tw-py-5 tw-px-6 lg:tw-py-10 lg:tw-px-9">
				<h1 class="tw-text-2xl tw-font-semibold">
					{{ title || 'Confirmation Message' }}
				</h1>
				<v-divider class="tw-mt-4 tw-mx-16" />

				<div
					class="tw-text-black tw-text-opacity-60 tw-mt-6 tw-mb-9 xl:tw-mt-8 xl:tw-mb-14 tw-text-center tw-text-lg"
				>
					<slot></slot>
				</div>
				<v-card-actions class="tw-flex tw-space-s-4">
					<v-btn
						depressed
						outlined
						color="#DF9999"
						@click="dialog = false"
						class="tw-h-14 tw-flex-1 tw-rounded-lg"
					>
						<span class="tw-capitalize tw-text-lg tw-font-medium">
							{{ noText }}
						</span>
					</v-btn>
					<v-btn
						:loading="isLoading"
						dark
						depressed
						color="#DF9999"
						class="tw-h-14 tw-flex-1 tw-rounded-lg"
						@click="onConfirm"
					>
						<span class="tw-capitalize tw-text-lg tw-font-medium">
							{{ yesText }}
						</span>
					</v-btn>
				</v-card-actions>
			</div>
		</v-card>
	</v-dialog>
</template>
<script>
import { i18n } from '@/plugins/vue-i18n'
export default {
	name: 'BaseConfirmation',
	props: {
		title: {
			type: String,
		},
		value: {
			default: false,
		},
		handler: {
			type: Function,
		},
		yesText: {
			type: String,
			default: i18n.t('actions.yes'),
		},
		noText: {
			type: String,
			default: i18n.t('actions.no'),
		},
	},
	data: () => ({
		mValue: false,
		isLoading: false,
	}),
	computed: {
		dialog: {
			get() {
				return this.mValue
			},
			set(v) {
				this.mValue = v
				this.$emit('input', v)
			},
		},
		mHandler() {
			return typeof this.value === 'function'
				? this.value
				: this.handler ?? (() => [false])
		},
	},
	watch: {
		value: {
			immediate: true,
			handler(v) {
				const value = !!v
				if (value === this.mValue) return
				this.mValue = value
			},
		},
	},
	methods: {
		async onConfirm() {
			this.isLoading = true
			await this.mHandler()
			this.isLoading = false

			this.$emit('done')
			// if (!err) {
			// 	this.dialog = false

			// 	// this.$emit('deleted', res)
			// 	// this.$toast.success(this.$t('success-delete', [this.title]))
			// 	this.$toast.success()
			// 	return
			// }
			// this.$toast.error()
		},
	},
}
</script>
