<template>
	<v-dialog v-model="vModel" max-width="648" content-class="tw-rounded-29px">
		<v-card>
			<v-card-title
				class=" tw-pb-0 tw-pt-12 tw-text-22 tw-font-pop tw-font-semibold tw-justify-center tw-text-black"
			>
				{{ $t('settings') }}
			</v-card-title>
			<v-card-text class="tw-p-0 tw-px-10 tw-pb-8">
				<v-list class="tw-p-0 tw-mt-6">
					<template v-for="(item, itemIndex) in items">
						<div
							v-if="item.subheader"
							:key="'subheader-' + itemIndex"
							class=" tw-text-base tw-font-pop tw-font-normal tw-text-4a4 tw-mt-12"
						>
							{{ item.label }}
						</div>
						<v-list-item
							v-else
							:key="itemIndex"
							@click="item.click"
							class=" tw-h-16 tw-ps-9 tw-mt-4 tw-bg-fcf tw-font-pop tw-text-base tw-rounded-lg tw-text-black tw-overflow-hidden"
						>
							<v-list-item-title
								:style="item.color ? { color: item.color } : {}"
								>{{ item.label }}</v-list-item-title
							>
							<v-list-item-action v-if="!item.hideArrow">
								<v-icon size="26" color="black">
									mdi-chevron-right
								</v-icon>
							</v-list-item-action>
						</v-list-item>
					</template>
				</v-list>
			</v-card-text>
		</v-card>

		<DialogDeleteConfirmation
			v-model="remove.dialog"
			hide-id
			:id.sync="remove.id"
			:title="$t('account')"
			:delete-function="deleteUser"
			@deleted="onAccountDeleted"
		/>
	</v-dialog>
</template>

<script>
import { i18n } from '../../plugins/vue-i18n'
import { mapActions, mapGetters } from 'vuex'

import DialogDeleteConfirmation from './DialogDeleteConfirmation.vue'

export default {
	name: 'DialogSettings',
	components: {
		DialogDeleteConfirmation,
	},
	data: () => ({
		remove: {
			id: null,
			dialog: false,
		},
	}),
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('settings', ['$settings']),
		items() {
			return [
				{ subheader: true, label: i18n.t('account') },
				{
					label: i18n.t('changePassword'),
					click: this.goto('/change-password'),
				},
				{
					label: i18n.t('actions.changeLanguage'),
					click: this.onInitChangeLanguage,
				},
				{ label: this.$t('contactUs'), click: this.goto('/contact-us') },
				{ subheader: true, label: i18n.t('others') },
				{
					label: i18n.t('privacyPolicy'),
					click: this.goto('/privacy-policy'),
				},
				{
					label: this.$t('termsNConditions'),
					click: this.goto('/terms-conditions'),
				},
				{
					hideArrow: true,
					color: '#EB7777',
					label: this.$t('deleteAccount'),
					click: this.onInitDeleteAccount,
				},
			]
		},
		vModel: {
			get() {
				return this.$settings.dialog
			},
			set(v) {
				this.$store.commit('settings/SET', {
					'settings.dialog': v,
				})
			},
		},
	},
	methods: {
		...mapActions('users', ['deleteUser']),
		goto(path) {
			return () => {
				this.vModel = false
				this.$router.push(path)
			}
		},
		onInitChangePassword(e) {
			console.log('onInitChangePassword', e)
		},
		onInitChangeLanguage() {
			this.$store.commit('settings/SET', {
				'language.dialog': true,
			})
		},
		onInitDeleteAccount() {
			this.remove = {
				dialog: true,
				id: this.$currentUser.id,
			}
		},
		async onAccountDeleted() {
			await this.$store.dispatch('auth/logout')
			this.vModel = false
			this.$router.replace('/login')
		},
	},
}
</script>
