import { Advertisements } from '../../api'
import { initialList } from '../store.helpers'
import {
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'

const initialState = () => ({
	advertisements: initialList(),
})

const state = initialState()

const getters = {
	...createGetters('advertisements'),
}

const mutations = {
	...createMutations('SET', 'PUSH', 'UNSHIFT', 'DELETE', 'UPDATE', 'MERGE'),
}

const actions = {
	setAdvertisementState({ commit }, payload) {
		commit('SET', payload)
	},
	createAdvertisement({ commit }, payload = {}) {
		return handleAction(Advertisements.post(payload), (res) => {
			commit('UNSHIFT', ['advertisements.data', res.advertisement])
		})
	},
	deleteAdvertisement({ commit }, payload) {
		return handleAction(Advertisements.delete(payload.id), () => {
			commit('DELETE', ['advertisements.data', payload.id])
		})
	},
	updateAdvertisement({ commit }, payload = {}) {
		return handleAction(Advertisements.patch(payload), (res) => {
			commit('UPDATE', ['advertisements.data', res.advertisement])
		})
	},
	changeStatus({ commit }, payload = {}) {
		return handleAction(Advertisements.changeStatus(payload), (res) => {
			commit('UPDATE', ['advertisements.data', res.advertisement])
		})
	},
	getAdvertisement({ commit }, payload = {}) {
		return handleAction(
			Advertisements.getById(payload.id, payload.query),
			(res) => {
				commit('UNSHIFT', ['advertisements.data', res.advertisement])
			}
		)
	},
	getAdvertisements({ commit }, payload = {}) {
		return handleAction(Advertisements.get(payload), (res) => {
			commit('SET', {
				advertisements: res.advertisements,
			})
		})
	},
	resetState({ commit }) {
		commit('SET', initialState())
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
