import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueToast from 'vue-toast-notification'
import moment from 'moment'
import lodash from 'lodash'
import API from '@/api'
import VueApexCharts from 'vue-apexcharts'
import VueMeta from 'vue-meta'

// import { _isDev } from './consts'
import { i18n, hotUpdateLocale } from './plugins/vue-i18n'
import {
	hasHistory,
	numberWithCommas,
	registerComponents,
	createImgErrorHandler,
} from 'vuelpers'

import { cookies } from './helpers'

// Stylesheets
import './assets/scss/main.scss'
import 'vue-toast-notification/dist/theme-sugar.css'

// Proggresive web app
import './registerServiceWorker'

import './assets/css/tailwind.css'

import { useUtils } from './utils'
import { _serverBaseURL } from './consts'

// REGISTER GLOBAL COMPONENTS
registerComponents(
	require.context('./components', true, /(Base|Icon)[A-Z]\w+\.(vue|js|ts)$/),
	Vue
)
vuetify.rtl = i18n.locale === 'ar' ? true : false

// Plugins
Vue.use(VueToast, {
	dismissible: true,
	position: vuetify.rtl ? 'bottom-left' : 'bottom-right',
})
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueMeta)

// Sync cookies
const { isAuth, userId, accessToken, isAcceptTerms } = cookies.get(
	'isAuth',
	'userId',
	'accessToken',
	'isAcceptTerms'
)
store.commit('auth/SET', {
	isAuth,
	accessToken,
	user: userId
		? {
				id: userId,
				isAcceptTerms,
		  }
		: null,
})
API.setHeaders({
	Authorization: `Bearer ${accessToken}`,
})
// Disabling production tips
Vue.config.productionTip = false

const FALLBACK_IMG = '/img/fallback.jpg?'

Vue.prototype._ = lodash
Vue.prototype.$m = moment
Vue.prototype.$get = lodash.get
Vue.prototype.$commafy = numberWithCommas
Vue.prototype.$onImgError = createImgErrorHandler({
	base: FALLBACK_IMG,
})

Vue.prototype.$isRtl = () => i18n.locale === 'ar'
Vue.prototype.$image = (src, size = '400') => {
	if (!src) return `${FALLBACK_IMG}${size}`
	if (['http', 'blob'].some((ext) => src.startsWith(ext))) return src
	return `${_serverBaseURL}/storage/${src}`
}
Vue.prototype.$placeholder = (size = '400', setSrc = true) => {
	const src = `${FALLBACK_IMG}${size}`
	if (!setSrc) return src
	return ($event) => $event.target && ($event.target.src = src)
}
Vue.prototype.$goBack = (path = '/') => {
	return hasHistory() ? router.back() : router.push(path)
}
Vue.prototype.$utils = useUtils()

const app = new Vue({
	i18n,
	store,
	router,
	vuetify,
	data: {
		confirmation: {
			title: '',
			message: '',
			handler: null,
		},
	},
	created() {
		isAuth && this.$store.dispatch('auth/fetchProfile')
	},
	render: (h) => h(App),
})

app.$mount('#app')

hotUpdateLocale()
