import { createGetters, createMutations } from 'vuelpers'

const initialState = () => ({
	drawer: null,
	loading: false,
	theme: {
		dark: false,
	},
	logout: {
		dialog: false,
		isLoading: false,
	},
})

export const state = initialState()
export const mutations = createMutations('SET', 'UPDATE')
export const getters = createGetters('theme', 'drawer', 'loading', 'logout')

export const actions = {
	setState({ commit }, payload = {}) {
		const module = payload?.module ?? ''
		delete payload.module
		commit(`${module ? module + '/' : module}SET`, payload)
	},
	toggleDrawer({ commit, state: { drawer } }, payload) {
		commit('SET', {
			drawer: payload === undefined ? !drawer : payload,
		})
	},
	toggleLoading({ commit, state: { loading } }, payload) {
		commit('SET', {
			loading: payload === undefined ? !loading : payload,
		})
	},
	toggleTheme(
		{
			commit,
			state: {
				theme: { dark },
			},
		},
		payload
	) {
		commit('SET', {
			theme: {
				dark: payload === undefined ? !dark : payload,
			},
		})
	},
}
