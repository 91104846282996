<template>
	<div class="v-input tw-flex-col tw-w-full tw-items-stretch">
		<base-drop-zone
			v-model="mValue"
			:accept="accept"
			:readonly="readonly"
			hide-list
		>
			<template #placeholder="{ on, attrs }">
				<div
					v-if="!readonly"
					v-on="on"
					v-bind="attrs"
					class="tw-border-dashed tw-border-2 tw-border-DF tw-border-opacity-70 tw-rounded-lg tw-ps-6 tw-pe-2 tw-py-2 tw-flex tw-flex-col md:tw-flex-row md:tw-space-s-4 tw-items-center tw-justify-between tw-space-y-2 md:tw-space-y-0"
				>
					<div class="tw-flex tw-flex-col tw-space-y-1.5">
						<span class="tw-font-xs tw-text-black tw-font-semibold">
							{{ translate ? $t(title) : title }}
						</span>
						<span class="tw-font-medium tw-text-CA tw-text-xs">
							{{ translate ? $t(subtitle) : subtitle }}
						</span>
					</div>

					<v-btn
						dark
						depressed
						width="152"
						height="52"
						color="#F2CA51"
						class="tw-rounded-md"
					>
						<icon-login class="tw-me-3 xl:tw-me-6" />
						<span
							class="tw-text-base xl:tw-text-lg tw-normal-case tw-font-normal"
							>{{ $t('actions.upload') }}</span
						>
					</v-btn>
				</div>
			</template>
		</base-drop-zone>
		<div v-if="!isValid" class="v-messages error--text tw-px-2 tw-mt-2">
			<div class="v-messages__wrapper">
				<div class="v-messages__message">
					{{ mErrors[0] }}
				</div>
			</div>
		</div>
		<v-simple-table
			v-if="attachments.length"
			:class="tableClass"
			class="tw-font-pop tw-text-base tw-text-58"
		>
			<tbody>
				<tr
					v-for="(attachment, i) in attachments"
					:key="i"
					class="tw-h-16 tw-border-f2f tw-relative tw-group"
				>
					<td class="tw-border-f2f">{{ i + 1 }}</td>
					<td class="tw-border-f2f">
						<div class="tw-flex tw-items-center">
							<div>
								<v-divider vertical class="tw-me-6 tw-h-6 tw-min-h-0" />
							</div>
							<div class="tw-me-5">
								<IconFilePdf v-if="isPDF(attachment.file)" />
								<IconFileDoc v-else-if="isDoc(attachment.file)" />
								<v-img
									v-else-if="isImage(attachment.file)"
									:src="attachment.src"
									:alt="attachment.file.name"
									cover
									width="24"
									height="24"
									class="tw-rounded-sm"
									@error="attachment.src = $placeholder(24, false)"
								/>
								<div v-else class="tw-w-6"></div>
							</div>
							<div>{{ attachment.file.name }}</div>
						</div>
					</td>

					<td v-if="attachment.file.category" class="tw-border-f2f">
						{{ attachment.file.category }}
					</td>

					<td class="tw-text-right tw-border-f2f">
						{{ getSize(attachment.file) }}
					</td>

					<component
						:is="actionsInLine ? 'td' : 'div'"
						class="tw-flex tw-items-center tw-justify-end"
						:class="[
							actionsInLine
								? 'tw-h-16 tw-border-f2f'
								: 'tw-absolute tw-top-1/2 tw-transform tw--translate-y-1/2 tw-right-1 tw-bg-ee tw-opacity-0 group-hover:tw-opacity-100 tw-h-14 tw-w-32',
						]"
					>
						<v-btn
							icon
							tag="a"
							color="primary"
							target="_blank"
							:href="attachment.src"
							><v-icon>mdi-eye</v-icon></v-btn
						>
						<v-btn
							v-if="!readonly"
							icon
							color="error"
							@click="onRemoveAttachment(i)"
						>
							<v-icon>mdi-close</v-icon></v-btn
						>
					</component>
				</tr>
			</tbody>
		</v-simple-table>
	</div>
</template>

<script>
export default {
	name: 'BaseUpload',
	props: {
		actionsInLine: {
			type: Boolean,
			default: false,
		},
		rules: {
			type: Array,
			default: () => [],
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		translate: {
			type: Boolean,
			default: true,
		},
		title: {
			type: String,
			default: 'Base Upload',
		},
		subtitle: {
			type: String,
			default: 'adminContracts.fileUploadHint',
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		accept: {
			type: String,
			default: 'image/*,application/pdf',
		},
		value: {
			required: true,
		},
		tableClass: {
			type: String,
			default: 'tw-mt-5 xl:tw-mt-9',
		},
		errorMessages: {
			type: Array,
			default: () => [],
		},
	},
	data: () => ({
		errors: [],
	}),
	created() {
		if (this.multiple && !Array.isArray(this.value)) {
			throw new Error(
				'model value have to be an array when multiple is true.'
			)
		}
		if (!this.multiple && Array.isArray(this.value)) {
			throw new Error(
				'model value cannot be an array when multiple is false'
			)
		}

		this.form && this.form.register(this)
	},
	beforeDestroy() {
		this.form && this.form.unregister(this)
	},
	computed: {
		isValid() {
			return this.mErrors.length === 0
		},
		mErrors() {
			return this.errorMessages.concat(this.errors)
		},
		form() {
			return this.$utils.getVueParent(this, parent => {
				return (
					parent?._vnode?.tag === 'form' &&
					parent?._vnode?.data?.staticClass === 'v-form'
				)
			})
		},
		config() {
			return {
				accept: this.accept,
				multiple: this.multiple,
			}
		},
		mValue: {
			get() {
				return this.value
			},
			set(v) {
				this.$emit('input', v)
			},
		},
		attachments() {
			return !this.multiple ? [this.mValue].filter(Boolean) : this.mValue
		},
	},
	methods: {
		onRemoveAttachment(index) {
			this.mValue = this.multiple
				? this.mValue.filter((_, i) => i !== index)
				: null

			this.$nextTick(() => this.validate())
		},
		getSize(file) {
			if (!file.size) return ''
			if (typeof file.size === 'string') {
				return (+file.size.split(' ')[0]).toFixed(2) + ' KB'
			}
			return (file.size / 1024).toFixed(2) + ' KB'
		},
		isPDF(file) {
			return (
				file.type === 'application/pdf' ||
				(file.name || '').endsWith('.pdf')
			)
		},
		isDoc(file) {
			return ['.doc', '.docx'].some(ext => (file.name || '').endsWith(ext))
		},
		isImage(file) {
			return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(file.name)
		},
		/** @public */
		reset() {
			this.mValue = this.multiple ? [] : null
		},
		/** @public */
		resetValidation() {
			this.errors = []
		},
		/** @public */
		validate() {
			this.errors = this.rules.reduce((errors, rule) => {
				const validationRes = rule(this.mValue)
				if (validationRes !== true) errors.push(validationRes)
				return errors
			}, [])
			return this.isValid
		},
	},
}
</script>
