<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17.435"
			height="17.435"
			viewBox="0 0 17.435 17.435"
		>
			<g
				id="menu_14_"
				data-name="menu (14)"
				transform="translate(0.25 0.25)"
			>
				<g id="Group_38800" data-name="Group 38800">
					<g id="Group_38799" data-name="Group 38799">
						<path
							id="Path_31264"
							data-name="Path 31264"
							d="M5.847,0H1.958A1.961,1.961,0,0,0,0,1.958V5.847A1.961,1.961,0,0,0,1.958,7.806H5.847A1.961,1.961,0,0,0,7.806,5.847V1.958A1.961,1.961,0,0,0,5.847,0Zm.635,5.847a.636.636,0,0,1-.635.635H1.958a.636.636,0,0,1-.635-.635V1.958a.636.636,0,0,1,.635-.635H5.847a.636.636,0,0,1,.635.635Z"
							stroke="#fcfafb"
							stroke-width="0.5"
						/>
					</g>
				</g>
				<g
					id="Group_38802"
					data-name="Group 38802"
					transform="translate(9.129)"
				>
					<g id="Group_38801" data-name="Group 38801">
						<path
							id="Path_31265"
							data-name="Path 31265"
							d="M281.821,0h-3.837A1.987,1.987,0,0,0,276,1.985V5.821a1.987,1.987,0,0,0,1.984,1.985h3.837a1.987,1.987,0,0,0,1.985-1.985V1.985A1.987,1.987,0,0,0,281.821,0Zm.662,5.821a.662.662,0,0,1-.662.662h-3.837a.662.662,0,0,1-.661-.662V1.985a.662.662,0,0,1,.661-.662h3.837a.662.662,0,0,1,.662.662Z"
							transform="translate(-276)"
							stroke="#fcfafb"
							stroke-width="0.5"
						/>
					</g>
				</g>
				<g
					id="Group_38804"
					data-name="Group 38804"
					transform="translate(0 9.129)"
				>
					<g id="Group_38803" data-name="Group 38803">
						<path
							id="Path_31266"
							data-name="Path 31266"
							d="M5.847,276H1.958A1.961,1.961,0,0,0,0,277.958v3.889a1.961,1.961,0,0,0,1.958,1.958H5.847a1.961,1.961,0,0,0,1.958-1.958v-3.889A1.961,1.961,0,0,0,5.847,276Zm.635,5.847a.636.636,0,0,1-.635.635H1.958a.636.636,0,0,1-.635-.635v-3.889a.636.636,0,0,1,.635-.635H5.847a.636.636,0,0,1,.635.635Z"
							transform="translate(0 -276)"
							stroke="#fcfafb"
							stroke-width="0.5"
						/>
					</g>
				</g>
				<g
					id="Group_38806"
					data-name="Group 38806"
					transform="translate(9.129 9.129)"
				>
					<g id="Group_38805" data-name="Group 38805">
						<path
							id="Path_31267"
							data-name="Path 31267"
							d="M281.821,276h-3.837A1.987,1.987,0,0,0,276,277.985v3.837a1.987,1.987,0,0,0,1.984,1.985h3.837a1.987,1.987,0,0,0,1.985-1.985v-3.837A1.987,1.987,0,0,0,281.821,276Zm.662,5.821a.662.662,0,0,1-.662.662h-3.837a.662.662,0,0,1-.661-.662v-3.837a.662.662,0,0,1,.661-.661h3.837a.662.662,0,0,1,.662.661Z"
							transform="translate(-276 -276)"
							stroke="#fcfafb"
							stroke-width="0.5"
						/>
					</g>
				</g>
			</g>
			<slot></slot>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconDashboard',
}
</script>
