<template>
	<v-list-item :class="{ 'tw-py-0': inline }">
		<v-list-item-icon class="tw-my-0 tw-me-7">
			<v-img
				:src="$image($get(item, 'cartable.attachments.0.attachedLink'))"
				ref="imgRef"
				class="tw-object-cover tw-object-center tw-w-16 md:tw-w-24 tw-h-16 md:tw-h-24 tw-rounded md:tw-rounded-xl"
				@error="$onImgError({ ref: $refs.imgRef })"
			/>
		</v-list-item-icon>
		<v-list-item-content
			class="tw-self-start"
			:class="inline ? 'tw-p-0' : 'tw-py-1'"
		>
			<div
				class="tw-w-full"
				:class="{
					'tw-flex tw-flex-row tw-items-center tw-justify-between tw-h-24':
						inline,
				}"
			>
				<div :class="[inline ? 'tw-w-1/2' : 'tw-w-full']">
					<v-list-item-title
						class="tw-mb-0 tw-mt-1 tw-flex tw-space-s-2 tw-text-black tw-items-center tw-leading-normal tw-justify-between tw-font-medium"
					>
						<div class="tw-font-pop tw-text-18px tw-truncate">
							{{ item.cartable.name }}
						</div>
						<div
							v-if="!inline"
							class="tw-font-rubi tw-text-17px tw-flex-none"
						>
							{{
								numberWithCommas(
									(offer ? offer.finalPrice : originalPrice) * quantity
								)
							}}
							{{ currency }}
						</div>
					</v-list-item-title>
					<v-list-item-subtitle
						class="tw-font-pop tw-font-normal tw-text-black tw-text-13px"
					>
						{{ item.cartable.supplier.representativeName }}
					</v-list-item-subtitle>
				</div>

				<div
					v-if="inline"
					class="tw-font-rubi tw-text-17px tw-flex-none tw-space-s-1"
				>
					<span
						v-if="offer"
						class="tw-line-through tw-opacity-60 tw-text-sm"
					>
						{{ numberWithCommas(originalPrice * quantity) }}
					</span>
					{{
						numberWithCommas(
							(offer ? offer.finalPrice : originalPrice) * quantity
						)
					}}
					{{ currency }}
				</div>

				<div
					class="tw-flex tw-justify-between tw-items-center"
					:class="[inline ? 'tw-w-1/3' : 'tw-mt-auto']"
				>
					<base-quantity
						class="tw-bg-fdf"
						:max="maxQuantity"
						v-model="quantity"
						@change="onChangeQuantity"
					/>
					<v-btn
						tile
						icon
						outlined
						color=""
						width="44"
						height="40"
						@click="deleteItem(item)"
						class="tw-rounded-md tw-border-E9"
					>
						<v-icon color="black" size="22">mdi-delete</v-icon>
					</v-btn>
				</div>
			</div>
		</v-list-item-content>
	</v-list-item>
</template>

<script>
import { mapActions } from 'vuex'
import { numberWithCommas } from '../helpers'
export default {
	name: 'CartListItem',
	props: {
		item: Object,
		inline: Boolean,
	},
	data: () => ({
		quantity: 1,
		isUpdating: false,
	}),
	watch: {
		'item.quantity': {
			immediate: true,
			handler(v) {
				if (v != this.quantity && !this.isUpdating) {
					this.quantity = v
				}
			},
		},
	},
	computed: {
		maxQuantity() {
			if (!this.item || !this.item.cartable.isSpecifiedQuantity) {
				return Infinity
			}
			return +this.item.cartable.quantity
		},
		currency() {
			let product = this.isProduct
				? this.item.cartable
				: this.item.cartable.products[0]
			return product?.currency?.currency || 'SAR'
		},
		attachments() {
			return [this.getAttachment(this.item.cartable)]
		},
		isProduct() {
			return this.item.cartableType.toLowerCase().includes('product')
		},
		offer() {
			if (this.isProduct) {
				return this.item.cartable.offers.find((offer) => {
					return offer.isSingleProductOffer
				})
			}
			return this.item.cartable
		},
		originalPrice() {
			if (this.isProduct) return this.item.cartable.price
			return this.item.cartable.products.reduce((total, op) => {
				return total + op.pivot.price
			}, 0)
		},
	},
	methods: {
		numberWithCommas,
		...mapActions('cart', ['deleteItem', 'updateCartItem']),
		async onChangeQuantity(payload) {
			this.isUpdating = true
			await this.updateCartItem({
				...this.item,
				quantity: payload,
			})
			this.isUpdating = false
		},
	},
}
</script>
