import { useInitial } from './useInitial'
import { getVueParent } from './getVueParent'
import { scrollIntoError } from './scrollIntoError'
import { useDocumentPaste } from './useDocumentPaste'
import { usePdfPrinter } from './usePdfPrinter'

export const useUtils = () => {
	return {
		useInitial,
		getVueParent,
		scrollIntoError,
		useDocumentPaste,
		usePdfPrinter,
	}
}
