<template>
	<v-navigation-drawer
		:permanent="!xs"
		:absolute="xs"
		:temporary="xs"
		v-model="drawer"
		class="sidebar"
		:mini-variant="!$drawer && !xs"
		:width="$vuetify.breakpoint.xlAndUp ? '335px' : '255px'"
	>
		<!-- <v-list v-if="user">
      <v-list-item two-line :class="`${$drawer ? 'px-4' : 'px-2'}`">
        <v-list-item-avatar>
          <v-avatar  size="38" color="white" class="black--text">
            {{ user.name && user.name[0] }}
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ user.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ user.role }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
        </v-list-item-content>
    </v-list>
      </v-list-item>
		<v-divider />-->
		<slot></slot>
		<template v-slot:append>
			<v-divider />
			<v-list-item v-if="!user.isAdmin" to="/contact-us">
				<v-list-item-icon>
					<span
						:class="
							$route.path === 'contact-us'
								? 'tw-block tw--ms-1.5'
								: 'tw-hidden'
						"
						class="tw-block tw-bg-72 tw-h-full tw-rounded-full tw-top-0 tw-w-2 tw-absolute tw-right-0"
					></span>

					<icon-call
						class="tw-text-black tw-fill-current"
						:class="$drawer ? 'tw-ms-8 lg:tw-ms-14' : ''"
					></icon-call>
				</v-list-item-icon>
				<v-list-item-title class="tw-cursor-pointer">
					<span
						class="tw-text-black tw-opacity-60"
						:class="$drawer ? 'tw-ms-2 lg:tw-ms-4' : ''"
						>{{ $t('contactUs') }}</span
					>
				</v-list-item-title>
			</v-list-item>
			<v-list-item class="tw-mb-4" @click="handleLogout">
				<v-list-item-icon>
					<span
						:class="
							$route.path === 'contact-us'
								? 'tw-block tw--ms-1.5'
								: 'tw-hidden'
						"
						class="tw-block tw-bg-72 tw-bg-opacity-2 tw-h-full tw-rounded-full tw-top-0 tw-w-1 tw-absolute tw-right-0"
					></span>
					<icon-exit
						class="tw-text-black tw-fill-current"
						:class="$drawer ? 'tw-ms-8 lg:tw-ms-14' : ''"
					/>
				</v-list-item-icon>
				<v-list-item-title class="tw-cursor-pointer">
					<span
						class="tw-text-black tw-opacity-60"
						:class="$drawer ? 'tw-ms-2 lg:tw-ms-4' : ''"
						>{{ $t('logout') }}</span
					>
				</v-list-item-title>
			</v-list-item>
		</template>
	</v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'main-drawer',
	props: ['user', 'hasChild', 'xs'],
	data: () => ({
		drawer: null,
	}),
	computed: {
		...mapGetters(['$drawer']),
	},
	watch: {
		$drawer(v) {
			this.drawer = v
		},
		drawer(v) {
			this.$emit('drawer', v)
		},
	},
	methods: {
		...mapActions('auth', ['logout']),
		...mapActions(['setState']),
		handleLogout() {
			this.setState({
				logout: {
					dialog: true,
					isLoading: false,
				},
			})
		},
	},
}
</script>
<style lang="scss">
.sidebar {
	.v-navigation-drawer__border {
		display: none !important;
	}
	.v-navigation-drawer__content > hr {
		display: none !important;
	}
	.v-navigation-drawer__content {
		overflow-y: auto !important;
	}
}
</style>
