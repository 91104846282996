<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21.5"
			height="21.5"
			viewBox="0 0 21.5 21.5"
		>
			<g id="product-svgrepo-com" transform="translate(-0.619 -0.619)">
				<rect
					id="Rectangle_17364"
					data-name="Rectangle 17364"
					width="4"
					height="2"
					transform="translate(5.869 12.869)"
					stroke="#fff"
					stroke-width="1.5"
				/>
				<rect
					id="Rectangle_17365"
					data-name="Rectangle 17365"
					width="7"
					height="2"
					transform="translate(5.869 14.869)"
					stroke="#fff"
					stroke-width="1.5"
				/>
				<path
					id="Path_34271"
					data-name="Path 34271"
					d="M19.657,4H5.423A1.425,1.425,0,0,0,4,5.423V19.657A1.425,1.425,0,0,0,5.423,21.08H19.657a1.425,1.425,0,0,0,1.423-1.423V5.423A1.425,1.425,0,0,0,19.657,4ZM13.963,5.423V8.27H11.117V5.423ZM5.423,19.657V5.423h4.27v4.27h5.693V5.423h4.27V19.657Z"
					transform="translate(-1.171 -1.171)"
					stroke="#fff"
					stroke-width="0.5"
				/>
				<rect
					id="_Transparent_Rectangle_"
					data-name="&lt;Transparent Rectangle&gt;"
					width="21"
					height="21"
					transform="translate(0.869 0.869)"
					fill="none"
					stroke="#fff"
					stroke-width="0.5"
				/>
			</g>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconProduct',
}
</script>
