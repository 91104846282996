import { Supplier } from '../../api'
import { initialList } from '../store.helpers'
import {
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'

const initialState = () => {
	return {
		suppliers: initialList(),
		supplier: initialList(),
	}
}

const state = initialState()
const getters = createGetters('supplier','suppliers')
const mutations = createMutations('SET', 'PUSH', 'UNSHIFT', 'DELETE', 'UPDATE', 'MERGE')

const actions = {
	supplierApprove(_, payload = {}) {
		return handleAction(Supplier.review(payload), (res) => {
			// commit('SET', { buyers: res.buyers })
			console.log('supplier approve', res)
		})
	},

	supplierProfileEdit(_, payload = {}) {
		return handleAction(Supplier.update(payload), (res) => {
			console.log(res)
		})
	},
	getSupplier({ commit }) {
		return handleAction(Supplier.get(), (res) => {
			commit('SET', { suppliers: res.suppliers })
			console.log(res)
			console.log('get suppliers', 'console from get suppliers')
		})
	},
	getSingleSupplier({ commit }, payload = {}) {
		return handleAction(Supplier.getById(payload), (res) => {
			commit('SET', { supplier: res.supplier })
			console.log(res)
			console.log('get suppliers')
		})
	},


}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
