<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		version="1.1"
		id="Capa_1"
		x="0px"
		y="0px"
		viewBox="0 0 471.2 471.2"
		style="enable-background:new 0 0 471.2 471.2;"
		xml:space="preserve"
	>
		<g>
			<g>
				<path
					d="M396.7,0H74.5C33.4,0,0,33.4,0,74.5v322.2c0,41.1,33.4,74.5,74.5,74.5h322.2c41.1,0,74.5-33.4,74.5-74.5V74.5    C471.2,33.5,437.7,0,396.7,0z M444.2,396.7c0,26.2-21.3,47.5-47.5,47.5H74.5c-26.2,0-47.5-21.3-47.5-47.5V74.5    C27,48.3,48.3,27,74.5,27h322.2c26.2,0,47.5,21.3,47.5,47.5V396.7z"
				/>
				<path
					d="M248.3,109.1L248.3,109.1c-0.2-0.4-0.3-0.8-0.5-1.2c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.2-0.2-0.4-0.3-0.6    c-0.1-0.2-0.3-0.4-0.4-0.7c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.3-0.4-0.5-0.6c-0.1-0.2-0.2-0.3-0.4-0.5c-0.1-0.2-0.3-0.3-0.4-0.5    c-0.2-0.2-0.3-0.3-0.5-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.4-0.3-0.6-0.5c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.2-0.5-0.3-0.7-0.5    c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2-0.1-0.5-0.3-0.7-0.4c-0.4-0.2-0.8-0.4-1.2-0.5l0,0c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.7-0.2-1-0.3    c-0.2,0-0.3-0.1-0.5-0.1c-0.3-0.1-0.5-0.1-0.8-0.2c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.5-0.1-0.7-0.1s-0.4,0-0.6,0c-0.2,0-0.5,0-0.7,0    s-0.4,0-0.7,0.1c-0.2,0-0.4,0-0.7,0.1c-0.2,0-0.5,0.1-0.7,0.1s-0.4,0.1-0.6,0.1c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.2,0.1-0.3,0.1    l0,0c-0.4,0.1-0.8,0.3-1.2,0.5c-0.3,0.1-0.5,0.3-0.8,0.4c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.2-0.5,0.3-0.7,0.5    c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0.2-0.4,0.3-0.6,0.5c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.1-0.3,0.3-0.4,0.4    c-0.1,0.1-0.3,0.3-0.4,0.5c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0.2-0.2,0.4-0.3,0.6    s-0.2,0.4-0.3,0.6c-0.2,0.4-0.4,0.8-0.5,1.2l0,0L135.6,353c-2.5,7,1.1,14.7,8.2,17.3c1.5,0.5,3,0.8,4.5,0.8c5.5,0,10.7-3.4,12.7-9    l24.6-68.7h93.6c2.1,0,4.1-0.5,5.8-1.3l25.1,70.1c2,5.5,7.2,9,12.7,9c1.5,0,3-0.3,4.5-0.8c7-2.5,10.7-10.2,8.2-17.3L248.3,109.1z     M195.3,266.4l40.3-112.7l40.3,112.6h-80.6V266.4z"
				/>
			</g>
		</g>
	</svg>
</template>
<script>
export default {
	name: 'IconText',
}
</script>
