<template>
	<div class="tw-flex tw-items-center tw-rounded-md">
		<v-btn
			v-bind="btnAttrs"
			:disabled="disabled || quantity <= 1"
			@click="quantity -= 1"
		>
			<v-icon :size="mSize / iconRatio">mdi-minus</v-icon>
		</v-btn>
		<v-divider vertical class="tw-my-2 tw-border-f0f"></v-divider>
		<input
			ref="inputRef"
			class=" tw-text-center tw-font-pop tw-px-2 tw-font-medium tw-text-black focus:tw-outline-none focus:tw-bg-f0f"
			:class="[
				large ? 'tw-font-medium tw-text-base xl:tw-text-xl' : 'tw-text-sm',
			]"
			:style="{
				width: `${mSize}px`,
				height: `${mSize}px`,
			}"
			:disabled="disabled"
			v-model.number="quantity"
			@input="onInputQuantity"
		/>
		<v-divider vertical class="tw-my-2 tw-border-f0f"></v-divider>
		<v-btn
			v-bind="btnAttrs"
			:disabled="disabled || quantity >= max"
			@click="quantity += 1"
		>
			<v-icon :size="mSize / iconRatio">mdi-plus</v-icon>
		</v-btn>
	</div>
</template>

<script>
import { TIME } from 'vuelpers'
export default {
	name: 'BaseQuantity',
	emits: ['input', 'change'],
	props: {
		value: [String, Number],
		max: {
			type: Number,
			default: Infinity,
		},
		size: {
			default: 0,
			type: [Number, String],
		},
		iconRatio: {
			default: 2.3,
			type: Number,
		},
		large: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		timeout: null,
	}),
	computed: {
		btnAttrs() {
			return {
				tile: true,
				icon: true,
				color: 'black',
				width: this.mSize,
				height: this.mSize,
			}
		},
		mSize() {
			if (this.size) return this.size
			return this.large ? 56 : 40
		},
		quantity: {
			get() {
				return this.value
			},
			set(v) {
				this.$emit('input', v)
				clearTimeout(this.timeout)
				this.timeout = setTimeout(() => {
					this.$emit('change', v)
				}, TIME.SEC)
			},
		},
	},
	methods: {
		onInputQuantity(event) {
			const v = +event.target.value
			if (!v || v < 1) {
				this.quantity = 1
				this.$refs.inputRef.value = 1
			} else if (this.max !== Infinity && v > this.max) {
				this.quantity = this.max
				this.$refs.inputRef.value = this.max
			}
		},
	},
}
</script>
