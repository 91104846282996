import { Addresses } from '../../api'

import { initialList, initialForm } from '../store.helpers'

import { handleAction, createGetters, createMutations } from 'vuelpers'

import { DELETE, MERGE, PUSH, SET, UNSHIFT, UPDATE } from 'vuelpers/lib/vuex'

const initialAddress = (value = {}) => {
	return {
		name: '',
		phone: '',
		city: '',
		streetName: '',
		postalCode: '',
		buildingNumber: '',
		neighborhoodName: '',
		...value,
	}
}

const initialState = () => {
	return {
		imagePreview: {
			src: null,
			modal: false,
			current: 0,
			images: [],
		},
		currentPayment: {
			type: null,
			tranRef: null,
		},
		addresses: initialList(),
		addressForm: initialForm({
			isUpdate: false,
			isVisible: false,
			isLoading: false,
			data: initialAddress(),
		}),
		settings: {
			dialog: false,
		},
		language: {
			locale: 'en',
			dialog: false,
		},
		languages: [
			{ locale: 'ar', flag: 'sa', label: 'Arabic' },
			{ locale: 'en', flag: 'us', label: 'English' },
		],
		globalLoading: false,
	}
}

const state = initialState()
const mutations = createMutations(SET, PUSH, UNSHIFT, DELETE, UPDATE, MERGE)
const getters = createGetters(
	'addresses',
	'addressForm',
	'currentPayment',
	'imagePreview',
	'settings',
	'language',
	'languages',
	'globalLoading',
	{
		currentLanguage(state) {
			return state.languages.find(
				(lang) => lang.locale === state.language.locale
			)
		},
	}
)

const actions = {
	// toggleImagePreview({ commit }, payload) {
	// 	const current = _.isPlainObject(payload) ? payload.current : 0
	// 	const images = _.isPlainObject(payload) ? payload.images : payload
	// 	commit('SET', {
	// 		imagePreview: {
	// 			current,
	// 			modal: !!images,
	// 			src: Array.isArray(images)
	// 				? typeof images[current] === 'string'
	// 					? images[current]
	// 					: SERVER_BASE + '/storage/' + images[current].attachedLink
	// 				: images,
	// 			images: Array.isArray(images)
	// 				? images.map((p) => (typeof p === 'string' ? p : p.attachedLink))
	// 				: [],
	// 		},
	// 	})
	// },
	setSettingsState({ commit }, payload) {
		commit('SET', payload)
	},
	resetAddressForm({ commit }) {
		commit('SET', {
			addressForm: initialForm({
				data: initialAddress(),
			}),
		})
	},
	createAddress({ commit }, payload) {
		return handleAction(Addresses.post(payload), (res) => {
			commit('PUSH', {
				'addresses.data': {
					...payload,
					...res.address,
				},
			})
		})
	},
	deleteAddress({ commit }, payload = {}) {
		return handleAction(Addresses.delete(payload), () => {
			commit('DELETE', ['addresses.data', payload])
		})
	},
	updateAddress({ commit }, payload = {}) {
		return handleAction(Addresses.update(payload), (res) => {
			commit('UPDATE', ['addresses.data', res.address])
		})
	},
	getAddresses({ commit }, payload = {}) {
		return handleAction(Addresses.get(payload), (res) => {
			commit('SET', {
				addresses: {
					data: res.addresses,
				},
			})
		})
	},
	resetState({ commit }) {
		commit('SET', initialState())
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
