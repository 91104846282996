import axios from 'axios'
import { createElement } from 'vuelpers'

const usePdfPrinter = ({
	url,
	token = '',
	filename = `use-pdf-printer-${Date.now()}.pdf`,
}) => {
	const config = {
		url,
		method: 'GET',
		responseType: 'blob',
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}
	const print = () => {
		return new Promise((resolve) => {
			return axios(config)
				.then((response) => {
					const href = URL.createObjectURL(response.data)
					const anchor = createElement('a', {
						href,
						download: filename,
					})
					anchor.click()
					resolve([false, href])
				})
				.catch((error) => resolve([error]))
		})
	}

	return { print }
}

export { usePdfPrinter }
