import { createAPI } from 'vuelpers'
import { _serverBaseURL } from '../consts'

const baseURL = `${_serverBaseURL}/api`

const axios = createAPI({
	baseURL,
	request: {
		convertCase: 'snake_case',
	},
	response: {
		convertCase: 'camelCase',
	},
})

export default axios
