<template>
	<component
		v-model="dialog"
		:is="tag"
		:max-width="maxWidth"
		scrollable
		content-class="tw-rounded-2xl tw-shadow-lg"
	>
		<v-card>
			<v-card-title>
				<span> {{ title || 'Base View Table' }}</span>
				<v-spacer />
				<v-btn icon large color="error" @click="dialog = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-title>
			<v-card-text v-if="item">
				<v-simple-table class="tw-border">
					<tbody>
						<tr v-for="(header, index) in tHeaders" :key="index">
							<th>{{ header.text }}</th>
							<td>
								<slot
									:name="header.value"
									:item="item"
									:index="index"
									:header="header"
									:headers="tHeaders"
								>
									{{
										isFunction(header.value)
											? header.value(item)
											: $get(item, header.value)
									}}
								</slot>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card-text>
		</v-card>
	</component>
</template>
<script>
import { isFunction } from 'lodash'
import { VDialog } from 'vuetify/lib/components'

export default {
	name: 'BaseViewTable',
	components: {
		VDialog,
	},
	props: {
		tag: {
			type: String,
			default: 'div',
		},
		title: {
			type: String,
		},
		maxWidth: {
			default: '600',
		},
		value: {
			type: Boolean,
			default: false,
		},
		translate: {
			type: Boolean,
			default: true,
		},
		headers: {
			type: Array,
			default: () => [],
		},
		item: {
			required: true,
			default: () => ({}),
		},
	},
	computed: {
		dialog: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
		tHeaders() {
			return !this.translate
				? this.headers
				: this.headers.map((header) => {
						return {
							...header,
							text: this.$t(header.text),
						}
				  })
		},
	},
	methods: {
		isFunction,
	},
}
</script>
