<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22.738"
			height="19.618"
			viewBox="0 0 22.738 19.618"
		>
			<g id="delivery-cart-svgrepo-com" transform="translate(0.102 -17.507)">
				<g
					id="Group_40433"
					data-name="Group 40433"
					transform="translate(0 17.624)"
				>
					<g
						id="Group_40432"
						data-name="Group 40432"
						transform="translate(0)"
					>
						<path
							id="Path_34285"
							data-name="Path 34285"
							d="M22.47,28.853a.676.676,0,0,0-.9-.327L13.479,32.3a2.9,2.9,0,0,0-2.867-1.045L4.758,18.7.853,17.647a.676.676,0,0,0-.353,1.3l3.3.893L9.387,31.828a2.914,2.914,0,0,0,1.811,5.2,2.911,2.911,0,0,0,2.852-3.5l8.093-3.774A.676.676,0,0,0,22.47,28.853Zm-9.8,5.791A1.565,1.565,0,0,1,11.2,35.673a1.564,1.564,0,0,1-.662-2.98,1.549,1.549,0,0,1,.659-.147,1.564,1.564,0,0,1,1.47,2.1Z"
							transform="translate(0 -17.624)"
							stroke="#fff"
							stroke-width="0.2"
						/>
						<path
							id="Path_34286"
							data-name="Path 34286"
							d="M100.209,44.711a.676.676,0,0,0,.9.327l7.78-3.628a.676.676,0,0,0,.327-.9l-3.31-7.1a.676.676,0,0,0-.9-.327l-7.78,3.628a.676.676,0,0,0-.327.9ZM104.965,34.6,107.7,40.47l-6.554,3.057L98.41,37.655Z"
							transform="translate(-88.233 -31.656)"
							stroke="#fff"
							stroke-width="0.2"
						/>
					</g>
				</g>
			</g>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconOrders',
}
</script>
