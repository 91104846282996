<template>
	<v-btn
		depressed
		v-bind="attrs"
		:to="to"
		:text="text"
		:dark="dark"
		:color="color"
		:block="block"
		:height="height"
		:loading="loading"
		:class="disabled ? 'tw-select-none tw-pointer-events-none' : ''"
		class=" tw-font-pop tw-text-18px tw-rounded-lg tw-font-medium tw-normal-case tw-tracking-normal"
		@click="$emit('click', $event)"
	>
		<slot />
	</v-btn>
</template>

<script>
export default {
	name: 'BaseButton',
	props: {
		loading: {
			type: Boolean,
		},
		block: {
			type: Boolean,
		},
		text: {
			type: Boolean,
		},
		dark: {
			type: Boolean,
		},
		color: {
			type: String,
			default: 'primary',
		},
		to: {
			type: String,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		height: {
			default: 40,
			type: [String, Number],
		},
		attrs: {
			type: Object,
			default: () => ({}),
		},
	},
}
</script>
