<template>
	<svg
		id="credit-card_7_"
		data-name="credit-card (7)"
		xmlns="http://www.w3.org/2000/svg"
		width="21.783"
		height="14.923"
		viewBox="0 0 21.783 14.923"
	>
		<path
			id="Path_32437"
			data-name="Path 32437"
			d="M2.383,80H19.4a2.381,2.381,0,0,1,2.383,2.374V92.549A2.381,2.381,0,0,1,19.4,94.923H2.383A2.381,2.381,0,0,1,0,92.549V82.374A2.381,2.381,0,0,1,2.383,80ZM19.4,81.357H2.383a1.02,1.02,0,0,0-1.021,1.018v1.357h19.06V82.374A1.02,1.02,0,0,0,19.4,81.357ZM2.383,93.567H19.4a1.02,1.02,0,0,0,1.021-1.018V85.087H1.361v7.462A1.02,1.02,0,0,0,2.383,93.567Z"
			transform="translate(0 -80)"
			fill="#fff"
		/>
		<path
			id="Path_32438"
			data-name="Path 32438"
			d="M80.681,306.035h.681a.68.68,0,0,0,.681-.678v-.678a.68.68,0,0,0-.681-.678h-.681a.68.68,0,0,0-.681.678v.678A.68.68,0,0,0,80.681,306.035Z"
			transform="translate(-63.663 -294.503)"
			fill="#fff"
		/>
	</svg>
</template>
<script>
export default {
	name: 'IconCreditCard',
}
</script>
