export const getVueParent = (component, selector) => {
	// PARENT OF CURRENT ELEMENT
	const parent = component?.$parent

	// NO MORE PARENT
	if (!parent) return

	// DOES GRAND PARENT HAVE THE CHILD WITH SELECTOR
	const isMatched =
		typeof selector !== 'function'
			? !!parent?.$parent?.$el?.querySelector(selector)
			: selector(parent)

	// FOUND THE PARENT
	if (isMatched) return parent

	// ITERATING TO NEXT LEVEL
	return getVueParent(parent, selector)
}
