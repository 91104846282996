<template>
	<v-app app class="default-layout">
		<AppBar
			:title="$t('admin')"
			:theme="$theme"
			:isAuth="$isAuth"
			:loading="$loading"
			@drawer="toggleDrawer"
		>
			<template #actions>
				<div class="tw-flex tw-items-center tw-me-20 tw-space-s-7">
					<!-- CART -->
					<!-- <v-badge
						top
						content="6"
						offset-y="26"
						offset-x="4"
						color="transparent"
						class="cart-badge tw-text-72 tw-me-2"
					>
						<v-btn icon height="36" width="36" color="#722432">
							<v-icon>mdi-cart </v-icon>
						</v-btn>
					</v-badge> -->

					<cart-list v-if="$currentUser.isBuyer" dropdown></cart-list>

					<!-- NOTIFICATION -->
					<!-- <v-badge top bordered dot color="#AAD4A9" offset-x="10" offset-y="10">
						<v-btn icon height="26" width="26" color="#ffc107">
							<v-icon>mdi-bell </v-icon>
						</v-btn>
					</v-badge> -->

					<!-- THEME SWITCHER -->
					<!-- <BaseThemeSwitcher /> -->

					<!-- LANGUAGE -->
					<v-btn
						v-if="$currentLanguage"
						icon
						width="26"
						height="26"
						@click="onInitLanguageChange"
					>
						<v-avatar size="26">
							<BaseFlag
								:alt="$currentLanguage.label"
								:code="$currentLanguage.flag"
								width="26"
								height="26"
								class="tw-rounded-full"
							/>
						</v-avatar>
					</v-btn>
				</div>

				<v-menu
					v-if="$currentUser"
					v-model="menuCurrentUser"
					:close-on-content-click="false"
					right
					offset-y
					rounded="b"
					width="100%"
					max-width="350"
					class="tw-z-20"
					nudge-bottom="8"
					transition="slide-y-transition"
				>
					<template #activator="{ attrs, on }">
						<AppBarMenuActivator v-on="on" v-bind="attrs" />
					</template>
					<v-card>
						<AppBarMenuCurrentUser />
						<v-divider />
						<v-list dense class="transparent">
							<!-- <v-list-item @click="onChangeTheme">
								<v-list-item-icon>
									<v-icon v-if="$theme.dark">mdi-weather-night</v-icon>
									<v-icon v-else>mdi-white-balance-sunny</v-icon>
								</v-list-item-icon>
								<v-list-item-title v-text="'Dark theme'" />
								<v-switch inset dense hide-details class="mt-0 no-events" :input-value="$theme.dark"></v-switch>
							</v-list-item>-->

							<DialogFormCurrentUser v-model="dialogCurrentUser">
								<template #activator="{ on, attrs }">
									<v-list-item v-on="on" v-bind="attrs">
										<v-list-item-icon>
											<v-icon>mdi-account-outline</v-icon>
										</v-list-item-icon>
										<v-list-item-title v-text="'Profile'" />
									</v-list-item>
								</template>
							</DialogFormCurrentUser>

							<v-list-item
								v-if="!$currentUser.isAdmin"
								:to="
									routes.find(route => {
										return route.path.includes('orders')
									}).path
								"
								@click="menuCurrentUser = false"
							>
								<v-list-item-icon>
									<icon-orders />
								</v-list-item-icon>
								<v-list-item-title v-text="'My Orders'" />
							</v-list-item>

							<v-list-item @click="onOpenSettings">
								<v-list-item-icon>
									<icon-settings />
								</v-list-item-icon>
								<v-list-item-title v-text="'Settings'" />
							</v-list-item>

							<v-divider class="my-1" />
							<v-list-item :disabled="$loading" @click="handleLogout">
								<v-list-item-icon>
									<icon-exit />
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title v-text="'Logout'" />
								</v-list-item-content>
							</v-list-item>
							<!-- <v-list-item-group color="primary"> </v-list-item-group> -->
						</v-list>
					</v-card>
				</v-menu>
			</template>
		</AppBar>

		<!-- Main content / router -->
		<v-main
			class="default-layout__main"
			:class="$theme.dark ? 'darken-4' : 'lighten-1'"
		>
			<div class="tw-flex h-content tw-overflow-hidden">
				<Sidebar
					v-if="isContractSigned && !$currentUser.isPending"
					class="sidebar tw-flex-none tw-w-80"
					:user="$currentUser"
					:xs="$vuetify.breakpoint.xs"
					@drawer="toggleDrawer($event)"
				>
					<SidebarNavList br :items="routes" class="scrollbar-y tw-h-full">
						<template #route="{ route }">
							<SidebarNavListItem
								:exact="isExact(route)"
								:to="route.path"
								:name="route.name"
								:icon="route.icon"
								:icon-component="route.iconComponent"
								:key="route.name"
								:hasChildrens="
									route.childrens && route.childrens.length
								"
								@click="onClickSidebarNavListItem(route)"
							>
								<SidebarNavList :items="route.childrens">
									<template #route="{ route: child }">
										<SidebarNavListItem
											:exact="isExact(route)"
											:key="child.name"
											:name="child.name"
											:icon="child.icon"
											:icon-component="route.iconComponent"
											:to="(route.path || '') + child.path"
										/>
									</template>
								</SidebarNavList>
							</SidebarNavListItem>
						</template>
					</SidebarNavList>
				</Sidebar>
				<router-wrapper class="tw-pb-2 lg:tw-pb-10">
					<router-view
						class="router-view tw-flex-1 tw-px-4 md:tw-px-8 lg:tw-px-20 tw-flex tw-flex-col tw-overflow-auto"
					/>
					<slot />
				</router-wrapper>
			</div>
		</v-main>

		<cart-preview v-if="$currentUser.isBuyer"></cart-preview>
		<dialog-settings></dialog-settings>
		<dialog-language></dialog-language>
		<dialog-category></dialog-category>
		<dialog-logout></dialog-logout>
	</v-app>
</template>

<script>
import { PERMISSION, ROLE } from '../consts'
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import AppBar from '@/components/basic/AppBar'
import Sidebar from '@/components/basic/Sidebar'
import SidebarNavList from '@/components/basic/SidebarNavList'
import SidebarNavListItem from '@/components/basic/SidebarNavListItem'
import RouterWrapper from '@/components/basic/RouterWrapper'
import CartList from '../components/CartList.vue'
import CartPreview from '../components/CartPreview.vue'
import DialogSettings from '../components/dialogs/DialogSettings.vue'
import DialogLanguage from '../components/dialogs/DialogLanguage.vue'
import DialogCategory from '../components/dialogs/DialogCategory.vue'
import DialogLogout from '../components/dialogs/DialogLogout.vue'

import AppBarMenuActivator from '../components/CurrentUser/AppBarMenuActivator.vue'
import AppBarMenuCurrentUser from '../components/CurrentUser/AppBarMenuCurrentUser.vue'
import DialogFormCurrentUser from '../components/CurrentUser/DialogFormCurrentUser.vue'

export default {
	name: 'DefaultLayout',
	components: {
		AppBar,
		Sidebar,
		RouterWrapper,
		SidebarNavList,
		SidebarNavListItem,
		CartList,
		CartPreview,
		DialogLogout,
		DialogSettings,
		DialogLanguage,
		DialogCategory,
		AppBarMenuActivator,
		AppBarMenuCurrentUser,
		DialogFormCurrentUser,
	},
	data() {
		return {
			isLoaded: false,
			menuCurrentUser: false,
			dialogCurrentUser: false,
		}
	},
	computed: {
		...mapGetters(['$drawer', '$loading', '$theme']),
		...mapGetters('auth', ['$isAuth', '$currentUser', 'isContractSigned']),
		...mapGetters('settings', ['$currentLanguage']),
		mDrawer: {
			get() {
				return this.$drawer
			},
			set(v) {
				this.setState({ drawer: v })
			},
		},
		buyerRoutes() {
			return [
				{
					path: '/products',
					name: this.$t('products'),
					iconComponent: 'icon-dashboard',
					requirePermissions: [PERMISSION.PRODUCTS],
				},
				{
					path: '/users',
					name: this.$t('users'),
					iconComponent: 'icon-users',
					requirePermissions: [PERMISSION.USERS],
				},
				{
					path: '/orders',
					name: this.$t('orders'),
					iconComponent: 'icon-note',
					requirePermissions: [PERMISSION.ORDERS],
				},
				{
					path: '/complaints',
					name: this.$t('permissions.complaints'),
					iconComponent: 'icon-note-list',
					requirePermissions: [PERMISSION.COMPLAINT],
				},
				{
					path: '/favorites',
					name: this.$t('buyer.navLinks.favorites'),
					iconComponent: 'icon-star',
				},

				{
					exact: false,
					path: '/establishment',
					name: this.$t('establishmentText'),
					iconComponent: 'icon-box',
				},
				{
					path: '/contracts',
					name: this.$t('contracts'),
					iconComponent: 'icon-book',
					requirePermissions: [PERMISSION.CONTRACT],
				},
				{
					path: '/branches',
					name: this.$t('branches'),
					iconComponent: 'icon-store',
					requirePermissions: [PERMISSION.ACCOUNTS],
				},
				{
					path: '/packages',
					name: this.$t('packages'),
					iconComponent: 'icon-package',
				},
				{
					path: '/invoices',
					name: this.$t('invoices'),
					icon: 'mdi-format-list-text',
				},
				{
					path: null,
					event: 'settings',
					name: this.$t('buyer.navLinks.settings'),
					iconComponent: 'icon-settings',
				},
			]
		},
		supplierRoutes() {
			return [
				{
					name: this.$t('dashboard'),
					path: '/supplier/dashboard',
					iconComponent: 'icon-dashboard',
				},
				{
					exact: false,
					path: '/supplier/establishment',
					name: this.$t('establishmentText'),
					iconComponent: 'icon-box',
				},
				{
					path: '/supplier/branches',
					name: this.$t('branches'),
					iconComponent: 'icon-store',
					requirePermissions: [PERMISSION.ACCOUNTS],
				},
				{
					path: '/supplier/users',
					name: this.$t('users'),
					iconComponent: 'icon-users',
					requirePermissions: [PERMISSION.USERS],
				},
				{
					path: '/supplier/products',
					name: this.$t('products'),
					iconComponent: 'icon-product',
					requirePermissions: [PERMISSION.PRODUCTS],
				},
				{
					path: '/supplier/offers',
					name: this.$t('offers'),
					iconComponent: 'icon-offer',
					requirePermissions: [PERMISSION.OFFERS],
				},
				{
					path: '/supplier/orders',
					name: this.$t('orders'),
					iconComponent: 'icon-orders',
					requirePermissions: [PERMISSION.ORDERS],
				},
				{
					path: '/supplier/complaints',
					name: this.$t('permissions.complaints'),
					iconComponent: 'icon-note-list',
					requirePermissions: [PERMISSION.COMPLAINT],
				},
				{
					path: '/supplier/contracts',
					name: this.$t('contracts'),
					iconComponent: 'icon-book',
					requirePermissions: [PERMISSION.CONTRACT],
				},
				{
					path: '/supplier/ads',
					name: this.$t('adsText'),
					iconComponent: 'icon-advertising',
					requirePermissions: [PERMISSION.ADS],
				},
				{
					path: '/supplier/invoices',
					name: this.$t('invoices'),
					icon: 'mdi-format-list-text',
				},
				{
					path: null,
					event: 'settings',
					name: this.$t('buyer.navLinks.settings'),
					iconComponent: 'icon-settings',
				},
			]
		},
		adminRoutes() {
			return [
				{
					name: this.$t('dashboard'),
					path: '/admin/dashboard',
					iconComponent: 'icon-dashboard',
					requirePermissions: [],
				},
				{
					path: '/admin/management',
					name: this.$t('management'),
					iconComponent: 'icon-box',
					requirePermissions: [PERMISSION.ACCOUNTS],
				},
				{
					path: '/admin/users',
					name: this.$t('users'),
					iconComponent: 'icon-users',
					requirePermissions: [PERMISSION.USERS],
				},
				{
					path: '/admin/products',
					name: this.$t('products'),
					iconComponent: 'icon-product',
					requirePermissions: [PERMISSION.PRODUCTS],
				},
				{
					path: '/admin/contracts',
					name: this.$t('contracts'),
					iconComponent: 'icon-book',
					requirePermissions: [PERMISSION.CONTRACT],
				},
				{
					path: '/admin/contracts/templates',
					name: this.$t('contract-templates'),
					icon: 'mdi-newspaper-variant-outline',
					requirePermissions: [PERMISSION.CONTRACT],
				},
				{
					path: '/admin/package',
					name: this.$t('package'),
					iconComponent: 'icon-package',
					requirePermissions: [PERMISSION.SUBSCRIPTION],
				},
				{
					path: '/admin/orders',
					name: this.$t('orders'),
					iconComponent: 'icon-orders',
					requirePermissions: [PERMISSION.ORDERS],
				},
				{
					path: '/admin/complaints',
					name: this.$t('permissions.complaints'),
					iconComponent: 'icon-note-list',
					requirePermissions: [PERMISSION.COMPLAINT],
				},
				{
					path: '/admin/offers',
					name: this.$t('offers'),
					iconComponent: 'icon-offer',
					requirePermissions: [PERMISSION.OFFERS],
				},
				{
					path: '/admin/ad-spaces',
					name: this.$t('ad-spaces'),
					iconComponent: 'icon-advertising',
					requirePermissions: [PERMISSION.ADS],
				},
				{
					path: '/admin/ads',
					name: this.$t('ad-requests'),
					iconComponent: 'icon-advertising',
					requirePermissions: [PERMISSION.ADS],
				},
				{
					path: '/admin/invoices',
					name: this.$t('invoices'),
					icon: 'mdi-format-list-text',
				},
				{
					path: '/admin/banners',
					name: this.$t('banners'),
					icon: 'mdi-view-carousel-outline',
					requirePermissions: [],
				},
				{
					path: '/admin/categories',
					name: this.$t('categories'),
					iconComponent: 'icon-note-list',
					requirePermissions: [],
				},
				{
					path: '/admin/banks',
					name: this.$t('banks'),
					iconComponent: 'icon-bank',
					requirePermissions: [],
				},
				{
					path: null,
					event: 'settings',
					name: this.$t('buyer.navLinks.settings'),
					iconComponent: 'icon-settings',
					requirePermissions: [],
				},
			]
		},
		currentPermissions() {
			if (!this?.$currentUser?.permissions) return []
			return this.$currentUser.permissions.map(p => {
				return typeof p === 'string' ? p : p.slug
			})
		},
		routes() {
			if (!this.$currentUser) return []
			const routes =
				{
					[ROLE.BUYER]: this.buyerRoutes,
					[ROLE.ADMIN]: this.adminRoutes,
					[ROLE.SUPPLIER]: this.supplierRoutes,
				}[this.$currentUser.role] ?? []

			return routes.filter(link => {
				if (!link.requirePermissions || !link.requirePermissions.length) {
					return true
				}
				return link.requirePermissions.every(rp => {
					return this.currentPermissions.includes(rp)
				})
			})
		},
	},
	methods: {
		...mapMutations(['SET']),
		...mapActions('auth', ['logout', 'fetchProfile', 'updateCurrentUser']),
		...mapActions(['toggleDrawer', 'toggleLoading', 'toggleTheme']),
		isExact(route) {
			return route.exact ?? true
		},
		onInitLanguageChange() {
			this.$store.commit('settings/SET', {
				'language.dialog': true,
			})
		},
		onOpenSettings() {
			this.$store.commit('settings/SET', {
				settings: v => ({
					...v,
					dialog: true,
				}),
			})
		},
		onClickSidebarNavListItem(route) {
			this.currentRoute = route.name
			if (route.event) {
				this.$store.commit('settings/SET', {
					[route.event]: v => ({
						...v,
						dialog: true,
					}),
				})
			}
		},
		handleLogout() {
			this.SET({
				logout: {
					dialog: true,
					isLoading: false,
				},
			})
		},
		onChangeTheme() {
			const theme = !this.$theme.dark
			this.$vuetify.theme.dark = theme
			this.toggleTheme(theme)
		},
	},
}
</script>
