<template>
	<div class="px-3 app-bar" ref="appBarRef">
		<v-app-bar
			app
			flat
			class="rounded"
			:height="height"
			:dark="theme.dark"
			:color="$vuetify.theme.isDark ? '' : 'white'"
		>
			<v-app-bar-nav-icon class="md:tw-hidden" @click="$emit('drawer')" />
			<img
				@click="$route.path !== '/' && $router.push('/')"
				class="tw-ms-16 tw-cursor-pointer"
				src="@/assets/images/nav-logo.png"
				alt
			/>
			<div v-if="$currentUser.isBuyer" class="tw-w-197px tw-ms-36">
				<v-text-field
					v-model="search"
					:placeholder="$t('actions.search')"
					filled
					rounded
					full-width
					class="search"
					prepend-icon="mdi-magnify"
					hide-details="auto"
					background-color="white"
					@keyup.enter="oninputSearch"
				/>
				<!-- disabled -->
			</div>
			<v-spacer></v-spacer>
			<slot name="actions" />
		</v-app-bar>
		<v-progress-linear
			top
			left
			absolute
			height="3"
			indeterminate
			color="secondary"
			v-if="loading"
		/>
	</div>
</template>

<script>
import { omitEmpties } from 'vuelpers'
import { mapGetters, mapActions } from 'vuex'
export default {
	name: 'AppBar',
	props: {
		title: String,
		isAuth: Boolean,
		theme: {
			dark: Boolean,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			search: '',
			height: 0,
		}
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
	},
	methods: {
		...mapActions('products', ['getProducts']),
		...mapActions('offers', ['getRunningOffers']),
		async oninputSearch() {
			this.$route.path != '/products' && this.$router.push('/products')
			await this.fetchSearchProdcut()
		},

		async fetchSearchProdcut() {
			await this.getProducts(
				omitEmpties({
					query: this.search,
				})
			)
			await this.getRunningOffers(omitEmpties({ query: this.search }))
		},
	},
	mounted() {
		this.height = this.$refs.appBarRef.getBoundingClientRect().height + 16
		document.documentElement.style.cssText += `--header-height:${this.height}px;`
	},
}
</script>
<style lang="scss">
.app-bar {
	top: 0;
	z-index: 1;
	width: 100%;
	height: 4.5rem;
	position: fixed;
	&::before {
		@include inset();
		content: '';
		display: block;
		position: absolute;
		transition: none !important;
		background: rgb(238, 238, 238);
		background: linear-gradient(
			0deg,
			rgba(238, 238, 238, 0) 0%,
			rgba(238, 238, 238, 0.8) 70%,
			rgba(238, 238, 238, 1) 100%
		);
	}
}
.theme--dark {
	.app-bar {
		&::before {
			background: rgb(33, 33, 33);
			background: linear-gradient(
				0deg,
				rgba(33, 33, 33, 0) 0%,
				rgba(33, 33, 33, 0.8) 70%,
				rgba(33, 33, 33, 1) 100%
			);
		}
	}
}
.search .v-input__slot {
	padding: 0 !important;
}
</style>
