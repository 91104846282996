import { sumBy } from 'lodash'
import { TAX_RATE } from '../consts'

const useOrder = (order) => {
	order.orderOffers = order.orderOffers.map((orderOffer) => {
		return {
			...orderOffer,
			priceWod: orderOffer.products.reduce((total, { pivot }) => {
				return total + pivot.quantity * pivot.productPrice
			}, 0),
		}
	})

	// WITHOUT DISCOUNT
	order.sumOffersWod = sumBy(order.orderOffers, (orderOffer) => {
		return orderOffer.priceWod * orderOffer.quantity
	})
	order.sumProductsWod = sumBy(order.products, ({ pivot }) => {
		return pivot.priceWod * pivot.quantity
	})

   // AFTER DISCOUNT
	order.sumOffers = sumBy(order.orderOffers, (offer) => {
		return offer.price * offer.quantity
	})
	order.sumProducts = sumBy(order.products, ({ pivot }) => {
		return pivot.price * pivot.quantity
	})

	order.shippingFee = 0
	order.subtotalWd = order.sumProducts + order.sumOffers
	order.subtotal = order.sumProductsWod + order.sumOffersWod

	order.taxRate = TAX_RATE
	order.tax = order.subtotal * order.taxRate

	order.subtotalWTx = order.subtotal + order.tax
	order.discount = order.subtotal - order.subtotalWd

	order.total = order.subtotalWTx - order.discount

	return order
}

export { useOrder }
