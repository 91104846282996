<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="17.784"
		height="21.43"
		viewBox="0 0 17.784 21.43"
	>
		<g id="login" transform="translate(0.15 0.15)">
			<path
				id="Path_9369"
				data-name="Path 9369"
				d="M118.524,0H104a.66.66,0,0,0-.66.66V2.641h1.321V1.321h13.206V19.809H104.658V18.488h-1.321v1.981a.66.66,0,0,0,.66.66h14.527a.66.66,0,0,0,.66-.66V.66a.66.66,0,0,0-.66-.66Z"
				transform="translate(-101.7 0)"
				fill="#fff"
				stroke="#fff"
				stroke-width="0.3"
			/>
			<path
				id="Path_9370"
				data-name="Path 9370"
				d="M40.969,167.975l.934.934,4.429-4.429L41.9,160.051l-.934.934,2.835,2.835H34.446v1.321H43.8Z"
				transform="translate(-34.446 -153.915)"
				fill="#fff"
				stroke="#fff"
				stroke-width="0.3"
			/>
		</g>
	</svg>
</template>
<script>
export default {
	name: 'IconLogin',
}
</script>
