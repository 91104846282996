<template>
	<v-dialog
		v-model="addressForm.isVisible"
		max-width="900"
		content-class="tw-rounded-xl md:tw-rounded-29px"
	>
		<v-form v-model="isFormValid" ref="formRef" @submit.prevent="onSubmit">
			<v-card class="tw-px-6 tw-py-10">
				<v-card-title class="pt-0">
					{{ $t(addressForm.isUpdate ? 'updateAddress' : 'addAddress') }}
				</v-card-title>
				<v-card-subtitle>{{ $t('fillAddress') }}</v-card-subtitle>
				<v-card-text>
					<v-divider class="mb-5"></v-divider>
					<v-row>
						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('recipient') }}
							</div>
							<v-text-field
								v-model="address.recipientName"
								flat
								dense
								outlined
								class="user-input"
								name="recipientName"
								hide-details="auto"
								background-color="#FBFBFB"
								:placeholder="$t('writeName')"
								:rules="[rules.required($t('recipientName'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="error.recipientName || []"
							/>
						</v-col>

						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('phoneNumber') }}
							</div>
							<v-text-field
								v-model="address.phone"
								flat
								dense
								outlined
								type="tel"
								name="phone"
								class="user-input"
								hide-details="auto"
								background-color="#FBFBFB"
								:placeholder="$t('phoneNumber')"
								:rules="[rules.required($t('phoneNumber'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="error.phone || []"
							/>
						</v-col>

						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('buildingNo') }}
							</div>
							<v-text-field
								v-model="address.buildingNumber"
								flat
								dense
								outlined
								type="text"
								class="user-input"
								name="buildingNumber"
								hide-details="auto"
								background-color="#FBFBFB"
								:placeholder="$t('writeBuidingNo')"
								:rules="[rules.required($t('buildingNo'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="error.buildingNumber || []"
							/>
						</v-col>

						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('city') }}
							</div>
							<v-autocomplete
								v-model="address.city"
								flat
								dense
								outlined
								name="city"
								class="user-input pa-0"
								hide-details="auto"
								item-value="value"
								background-color="#FBFBFB"
								:placeholder="$t('selectCity')"
								:items="cities"
								:item-text="`name_${$i18n.locale}`"
								:rules="[rules.required($t('city'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="error.city || []"
								@input="error.city = []"
							></v-autocomplete>
						</v-col>

						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('neighborhood') }}
							</div>
							<v-text-field
								v-model="address.neighborhoodName"
								flat
								dense
								outlined
								type="text"
								class="user-input"
								name="neighborhoodName"
								hide-details="auto"
								background-color="#FBFBFB"
								:placeholder="$t('writeNeighborhoodName')"
								:rules="[rules.required($t('neighborhood'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="error.neighborhoodName || []"
							/>
						</v-col>

						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('street') }}
							</div>
							<v-text-field
								v-model="address.streetName"
								flat
								dense
								outlined
								type="text"
								class="user-input"
								name="streetName"
								hide-details="auto"
								background-color="#FBFBFB"
								:placeholder="$t('writeStreetName')"
								:rules="[rules.required($t('street'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="error.streetName || []"
							/>
						</v-col>

						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('zipCode') }}
							</div>
							<v-text-field
								v-model="address.postalCode"
								flat
								dense
								outlined
								type="text"
								class="user-input"
								name="postalCode"
								hide-details="auto"
								background-color="#FBFBFB"
								:placeholder="$t('zipCode')"
								:rules="[rules.required($t('zipCode'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="error.postalCode || []"
							/>
						</v-col>

						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('extraNumber') }}
							</div>
							<v-text-field
								v-model="address.extraNumber"
								flat
								dense
								outlined
								type="text"
								class="user-input"
								name="extraNumber"
								hide-details="auto"
								background-color="#FBFBFB"
								:placeholder="$t('extraNumber')"
								:rules="[rules.required($t('extraNumber'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="error.extraNumber || []"
							/>
						</v-col>

						<v-col cols="12" md="4" sm="6">
							<div
								class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-47"
							>
								{{ $t('unitNumber') }}
							</div>
							<v-text-field
								v-model="address.unitNumber"
								flat
								dense
								outlined
								type="text"
								class="user-input"
								name="unitNumber"
								hide-details="auto"
								background-color="#FBFBFB"
								:placeholder="$t('unitNumber')"
								:rules="[rules.required($t('unitNumber'))]"
								:height="$vuetify.breakpoint.lgAndUp ? 60 : ''"
								:error-messages="error.unitNumber || []"
							/>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="tw-px-4 tw-pt-4">
					<v-btn
						text
						outlined
						height="58"
						color="primary"
						class="tw-flex-1 tw-rounded-lg"
						@click="onResetForm"
					>
						<span
							class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
							>{{ $t('actions.cancel') }}</span
						>
					</v-btn>
					<v-btn
						:loading="addressForm.isLoading"
						depressed
						height="58"
						type="submit"
						color="primary"
						class="tw-flex-1 tw-font-medium tw-rounded-lg"
					>
						<span
							class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
							>{{ $t('actions.submit') }}</span
						>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import cities from '@/assets/json/cities.min.json'

import { mapGetters, mapActions } from 'vuex'
import { createFormMixin } from '../../mixins/form-mixin'

export default {
	name: 'DialogAddressForm',
	mixins: [
		createFormMixin({
			rules: ['required'],
		}),
	],
	data: () => ({
		error: {},
		address: {},
		isFormValid: false,
	}),
	computed: {
		...mapGetters('settings', ['$addressForm']),
		cities() {
			return cities
		},
		addressForm: {
			get() {
				return this.$addressForm
			},
			set(v) {
				this.setSettingsState({
					addressForm: v,
				})
			},
		},
	},
	watch: {
		'addressForm.data': {
			deep: true,
			immediate: true,
			handler(v) {
				if (!v) return
				this.address = { ...v, recipientName: v.recipientName || v.name }

				const city = cities.find((city) => {
					return city.name_en === v.city || city.name_ar === v.city
				})
				if (city) this.address.city = city.value
			},
		},
	},
	methods: {
		...mapActions('settings', [
			'setSettingsState',
			'createAddress',
			'updateAddress',
			'resetAddressForm',
		]),
		onResetForm() {
			this.$refs.formRef.reset()
			this.resetAddressForm()
		},
		async onSubmit() {
			if (!this.$refs.formRef.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			this.addressForm.isLoading = true
			let request = this.addressForm.isUpdate
				? this.updateAddress
				: this.createAddress

			const [err] = await request({
				address: {
					...this.address,
					isShowing: true,
					name: this.address.recipientName,
					unitNumber: `${this.address.unitNumber}`,
					extraNumber: `${this.address.extraNumber}`,
					buildingNumber: `${this.address.buildingNumber}`,
					city: cities.find((city) => {
						return city.value === this.address.city
					})?.name_en,
				},
			})
			this.addressForm.isLoading = false

			if (err) {
				this.$utils.scrollIntoError(this)
				return console.log('err', err)
			}

			this.onResetForm()
		},
	},
}
</script>
