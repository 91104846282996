<template>
	<component v-model="menu" v-bind="vBind" :is="tag">
		<!-- CARET -->
		<div v-if="dropdown" class="cart-list__caret tw-shadow tw-z-10"></div>

		<!-- TOGGLER -->
		<template v-if="dropdown" #activator="{ on, attrs }">
			<v-badge
				top
				offset-y="26"
				offset-x="0"
				color="transparent"
				class="cart-badge tw-text-72 tw-me-2"
				:content="$cartItemsCount ? $cartItemsCount : null"
			>
				<v-btn
					icon
					width="36"
					v-on="on"
					height="36"
					color="#722432"
					v-bind="attrs"
					class="tw-z-10"
				>
					<icon-cart class="tw-h-6"></icon-cart>
				</v-btn>
			</v-badge>
		</template>

		<!-- CART ITEMS -->
		<v-card
			flat
			:width="width"
			class="tw-relative tw-rounded-2xl scrollbar tw-z-10"
			style="max-height: 80vh"
		>
			<v-card-text class="pb-0">
				<h4
					v-if="!hideTitle"
					class="tw-uppercase tw-text-18px tw-font-semibold tw-text-black tw-mt-5 tw-ps-4"
				>
					{{ $t('cart-items') }}
				</h4>

				<v-list
					v-if="$cartItemsCount"
					three-line
					class="tw-space-y-2 pa-0"
					:class="!hideTitle ? 'tw-mt-6' : ''"
				>
					<cart-list-item
						v-for="item in $cart.products.data"
						:item="item"
						:key="item.id"
						:inline="inline"
					/>
					<!-- :class="{ 'tw-mt-8': itemIndex }" -->
				</v-list>

				<div v-else class="tw-py-5 tw-ps-4">
					{{ $t('cart-is-empty') }}
				</div>
			</v-card-text>

			<v-card-text class="pt-0">
				<h4
					v-if="!hideTitle"
					class="tw-uppercase tw-text-18px tw-font-semibold tw-text-black tw-mt-5 tw-ps-4"
				>
					{{ $t('offer-items') }}
				</h4>

				<v-list
					v-if="$cartItemsCount"
					three-line
					class="pa-0 tw-space-y-2"
					:class="!hideTitle ? 'tw-mt-6' : ''"
				>
					<!-- v-for="(offer, itemIndex) in $cart.offers.data" -->
					<!-- :class="{ 'tw-mt-8': itemIndex }" -->
					<OfferCartListItem
						v-for="offer in $cart.offers.data"
						:item="offer"
						:key="offer.id"
						:inline="inline"
					/>
					<!-- <div
						v-for="(offer, itemIndex) in $cart.offers.data"
						:item="offer"
						:key="offer.id"
						:inline="inline"
						:class="{ 'tw-mt-8': itemIndex }"
					>
						Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatem
						enim ipsam corrupti impedit excepturi, aut, sint voluptates,
						praesentium deserunt obcaecati nemo itaque consectetur iste saepe
						doloribus pariatur veniam illum aliquid!
					</div> -->
				</v-list>

				<div v-else class="tw-py-5 tw-ps-4">
					{{ $t('cart-is-empty') }}
				</div>
			</v-card-text>

			<v-card-actions
				v-if="!hideActions && $cartItemsCount"
				class="tw-mt-6 px-7 tw-pb-7 tw-sticky tw-bottom-0 tw-bg-white"
			>
				<base-button block height="60" @click="showCartPreview">{{
					$t('proceed-to-preview')
				}}</base-button>
			</v-card-actions>
		</v-card>

		<!-- OVERLAY -->
		<v-overlay
			:value="menu"
			color="black"
			opacity="0.18"
			@click="menu = false"
		></v-overlay>
	</component>
</template>

<script>
import { VMenu } from 'vuetify/lib/components'
import { mapActions, mapGetters } from 'vuex'
import CartListItem from './CartListItem.vue'
import OfferCartListItem from './OfferCartListItem.vue'
export default {
	name: 'CartList',
	props: {
		inline: Boolean,
		dropdown: Boolean,
		hideTitle: Boolean,
		hideActions: Boolean,
		width: {
			type: String,
			default: '460px',
		},
	},
	data: () => ({
		menu: false,
	}),
	components: {
		VMenu,
		CartListItem,
		OfferCartListItem,
	},
	created() {
		this.getCartList()
	},
	computed: {
		...mapGetters('cart', ['$cart', '$cartItemsCount']),
		tag() {
			if (!this.dropdown) return 'div'
			return 'v-menu'
		},
		vBind() {
			if (!this.dropdown) {
				return {
					class: 'cart-list',
				}
			}
			return {
				left: true,
				'nudge-top': '-60px',
				'nudge-right': '60px',
				'content-class': 'cart-list tw-rounded-2xl',
				'close-on-content-click': false,
			}
		},
	},
	methods: {
		...mapActions('cart', ['getCartList', 'showCartPreview']),
	},
}
</script>

<style lang="scss">
.cart-list {
	&__caret {
		width: 12px;
		height: 12px;
		background-color: white;
		transform: rotate(45deg);
		position: absolute;
		top: -6px;
		right: 4rem;
	}
	&.v-menu__content {
		z-index: 50 !important;
		contain: unset !important;
		overflow: visible !important;
	}
}
</style>
