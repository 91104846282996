import { Supplier } from '../../api'
import { initialList } from '../store.helpers'
import { createGetters, createMutations, handleAction } from 'vuelpers'

const initialState = () => {
	return {
		supplier: {},
		suppliers: initialList(),
		activeSuppliers: initialList(),
		pendingSuppliers: initialList(),
		rejectedSuppliers: initialList(),
	}
}

const state = initialState()

const getters = createGetters(
	'supplier',
	'suppliers',
	'activeSuppliers',
	'pendingSuppliers',
	'rejectedSuppliers'
)

const mutations = {
	...createMutations('SET', 'PUSH', 'UNSHIFT', 'DELETE', 'UPDATE', 'MERGE'),
}

const actions = {
	changeSupplierStatus({ dispatch, state }, payload) {
		return handleAction(Supplier.changeStatus(payload), () => {
			const payload = {
				page: state.suppliers.currentPage,
				perPage: state.suppliers.perPage,
			}
			dispatch('getActiveSuppliers', payload)
			dispatch('getRejectedSuppliers', payload)
		})
	},
	deleteSupplier({ commit, dispatch, state }, payload) {
		return handleAction(Supplier.delete(payload), () => {
			const paths = [
				'suppliers',
				'activeSuppliers',
				'pendingSuppliers',
				'rejectedSuppliers',
			]
			paths.forEach((path) => commit('DELETE', [`${path}.data`, payload]))

			dispatch('getActiveSuppliers', {
				page: state.suppliers.currentPage,
				perPage: state.suppliers.perPage,
			})
		})
	},
	updateSupplier(_, payload = {}) {
		return handleAction(Supplier.update(payload), (res) => {
			console.log('updateSupplier', res)
		})
	},
	supplierApprove(_, payload = {}) {
		return handleAction(Supplier.review(payload), () => {
			// commit('SET', { buyers: res.buyers })
			console.log('supplier approve')
		})
	},

	supplierProfileEdit(_, payload = {}) {
		return handleAction(Supplier.update(payload), (res) => {
			console.log(res)
		})
	},
	getSuppliersByStatus({ commit }, payload) {
		const path = `${payload.status.split(',').shift()}Suppliers`
		commit('SET', {
			[path]: (v) => ({
				...v,
				isLoading: !v.isLoaded,
				isRefreshing: v.isLoaded,
			}),
		})
		return handleAction(Supplier.get(payload), (res) => {
			commit('SET', {
				[path]: (v) => ({
					...v,
					...res.suppliers,
					isLoaded: true,
					isLoading: false,
					isRefreshing: false,
				}),
			})
		})
	},
	getRejectedSuppliers({ dispatch }, payload = {}) {
		return dispatch('getSuppliersByStatus', {
			...payload,
			status: 'rejected',
		})
	},
	getPendingSuppliers({ dispatch }, payload = {}) {
		return dispatch('getSuppliersByStatus', {
			...payload,
			status: 'pending',
		})
	},
	getActiveSuppliers({ dispatch }, payload = {}) {
		return dispatch('getSuppliersByStatus', {
			...payload,
			status: 'active,deactive',
		})
	},
	getSupplier({ commit, state: { suppliers } }, payload) {
		commit('SET', {
			suppliers: (v) => ({
				...v,
				isLoading: !suppliers.isLoaded,
				isRefreshing: suppliers.isLoaded,
			}),
		})
		return handleAction(Supplier.get(payload), (res) => {
			commit('SET', {
				suppliers: {
					...res.suppliers,
					isLoaded: true,
					isLoading: false,
					isRefreshing: false,
				},
			})
		})
	},
	getSingleSupplier({ commit }, payload = {}) {
		return handleAction(Supplier.getById(payload), (res) => {
			commit('SET', { supplier: res.supplier })
			console.log(res)
			console.log('get suppliers')
		})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
