<template>
	<div>
		<div>
			<v-skeleton-loader
				v-if="$banners.isRefetching"
				type="image"
				style="height: 420px"
				class="tw-rounded-3xl banner-skeleton"
			/>

			<v-carousel
				v-else
				interval="3000"
				class="tw-rounded-3xl"
				cycle
				show-arrows-on-hover
				hide-delimiter-background
			>
				<v-carousel-item
					v-for="banner in $banners.data"
					:key="banner.id"
					:alt="banner.attachment.attachedName"
					:src="$image(banner.attachment.attachedLink)"
					:aspect-ratio="16 / 5"
					cover
					rounded="xl"
					class="tw-bg-gray-100"
				/>
			</v-carousel>
		</div>

		<div>
			<!-- Categories -->
			<div class="xl:tw-mt-8 tw-mt-4">
				<div class="tw-flex tw-items-center tw-justify-between">
					<h2 class="tw-text-xl tw-font-semibold tw-mb-7 tw-font-pop">
						{{ $t('home.ourCategories') }}
					</h2>
					<div
						v-if="!showAllCategories"
						class="tw-flex tw-items-center tw-text-F2 tw-cursor-pointer"
						@click="showAllCategories = true"
					>
						{{ $t('viewAll') }}
						<v-icon color="#F2CA51">mdi-chevron-right</v-icon>
						<v-icon color="#F2CA51" class="tw--ms-4"
							>mdi-chevron-right</v-icon
						>
					</div>
				</div>
				<div
					class="tw-flex tw-overflow-hidden tw--ms-4 tw--mt-4 lg:tw--ms-9 lg:tw--mt-9"
					:class="{ 'tw-flex-wrap': showAllCategories }"
				>
					<template
						v-if="
							$productCategories.isRefreshing ||
							!$productCategories.isLoaded
						"
					>
						<CategoryCardSkeleton v-for="i in 11" :key="i" />
					</template>
					<template v-else>
						<CategoryCard
							v-for="(item, index) in $productCategories.data"
							:key="index"
							:item="item"
							:isSelected="selectedCategoryId === item.id"
							class="tw-cursor-pointer tw-flex-none tw-ms-4 tw-mt-4 lg:tw-ms-9 lg:tw-mt-9"
							@onClick="onSelectCategory"
						/>
					</template>
				</div>
			</div>

			<!-- Todays best picks -->
			<div
				v-if="$todayBestPickProducts.data.length"
				class="tw-mt-4 xl:tw-mt-9"
			>
				<div class="tw-flex tw-items-center tw-justify-between">
					<h2 class="tw-text-xl tw-font-semibold tw-mb-7">
						{{ $t('todayBestPick') }}
					</h2>
					<div
						@click="$router.push('/best-pick-products')"
						class="tw-flex tw-items-center tw-text-F2 tw-cursor-pointer tw-font-pop tw-text-base"
					>
						{{ $t('viewAll') }}
						<v-icon color="#F2CA51">mdi-chevron-right</v-icon>
						<v-icon color="#F2CA51" class="tw--ms-4"
							>mdi-chevron-right</v-icon
						>
					</div>
				</div>
				<div
					class="tw-flex tw-items-center tw-space-s-7 tw-justify-between tw-mb-12"
				>
					<v-row>
						<v-col
							v-for="(item, i) in $todayBestPickProducts.data.slice(
								0,
								6
							)"
							:key="i"
							cols="12"
							sm="6"
							md="4"
							lg="3"
							xl="2"
						>
							<ProductCard
								@onClick="goToSingleProduct"
								:productInfo="item"
								class="tw-flex-none"
							/>
						</v-col>
					</v-row>
				</div>
			</div>

			<!-- Offers -->
			<div
				class="tw-flex tw-items-center tw-justify-between tw-mt-4 xl:tw-mt-9"
			>
				<h2 class="tw-text-xl tw-font-semibold">
					{{ $t('offers') }}
				</h2>
				<div
					@click="$router.push('/all-offers')"
					class="tw-flex tw-items-center tw-text-F2 tw-cursor-pointer"
				>
					{{ $t('viewAll') }}
					<v-icon color="#F2CA51">mdi-chevron-right</v-icon>
					<v-icon color="#F2CA51" class="tw--ms-4">
						mdi-chevron-right
					</v-icon>
				</div>
			</div>

			<v-container fluid>
				<v-row v-if="!$offers.isLoaded" class="tw-mt-4">
					<v-col
						v-for="i in 6"
						:key="i"
						cols="12"
						sm="6"
						md="4"
						lg="3"
						xl="2"
					>
						<ProductCardSkeleton />
					</v-col>
				</v-row>
				<v-row v-else>
					<v-col
						v-for="(offer, i) in $offers.data.slice(0, 4)"
						:key="i"
						sm="6"
						md="3"
						cols="12"
					>
						<OfferCard
							:offer="offer"
							hide-details
							class="tw-flex-none"
							style="height: 341px"
						/>
					</v-col>
				</v-row>
			</v-container>

			<!-- Top Selling -->
			<div v-if="$topSellingProducts.data.length" class="tw-mt-4 xl:tw-mt-9">
				<div class="tw-flex tw-items-center tw-justify-between">
					<h2 class="tw-text-xl tw-font-semibold tw-mb-7">
						{{ $t('topSelling') }}
					</h2>
					<div
						@click="$router.push('/top-selling-products')"
						class="tw-flex tw-items-center tw-text-F2 tw-cursor-pointer"
					>
						{{ $t('viewAll') }}
						<v-icon color="#F2CA51">mdi-chevron-right</v-icon>
						<v-icon color="#F2CA51" class="tw--ms-4"
							>mdi-chevron-right</v-icon
						>
					</div>
				</div>

				<div
					class="tw-flex tw-items-center tw-space-s-7 tw-justify-between tw-mb-12"
				>
					<v-container fluid>
						<v-row>
							<v-col
								v-for="(item, i) in $topSellingProducts.data.slice(
									0,
									6
								)"
								:key="i"
								cols="12"
								sm="6"
								md="4"
								lg="3"
								xl="2"
							>
								<ProductCard
									class="tw-flex-none"
									:productInfo="item"
									@onClick="goToSingleProduct"
								/>
							</v-col>
						</v-row>
					</v-container>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import OfferCard from '../components/customs/OfferCard.vue'
import ProductCard from '../components/customs/ProductCard.vue'
import CategoryCard from '../components/customs/CategoryCard.vue'
import CategoryCardSkeleton from '../components/CategoryCardSkeleton.vue'
import ProductCardSkeleton from '../components/customs/ProductCardSkeleton.vue'

export default {
	name: 'Home',
	components: {
		CategoryCard,
		ProductCard,
		OfferCard,
		CategoryCardSkeleton,
		ProductCardSkeleton,
	},
	data() {
		return {
			loading: true,
			showAllCategories: false,
			selectedCategoryId: null,
			attrs: {
				class: 'mb-2',
				boilerplate: false,
				elevation: 2,
			},
		}
	},
	created() {
		this.getBanners()
		this.getCategories({ type: 'product', perPage: 0 })
		this.getRunningOffers()
		this.getTodayBestPickProducts()
		this.getTopSellingProducts()

		// this.getProducts()
		// this.loading = false
	},
	computed: {
		...mapGetters('offers', ['$offers']),
		...mapGetters('banners', ['$banners']),
		...mapGetters('category', ['$productCategories']),
		...mapGetters('products', [
			// '$products',
			'$topSellingProducts',
			'$todayBestPickProducts',
		]),
	},
	methods: {
		...mapActions('banners', ['getBanners']),
		...mapActions('category', ['getCategories']),
		...mapActions('offers', ['getRunningOffers']),
		...mapActions('products', [
			'getProducts',
			'getTopSellingProducts',
			'getTodayBestPickProducts',
		]),
		onSelectCategory(id) {
			this.selectedCategoryId = id
			this.$router.push({
				name: this.$t('headers.products'),
				query: { selectedCategory: id },
			})
		},
		goToSingleProduct(id) {
			this.$router.push(`product/${id}`)
		},
		// async fetchProducts() {
		// 	if (
		// 		!(
		// 			this.$products &&
		// 			this.$products.data &&
		// 			this.$products.data.length
		// 		)
		// 	) {
		// 		await this.getProducts()
		// 	}
		// },
	},
}
</script>

<style lang="scss">
.cart-btn.v-btn {
	min-width: 0 !important;
	width: 39px;
}
.banner-skeleton .v-skeleton-loader__image {
	height: 420px !important;
}
</style>
