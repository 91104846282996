import { Contracts } from '../../api'
import { initialList } from '../store.helpers'
import { createGetters, createMutations, handleAction } from 'vuelpers'

const initialState = () => {
	return {
		buyers: initialList(),
		suppliers: initialList(),
		buyerContracts: initialList(),
		supplierContracts: initialList(),
		contractDetails: initialList(),
	}
}

const state = initialState()

const contractables = {
	'App\\Models\\Buyer': 'buyerContracts.data',
	'App\\Models\\Supplier': 'supplierContracts.data',
}

const getters = {
	...createGetters(
		'buyers',
		'suppliers',
		'buyerContracts',
		'supplierContracts',
		'contractDetails'
	),
}

const mutations = {
	...createMutations('SET', 'PUSH', 'UNSHIFT', 'DELETE', 'UPDATE', 'MERGE'),
}

const actions = {
	signContract(_, payload) {
		return handleAction(Contracts.signContract(payload))
	},
	createContractDetails({ commit }, payload) {
		return handleAction(Contracts.createDetails(payload), (res) => {
			commit('PUSH', { 'contractDetails.data': res.contractDetails })
		})
	},
	createContract({ commit }, payload) {
		return handleAction(Contracts.post(payload), (res) => {
			commit('UNSHIFT', {
				[contractables[res.contract.contractableType]]: res.contract,
			})
		})
	},
	deleteContract({ commit }, payload = {}) {
		return handleAction(Contracts.delete(payload.id), () => {
			commit('DELETE', [contractables[payload.contractableType], payload.id])
		})
	},
	updateContract({ commit }, payload = {}) {
		return handleAction(Contracts.patch(payload), (res) => {
			commit('UPDATE', [
				contractables[res.contract.contractableType],
				res.contract,
			])
		})
	},
	updateContractDetails({ commit }, payload = {}) {
		return handleAction(Contracts.updateDetails(payload), (res) => {
			commit('UPDATE', ['contractDetails.data', res.contractDetails])
		})
	},
	getContract({ commit }, payload = {}) {
		return handleAction(
			Contracts.getById(payload.id, payload.query),
			(res) => {
				commit('UNSHIFT', [
					contractables[res.contract.contractableType],
					res.contract,
				])
			}
		)
	},
	getContracts({ commit }, payload = {}) {
		commit('SET', { 'supplierContracts.isRefreshing': true })
		commit('SET', { 'buyerContracts.isRefreshing': true })

		return handleAction(Contracts.get(payload), (res) => {
			commit('SET', {
				[`${payload.contractableType}Contracts`]: res.contracts,
			})
			commit('SET', { 'supplierContracts.isRefreshing': false })
			commit('SET', { 'buyerContracts.isRefreshing': false })
		})
	},
	getContractsDetails({ commit }, payload = {}) {
		commit('SET', { 'contractDetails.isRefreshing': true })

		return handleAction(Contracts.getDetails(payload), (res) => {
			commit('SET', { 'contractDetails.data': res.contractDetails })
			commit('SET', { 'contractDetails.isRefreshing': true })
		})
	},
	getBuyers({ commit }, payload = {}) {
		return handleAction(Contracts.getBuyers(payload), (res) => {
			commit('SET', { buyers: res.buyers })
		})
	},
	getSuppliers({ commit }, payload = {}) {
		return handleAction(Contracts.getSuppliers(payload), (res) => {
			commit('SET', { suppliers: res.suppliers })
		})
	},
	resetState({ commit }) {
		commit('SET', initialState())
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
