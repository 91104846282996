<template>
	<component :is="tag">
		<slot v-bind="parsed">
			{{ parsed.phone || phone }}
		</slot>
	</component>
</template>
<script>
import parsePhoneNumber from 'libphonenumber-js'
export default {
	name: 'BasePhoneNumber',
	props: {
		tag: {
			type: String,
			default: 'span',
		},
		phone: {
			required: true,
		},
	},
	data: () => ({
		parsed: {},
	}),
	watch: {
		phone: {
			immediate: true,
			handler(phone) {
				if (!phone) return
				const parsed = parsePhoneNumber(`${phone}`)
				if (!parsed) {
					this.parsed = {
						phone,
						country: null,
						parsedPhone: null,
					}
					return
				}
				this.parsed = {
					phone,
					country: parsed.country,
					parsedPhone: parsed.formatInternational(),
				}
			},
		},
	},
}
</script>
