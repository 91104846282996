<template>
	<v-card flat>
		<v-list-item class="my-4">
			<v-badge
				:content="_.capitalize($currentUser.role)"
				bottom
				bordered
				offset-x="76"
				offset-y="20"
			>
				<v-list-item-avatar
					size="76"
					color="primary"
					class="white--text tw-justify-center"
				>
					<v-img
						v-if="$currentUser.avatar"
						:alt="$currentUser.name"
						:src="$image($currentUser.avatar.attachedLink)"
					/>
					<span v-else class="text-h5">
						{{ $utils.useInitial($currentUser.name) }}
					</span>
				</v-list-item-avatar>
			</v-badge>
			<v-list-item-content>
				<v-list-item-title class="tw-text-xl tw-font-medium">
					{{ $currentUser.name }}
				</v-list-item-title>
				<v-list-item-subtitle class="tw-flex tw-items-center tw-space-s-2">
					<v-icon size="18">mdi-email-outline</v-icon>
					<span>{{ $currentUser.email }}</span>
				</v-list-item-subtitle>
				<v-list-item-subtitle class="tw-flex tw-items-center tw-space-s-2">
					<v-icon size="18">mdi-phone-outline</v-icon>
					<span>{{ $currentUser.phone }}</span>
				</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'AppBarMenuCurrentUser',
	computed: {
		...mapGetters('auth', ['$currentUser']),
	},
}
</script>
