import api from './base.api'

const Advertisements = {
	get: (query) => api.get('/advertisement', query),
	post: (body) => api.post('/advertisement/create', body),
	patch: (body) => api.post('/advertisement/update', body),
	changeStatus: (body) => api.patch('/advertisement', body),
	delete: (id) => api.delete('/advertisement/' + id),
	getById: (id, query) => api.get('/advertisement/' + id, query),
}

export { Advertisements }
