<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="31.287"
		height="28.112"
		viewBox="0 0 31.287 28.112"
	>
		<g
			id="_353403_cart_icon"
			data-name="353403_cart_icon"
			transform="translate(-6.693 -9.261)"
		>
			<path
				id="Path_34269"
				data-name="Path 34269"
				d="M44.5,17.837H26.252a.746.746,0,0,0-.756,1.027l2.886,8.455a1.25,1.25,0,0,0,1.123.826H41.467a1.248,1.248,0,0,0,1.122-.826L45.1,18.664A.6.6,0,0,0,44.5,17.837Z"
				transform="translate(-7.162 -3.275)"
				fill="#722432"
			/>
			<circle
				id="Ellipse_1195"
				data-name="Ellipse 1195"
				cx="2.729"
				cy="2.729"
				r="2.729"
				transform="translate(18.011 31.88)"
				fill="#722432"
			/>
			<circle
				id="Ellipse_1196"
				data-name="Ellipse 1196"
				cx="2.729"
				cy="2.729"
				r="2.729"
				transform="translate(29.56 31.915)"
				fill="#722432"
			/>
			<path
				id="Path_34270"
				data-name="Path 34270"
				d="M35.566,27.647H19.6L13.214,9.261H8.238a1.545,1.545,0,1,0,0,3.091h2.777l6.39,18.386h18.16a1.545,1.545,0,1,0,0-3.091Z"
				transform="translate(0)"
				fill="#722432"
			/>
		</g>
	</svg>
</template>
<script>
export default {
	name: 'IconCart',
}
</script>