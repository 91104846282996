<template>
	<v-dialog
		v-model="dialog"
		persistent
		max-width="676"
		content-class="tw-rounded-3xl tw-bg-white"
	>
		<v-card elevation="0" class="tw-p-0 tw-mx-0">
			<div class="tw-text-center tw-py-5 tw-px-6 lg:tw-py-10 lg:tw-px-9">
				<h1 class="tw-text-2xl tw-font-semibold">
					{{ $t('actions.delete') }} {{ title }}
					<template v-if="!hideId">
						<template>#</template>
						<template>{{ $get(id, 'id', id) }}</template>
					</template>
				</h1>
				<div
					class="tw-text-black tw-text-opacity-25 tw-mt-6 tw-mb-9 xl:tw-mt-8 xl:tw-mb-14 tw-text-center"
				>
					{{ $t('are-you-sure-to-delete', [title]) }}

					<slot></slot>
				</div>
				<v-card-actions class="tw-flex tw-space-s-4">
					<v-btn
						depressed
						color="#DF9999"
						outlined
						@click="dialog = false"
						class="tw-h-14 tw-flex-1 tw-rounded-lg"
					>
						<span class="tw-capitalize tw-text-lg tw-font-medium">
							{{ $t('actions.cancel') }}
						</span>
					</v-btn>
					<v-btn
						:loading="loading"
						dark
						depressed
						color="#DF9999"
						class="tw-h-14 tw-flex-1 tw-rounded-lg"
						@click="onConfirm"
					>
						<span class="tw-capitalize tw-text-lg tw-font-medium">
							{{ $t('actions.delete') }} {{ title }}
						</span>
					</v-btn>
				</v-card-actions>
			</div>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	name: 'DialogDeleteConfirmation',
	props: {
		id: {
			type: [String, Number, Object, Array],
		},
		hideId: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			required: true,
		},
		value: {
			type: Boolean,
			required: true,
		},
		deleteFunction: {
			type: Function,
			required: true,
		},
	},
	data: () => ({
		loading: false,
	}),
	computed: {
		dialog: {
			get() {
				return this.value
			},
			set(v) {
				this.$emit('input', v)
				this.$emit('update:id', v ? this.id : null)
			},
		},
	},
	methods: {
		async onConfirm() {
			this.loading = true
			const [err, res] = await this.deleteFunction(this.id)
			this.loading = false

			if (!err) {
				this.dialog = false
				this.$emit('deleted', res)
				return this.$toast.success(this.$t('success-delete', [this.title]))
			}
			this.$toast.error(this.$t('error-deleting', [this.title]))
		},
	},
}
</script>
