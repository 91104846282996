import { PERMISSION, ROLE } from '../consts'
import { view } from '../helpers'

import HomePage from '@/views/Home.vue'
import DefaultLayout from '@/layouts/Default.vue'

const buyerRoutes = {
	path: '/',
	component: DefaultLayout,
	children: [
		{
			path: '',
			name: 'Home',
			component: HomePage,
			meta: {
				requiresRole: [ROLE.BUYER],
				requiresAuth: true,
			},
		},
		{
			path: 'products',
			name: 'Products',
			component: view('Products'),
			meta: {
				requiresRole: [ROLE.BUYER],
				requiresAuth: true,
				requirePermissions: [PERMISSION.PRODUCTS],
			},
		},
		{
			path: 'top-selling-products',
			name: 'topSellingProducts',
			component: view('TopSellingProducts'),
			meta: {
				requiresRole: [ROLE.BUYER],
				requiresAuth: true,
				requirePermissions: [PERMISSION.PRODUCTS],
			},
		},
		{
			path: 'best-pick-products',
			name: 'TodayBestPick',
			component: view('TodayBestPick'),
			meta: {
				requiresRole: [ROLE.BUYER],
				requiresAuth: true,
				requirePermissions: [PERMISSION.PRODUCTS],
			},
		},
		{
			path: 'all-offers',
			name: 'AllOffers',
			component: view('allOffers'),
			meta: {
				requiresRole: [ROLE.BUYER],
				requiresAuth: true,
				requirePermissions: [PERMISSION.OFFERS],
			},
		},

		{
			path: 'product/:id',
			name: 'Product',
			component: view('ProductDetails'),
			meta: {
				requiresRole: [ROLE.BUYER],
				requiresAuth: true,
				requirePermissions: [PERMISSION.PRODUCTS],
			},
		},
		{
			path: 'users',
			name: 'Users',
			component: view('Users'),
			meta: {
				requiresRole: [ROLE.BUYER],
				requiresAuth: true,
				requirePermissions: [PERMISSION.USERS],
			},
		},
		{
			path: 'orders',
			name: 'Orders',
			component: view('Supplier/Orders'),
			meta: {
				requiresRole: [ROLE.BUYER],
				requiresAuth: true,
				requirePermissions: [PERMISSION.ORDERS],
			},
		},
		{
			path: 'offers/:id',
			name: 'offers',
			component: view('Offers'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.BUYER],
				requirePermissions: [PERMISSION.OFFERS],
			},
		},
		{
			path: 'complaints',
			name: 'Complaints',
			component: view('Complaints'),
			meta: {
				requiresRole: [ROLE.BUYER],
				requiresAuth: true,
				requirePermissions: [PERMISSION.COMPLAINT],
			},
		},
		{
			path: 'favorites',
			name: 'Favorites',
			component: view('Favorites'),
			meta: {
				requiresRole: [ROLE.BUYER],
				requiresAuth: true,
			},
		},
		{
			path: 'establishment',
			name: 'BuyerEstablishment',
			component: view('Supplier/Establishment'),
			meta: {
				requiresRole: [ROLE.BUYER],
				requiresAuth: true,
			},
		},
		{
			path: 'establishment/update',
			name: 'BuyerEstablishmentUpdate',
			component: view('Supplier/Establishment'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.BUYER],
			},
		},
		{
			path: 'branches',
			name: 'BuyerBranches',
			component: view('Supplier/Branches'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.BUYER],
			},
		},
		{
			path: 'branches/create',
			name: 'BuyerCreateBranch',
			component: view('Supplier/CreateBranch'),
			meta: {
				requiresRole: [ROLE.BUYER],
			},
		},
		{
			path: 'contracts',
			name: 'BuyerContracts',
			component: view('Admin/Contracts'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.BUYER],
				requirePermissions: [PERMISSION.CONTRACT],
			},
		},
		{
			path: 'subscription',
			name: 'BuyerSubscription',
			component: view('Subscription'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.BUYER],
			},
		},
		{
			path: 'packages',
			name: 'BuyerPackages',
			component: view('Packages'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.BUYER],
			},
		},
		{
			path: 'invoices',
			name: 'BuyerInvoices',
			component: view('Admin/Invoices'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.BUYER],
			},
		},
		{
			path: 'invoices/:id',
			name: 'BuyerInvoice',
			component: view('Admin/Invoice'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.BUYER],
			},
		},
		{
			path: 'settings',
			name: 'Settings',
			component: view('Settings'),
			meta: {
				// requiresRole: [ROLE.BUYER, ROLE.SUPPLIER],
				requiresAuth: true,
			},
		},
		{
			path: 'change-password',
			name: 'ChangePassword',
			component: view('ChangePassword'),
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: 'privacy-policy',
			name: 'PrivacyPolicy',
			component: view('PrivacyPolicy'),
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: 'terms-conditions',
			name: 'TermsConditions',
			component: view('TermsConditions'),
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: 'contact-us',
			name: 'ContactUS',
			component: view('ContactUS'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.BUYER, ROLE.SUPPLIER],
			},
		},
	],
}

export default buyerRoutes
