<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20.61"
			height="20.689"
			viewBox="0 0 20.61 20.689"
		>
			<g
				id="telephone_9_"
				data-name="telephone (9)"
				transform="translate(-0.912 0)"
			>
				<path
					id="Path_34051"
					data-name="Path 34051"
					d="M5.088,13.752a21.949,21.949,0,0,0,7.3,5.719,11.363,11.363,0,0,0,4.085,1.209c.1,0,.193.009.292.009a3.383,3.383,0,0,0,2.624-1.127.094.094,0,0,0,.017-.021,11.046,11.046,0,0,1,.827-.857c.2-.193.407-.394.6-.6A2.07,2.07,0,0,0,20.83,15l-2.576-2.576a2.085,2.085,0,0,0-1.509-.694,2.156,2.156,0,0,0-1.526.69l-1.535,1.535c-.141-.081-.287-.154-.424-.223a5.3,5.3,0,0,1-.472-.257,16.242,16.242,0,0,1-3.88-3.532A9.353,9.353,0,0,1,7.6,7.849c.4-.364.78-.746,1.145-1.119.129-.133.262-.266.394-.4a2.2,2.2,0,0,0,.712-1.543,2.18,2.18,0,0,0-.712-1.543L7.858,1.968c-.15-.15-.292-.3-.437-.446C7.137,1.23,6.842.93,6.55.66A2.137,2.137,0,0,0,5.041,0,2.2,2.2,0,0,0,3.515.664l-1.6,1.6A3.294,3.294,0,0,0,.93,4.377a7.934,7.934,0,0,0,.6,3.43A19.952,19.952,0,0,0,5.088,13.752ZM1.976,4.467a2.268,2.268,0,0,1,.682-1.458L4.252,1.415a1.154,1.154,0,0,1,.789-.364,1.1,1.1,0,0,1,.772.373c.287.266.557.544.849.84.146.15.3.3.446.454L8.385,4a1.164,1.164,0,0,1,.4.8,1.164,1.164,0,0,1-.4.8c-.133.133-.266.27-.4.4-.4.4-.772.785-1.183,1.149l-.021.021a.838.838,0,0,0-.214.952c0,.013.009.021.013.034a10.089,10.089,0,0,0,1.5,2.448,17.143,17.143,0,0,0,4.133,3.764,6.137,6.137,0,0,0,.566.309,5.3,5.3,0,0,1,.472.257l.047.026a.9.9,0,0,0,.416.107.909.909,0,0,0,.639-.292l1.6-1.6a1.134,1.134,0,0,1,.785-.382,1.077,1.077,0,0,1,.759.382l2.585,2.581a1.034,1.034,0,0,1-.013,1.616c-.18.193-.369.377-.57.57a12.345,12.345,0,0,0-.9.93,2.354,2.354,0,0,1-1.839.78c-.073,0-.15,0-.223-.009a10.323,10.323,0,0,1-3.7-1.106A20.851,20.851,0,0,1,5.9,13.092,19.1,19.1,0,0,1,2.516,7.455,6.914,6.914,0,0,1,1.976,4.467Z"
					transform="translate(0 0)"
				/>
			</g>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconCall',
}
</script>
