import { set } from 'lodash'

const useDocumentPaste = (callback = [], obj, setFunc) => {
	document.onpaste = (evt) => {
		const target = evt.clipboardData || window.clipboardData
		if (!target.files || !target.files.length) return

		const attachments = [...target.files].map((file) => {
			return { file, src: URL.createObjectURL(file) }
		})

		if (typeof callback === 'function') {
			return callback(attachments)
		}

		if (Array.isArray(callback) && obj) {
			callback.forEach((path, index) => {
				if (attachments[index]) {
					const attach = attachments[index]
					set(obj, path, setFunc ? setFunc(attach) : attach.file)
				}
			})
		}
	}

	return () => {
		document.onpaste = null
	}
}

export { useDocumentPaste }
