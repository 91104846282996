import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { i18n, hotUpdateLocale } from '@/plugins/vue-i18n'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		// dark: true,
		themes: {
			light: {
				primary: '#722432',
				accent: '#F2CA51',
			},
		},
	},
	rtl: i18n.locale === 'ar' ? true : false,
})
hotUpdateLocale()
