<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="17.372"
		height="17.312"
		viewBox="0 0 17.372 17.312"
	>
		<path
			id="Path_1424"
			data-name="Path 1424"
			d="M2.515,11.062A8.558,8.558,0,0,1,5.778,9.023a4.964,4.964,0,1,1,5.617,0,8.576,8.576,0,0,1,5.778,8.09H15.831a7.245,7.245,0,0,0-14.489,0H0A8.485,8.485,0,0,1,2.515,11.062ZM8.586,8.556a3.61,3.61,0,1,0-3.622-3.61A3.62,3.62,0,0,0,8.586,8.556Z"
			transform="translate(0.1 0.1)"
			fill="#fff"
			stroke="#fff"
			stroke-width="0.2"
		/>
	</svg>
</template>
<script>
export default {
	name: 'IconUser',
}
</script>
