<template>
	<v-dialog
		persistent
		max-width="676"
		v-model="logout.dialog"
		content-class="tw-rounded-3xl tw-bg-white"
	>
		<v-card elevation="0" class="tw-p-0 tw-mx-0">
			<div class="tw-text-center tw-py-5 tw-px-6 lg:tw-py-10 lg:tw-px-9">
				<h1 class="tw-text-2xl tw-font-semibold">{{ $t('logout') }}</h1>
				<div
					class="tw-text-black tw-text-opacity-25 tw-mt-6 tw-mb-9 xl:tw-mt-8 xl:tw-mb-14 tw-text-center"
				>
					{{ $t('logoutDesc') }}
				</div>
				<v-card-actions class="tw-flex tw-space-s-4">
					<v-btn
						depressed
						outlined
						color="#DF9999"
						@click="logout.dialog = false"
						class="tw-h-14 tw-flex-1 tw-rounded-lg"
					>
						<span class="tw-capitalize tw-text-lg tw-font-medium">
							{{ $t('noThanks') }}
						</span>
					</v-btn>
					<v-btn
						:loading="logout.isLoading"
						dark
						depressed
						color="#DF9999"
						class="tw-h-14 tw-flex-1 tw-rounded-lg"
						@click="onLogout"
					>
						<span class="tw-capitalize tw-text-lg tw-font-medium">
							{{ $t('yesPlease') }}
						</span>
					</v-btn>
				</v-card-actions>
			</div>
		</v-card>
	</v-dialog>
</template>
<script>
export default {
	name: 'DialogLogout',
	computed: {
		logout: {
			get() {
				return this.$store.getters['$logout']
			},
			set(logout) {
				this.$store.commit('SET', { logout })
			},
		},
	},
	methods: {
		async onLogout() {
			this.logout.isLoading = true
			await this.$store.dispatch('auth/logout')

			this.logout = {
				dialog: false,
				isLoading: false,
			}

			this.$router.replace('/login')
		},
	},
}
</script>
