import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

import { ROLE } from '../consts'
import { layout, view } from '../helpers'
import { isArray } from 'lodash'
import { DEFAULT_ROUTE, _baseURL } from '../consts'

// Routes
import buyerRoutes from './buyer.routes'
import supplierRoutes from './supplier.routes'
import adminRoutes from './admin.routes'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: _baseURL,
	routes: [
		buyerRoutes,
		supplierRoutes,
		adminRoutes,

		{
			path: '/',
			component: layout('ExtraLayout'),
			children: [
				{
					name: 'ContractSign',
					path: 'contract-sign',
					component: view('ContractSign'),
					meta: {
						requiresAuth: true,
					},
				},
				{
					name: 'Pending',
					path: 'pending',
					component: view('PendingView'),
					meta: {
						requiresAuth: true,
					},
				},
			],
		},
		{
			path: '/login',
			name: 'Login',
			component: view('Login'),
			meta: {
				requiresUnAuth: true,
			},
		},
		{
			path: '/sign-up',
			name: 'SignUp',
			component: view('SignUp'),
			meta: {
				requiresUnAuth: true,
			},
		},
		{
			path: '/forgot-password',
			name: 'ForgotPassword',
			component: view('ForgotPassword'),
			meta: {
				requiresUnAuth: true,
			},
		},
		{
			path: '/reset-password',
			name: 'ResetPassword',
			component: view('ResetPassword'),
			meta: {
				requiresUnAuth: true,
			},
		},
		{
			path: '/user-agreement',
			name: 'UserAgreement',
			component: view('UserAgreement'),
			meta: { requiresAuth: true },
		},
		{
			name: 'PaymentCallback',
			path: '/payment/callback',
			component: view('PaymentCallback'),
		},

		{
			path: '*',
			component: layout('EmptyLayout'),
			children: [
				{
					path: '',
					name: 'NotFound',
					component: view('404'),
				},
			],
		},
	],
})

const hasMeta = (to, key) => {
	return to.matched.some(route => route.meta[key])
}

router.beforeEach((to, from, next) => {
	const isLoggedIn = store.getters['auth/$isLoggedIn']

	if (!isLoggedIn && hasMeta(to, 'requiresAuth')) {
		// Ensure authentication
		return next('/login')
	}

	if (isLoggedIn) {
		// Ensure redirect from un auth routes
		const currentUser = store.getters['auth/$currentUser']
		const isContractSigned = store.getters['auth/isContractSigned']

		if (
			hasMeta(to, 'requiresUnAuth') ||
			(isContractSigned && to.path === '/contract-sign')
		) {
			return next(DEFAULT_ROUTE[currentUser.role])
		}
		if (to.path === '/' && to.path !== DEFAULT_ROUTE[currentUser.role]) {
			return next(DEFAULT_ROUTE[currentUser.role])
		}

		// Ensure role
		if (hasMeta(to, 'requiresRole')) {
			const requiresRole = isArray(to.meta.requiresRole)
				? to.meta.requiresRole
				: [to.meta.requiresRole]
			if (!requiresRole.includes(currentUser.role)) {
				return next('/404' + to.path)
			}
		}

		// Ensure permission
		if (hasMeta(to, 'requirePermissions')) {
			const requirePermissions = isArray(to.meta.requirePermissions)
				? to.meta.requirePermissions
				: [to.meta.requirePermissions]

			const currentPermissions = (currentUser?.permissions || []).map(p => {
				return typeof p === 'string' ? p : p.slug
			})
			if (
				!requirePermissions.every(rp => {
					return currentPermissions.includes(rp)
				})
			) {
				return next(
					currentUser.role === ROLE.BUYER
						? '/not-allowed'
						: `/${currentUser.role}/not-allowed`
				)
			}
		}

		if (!isContractSigned && to.path !== '/contract-sign') {
			return next('/contract-sign')
		}

		if (
			isContractSigned &&
			!currentUser.isAcceptTerms &&
			to.path !== '/user-agreement'
		) {
			return next('/user-agreement')
		}

		if (
			isContractSigned &&
			currentUser.isPending &&
			currentUser.isAcceptTerms &&
			to.path !== '/pending'
		) {
			return next('/pending')
		}
	}

	return next()

	// console.log('to', to);
	// let isAuth = store.getters['auth/$isAuth'];
	// let user = store.getters['auth/$currentUser']
	// let defaultRoute = store.getters['auth/$defaultRoute']
	// if (to.matched.some(record => record.meta.requiresAuth)) {
	// 	console.log(defaultRoute, 'defaultRoute');
	// 	if (!isAuth) {
	// 		return next({
	// 			path: '/login', query: { redirect: to.fullPath }
	// 		})
	// 	}
	// 	if (isAuth) {

	// 		const requiresUnAuth = getRouteMeta(to, 'requiresUnAuth')
	// 		console.log(requiresUnAuth, 'isAuth294');
	// 		if (isAuth && !+user.isAcceptTerms && to.path != '/user-agreement') {
	// 			return next({
	// 				path: '/user-agreement', query: { redirect: to.fullPath }
	// 			})
	// 		}
	// 		if (requiresUnAuth) {
	// 			console.log(requiresUnAuth, 'requiresUnAuth301');
	// 			return next(defaultRoute)
	// 		}
	// 		let requiresRole = getRouteMeta(to, 'requiresRole')
	// 		console.log(requiresRole);
	// 		if (requiresRole) {
	// 			console.log(requiresRole, 'requiresRole306');
	// 			console.log(defaultRoute, 'defaultRoute307');
	// 			requiresRole = isArray(requiresRole) ? requiresRole : [requiresRole]
	// 			if (requiresRole && !requiresRole.includes(user.role)) {
	// 				console.log('objectToArray310');
	// 				if (defaultRoute !== '/') {
	// 					console.log('!defaultRoute312');
	// 					return next(defaultRoute)
	// 				}
	// 				console.log(requiresRole, 'requiresRole');
	// 				console.log(defaultRoute, 'defaultRoute');
	// 				return next('/404' + to.path)
	// 			}
	// 		}
	// 	}

	// 	return next(defaultRoute)
	// }
	// else if (to.path === '/login' && isAuth) {
	// 	return next(defaultRoute)
	// }
	// console.log(defaultRoute, 'defaultRoute');
	// return next()
})

export default router
