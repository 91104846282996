<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20.71"
			height="20.71"
			viewBox="0 0 20.71 20.71"
		>
			<path
				id="offer-svgrepo-com"
				d="M8.475,17.235l8.76-8.76m5.475,4.38A9.855,9.855,0,1,1,12.855,3,9.855,9.855,0,0,1,22.71,12.855ZM11.76,9.57A2.19,2.19,0,1,1,9.57,7.38,2.19,2.19,0,0,1,11.76,9.57Zm6.57,6.57a2.19,2.19,0,1,1-2.19-2.19A2.19,2.19,0,0,1,18.33,16.14Z"
				transform="translate(-2.5 -2.5)"
				fill="none"
				stroke="#000"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="1"
			/>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconOffer',
}
</script>
