import { Cart } from '../../api'
import { MODELS } from '../../consts'
import { initialList } from '../store.helpers'
import { SET, UPDATE, DELETE, UNSHIFT } from 'vuelpers/lib/vuex'
import { handleAction, createGetters, createMutations } from 'vuelpers'
import { useCart } from '../../mixins'

const initialState = () => ({
	preview: { dialog: false },
	cart: {
		offers: initialList(),
		products: initialList(),
	},
})

const state = initialState()
const mutations = createMutations(SET, UNSHIFT, DELETE, UPDATE)
const getters = createGetters('cart', 'preview', {
	cartItemsCount(state) {
		return state.cart.offers.data.length + state.cart.products.data.length
	},
	summary({ cart: { products, offers } }) {
		return useCart(products?.data, offers?.data)
	},
})

const actions = {
	showCartPreview({ commit }) {
		commit('SET', { 'preview.dialog': true })
	},
	getCartList({ commit }, payload = {}) {
		const { perPage = 20, page = 1 } = payload
		return handleAction(Cart.getCartList({ perPage, page }), (res) =>
			commit(SET, {
				cart: {
					offers: res.offerCarts,
					products: res.productCarts,
				},
			})
		)
	},
	addToCart({ commit, dispatch }, payload) {
		const cartitem = getCartItem(state, payload)
		if (cartitem)
			return dispatch('updateCartItem', {
				...cartitem,
				quantity: cartitem.quantity + 1,
			})
		return handleAction(Cart.addItem(payload), (res) =>
			commit(UNSHIFT, [
				res.cart.cartableType === MODELS.PRODUCT
					? `cart.products.data`
					: `cart.offers.data`,
				res.cart,
			])
		)
	},
	deleteItem({ commit }, payload) {
		return handleAction(Cart.removeItem(payload.id), () =>
			commit(DELETE, [
				payload.cartableType === MODELS.PRODUCT
					? `cart.products.data`
					: `cart.offers.data`,
				payload.id,
			])
		)
	},
	updateCartItem({ commit }, payload) {
		return handleAction(
			Cart.updateItem({
				id: payload.id,
				quantity: payload.quantity,
			}),
			() =>
				commit(UPDATE, [
					payload.cartableType === MODELS.PRODUCT
						? `cart.products.data`
						: `cart.offers.data`,
					payload,
				])
		)
	},
}

export const getCartItem = (state, item) => {
	const productCart = state.cart.products.data.find(
		(cart) => cart.cartableId === item.cartableId
	)
	if (productCart) return productCart
	const offerCart = state.cart.offers.data.find(
		(cart) => cart.cartableId === item.cartableId
	)
	if (offerCart) return offerCart
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
