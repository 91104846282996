/* eslint-disable no-useless-escape */
import { parsePhoneNumber } from 'libphonenumber-js'
import { isEmpty } from 'vuelpers'
import { i18n } from '../plugins/vue-i18n'

const _regex = {
	name: /^[a-zA-Z\.\- ]{3,32}$/,
	email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,

	phone: /^(\+?[0-9\-]{10,15})$/,
	password: /^.{8,20}$/,
	character: /^[a-zA-Z]+$/,
}

const validators = {
	email: v => _regex.email.test(v) || i18n.t('invalidEmail'),
	name: v => _regex.name.test(v) || i18n.t('enterValidName'),
	password: v => !v || _regex.password.test(v) || i18n.t('invalidPassword'),
	phone: v => _regex.phone.test(v) || i18n.t('enterValidPhone'),
	phoneCountry: (cb, code = 'SA') => {
		return phone => {
			if (!phone) return true

			const errorMessage = 'Phone number not valid!!!!'
			try {
				const parsed = parsePhoneNumber(phone, code)
				// console.log(parsed.isValid(), parsed)
				if (parsed.isValid()) {
					cb && cb(parsed)
					return true
				}
				return errorMessage
			} catch (_) {
				console.log(_.message)
				return errorMessage
			}
		}
	},
	onlyCharacters: v => {
		return _regex.character.test(v) || i18n.t('cantHaveSpecialChar')
	},
	required: fieldName => v => {
		return (
			!isEmpty(v) ||
			`${fieldName || i18n.t('field')} ${i18n.t('isRequired')}.`
		)
	},
	match: (v1, v2, fieldName) => {
		return v1 === v2 || `${fieldName} ${i18n.t('doesntMatch')}`
	},
	cantMatch: (v1, v2, f1 = 'Field1', f2 = 'Field2') => {
		return v1 !== v2 || i18n.t('cantBeMatch', [f1, f2])
	},
	min: (len, fieldName) => v => {
		if (typeof v === 'string' && Number.isNaN(+v))
			return (
				!(v && v.length < len) ||
				`${fieldName || i18n.t('field')} ${i18n.t('cantLessChars', {
					len,
				})}`
			)
		else if (!Number.isNaN(+v))
			return (
				v >= len ||
				`${fieldName || i18n.t('field')} ${i18n.t('cantBeLessThan', {
					len,
				})}`
			)
		return true
	},
	max: (len, fieldName) => v => {
		if (typeof v === 'string' && Number.isNaN(+v))
			return (
				!(v && v.length > len) ||
				`${fieldName || i18n.t('field')} ${i18n.t('cantMoreChars', {
					len,
				})}`
			)
		else if (!Number.isNaN(+v))
			return (
				v <= len ||
				`${fieldName || i18n.t('field')} ${i18n.t('cantBeGreThan', {
					len,
				})}`
			)
		return true
	},
}

export const createFormMixin = ({ rules = [] }) => {
	const mRules = Object.keys(validators)
		.filter(key => rules.includes(key))
		.reduce((acc, key) => ({ ...acc, [key]: validators[key] }), {})
	return {
		name: 'FormMixin',
		data() {
			return {
				rules: mRules,
				fieldAttrs: {
					dense: true,
					outlined: true,
					required: true,
					hideDetails: 'auto',
				},
			}
		},
	}
}
