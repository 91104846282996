<template>
	<span
		:style="{ color }"
		class="tw-capitalize"
		@click="$emit('click', $event)"
	>
		{{ translatedText }}
	</span>
</template>

<script>
import { STATUS_COLOR } from '../../consts'
export default {
	name: 'BaseStatus',
	props: {
		translate: {
			type: Boolean,
			default: true,
		},
		label: {
			type: String,
		},
		status: {
			required: true,
			type: [String, Number],
		},
		fallbackColor: {
			type: String,
			default: '#722432',
		},
	},
	computed: {
		translatedText() {
			if (!this.translate) return this.statusText
			return this.$t(`status.${this.statusText}`)
		},
		statusText() {
			if (this.label) return this.label
			if (typeof this.status === 'string') return this.status
			return this.status ? 'active' : 'inactive'
		},
		color() {
			return this.colors[this.status] || this.fallbackColor
		},
		colors() {
			return STATUS_COLOR
		},
	},
}
</script>
