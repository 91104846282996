import api from '../../api'
import { initialList } from '../store.helpers'
import { createGetters, createMutations, handleAction } from 'vuelpers'
import { omit } from 'lodash'

const initialState = () => ({
	users: initialList(),
	buyers: initialList(),
	suppliers: initialList(),
})

const state = initialState()
const getters = createGetters('users', 'buyers', 'suppliers')
const mutations = createMutations('SET', 'DELETE', 'UPDATE', 'PUSH', 'UNSHIFT')

const actions = {
	fetchUsers({ commit, state: { users } }, payload) {
		commit('SET', {
			users: (v) => ({
				...v,
				isLoading: !users.isLoaded,
				isRefreshing: users.isLoaded,
			}),
		})
		return handleAction(api.users.get(payload), (res) => {
			commit('SET', {
				users: {
					...res.users,
					isLoaded: true,
					isLoading: false,
					isRefreshing: false,
				},
			})
		})
	},
	createUser({ commit }, payload) {
		return handleAction(api.users.post(payload), (res) => {
			commit('UNSHIFT', ['users.data', res.user])
		})
	},
	updateUser({ commit }, payload) {
		return handleAction(api.users.update(payload), (res) => {
			commit('UPDATE', ['users.data', res.user])
		})
	},
	deleteUser({ commit }, payload) {
		return handleAction(api.users.delete(payload), () => {
			commit('DELETE', ['users.data', payload])
		})
	},
	changeStatusOfUser({ commit }, payload = {}) {
		commit('SET', { 'users.isRefreshing': true })
		return handleAction(api.users.changeStatus(payload), (res) => {
			commit('UPDATE', ['users.data', res.user])
			commit('SET', { 'users.isRefreshing': false })
		})
	},
	getBuyers({ commit }, payload = {}) {
		commit('SET', {
			buyers: (v) => ({
				...v,
				isLoading: !v.isLoaded,
				isRefetching: v.isLoaded,
			}),
		})
		return handleAction(api.users.getBuyers(payload), (res) => {
			commit('SET', {
				buyers: (v) => {
					return {
						...v,
						...omit(res.buyers, 'data'),
						data:
							payload.page === 1
								? res.buyers.data
								: [...v.data, ...res.buyers.data],
						isLoaded: true,
						isLoading: false,
						isRefetching: false,
					}
				},
			})
		})
	},
	getSuppliers({ commit }, payload = {}) {
		commit('SET', {
			suppliers: (v) => ({
				...v,
				isLoading: !v.isLoaded,
				isRefetching: v.isLoaded,
			}),
		})
		return handleAction(api.users.getSuppliers(payload), (res) => {
			commit('SET', {
				suppliers: (v) => {
					return {
						...v,
						...omit(res.suppliers, 'data'),
						data:
							payload.page === 1
								? res.suppliers.data
								: [...v.data, ...res.suppliers.data],
						isLoaded: true,
						isLoading: false,
						isRefetching: false,
					}
				},
			})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}
