<template>
	<label
		:for="id"
		class="base-checkbox tw-font-pop tw-font-normal tw-text-base xl:tw-text-lg tw-text-white"
	>
		<span>{{ label }}</span>
		<input v-model="checked" :id="id" type="checkbox" :checked="checked" />
		<span class="checkmark"></span>
	</label>
</template>

<script>
export default {
	name: 'BaseCheckbox',
	props: {
		label: {
			type: String,
		},
		value: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			id: Math.random()
				.toString(36)
				.substring(2, 9),
		}
	},
	computed: {
		checked: {
			get() {
				return this.value
			},
			set(v) {
				this.$emit('input', v)
			},
		},
	},
}
</script>
<style scoped lang="scss">
/* The base-checkbox */
.base-checkbox {
	display: block;
	position: relative;
	padding-left: 44px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.base-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	width: 27.24px;
	height: 27.24px;
	border-radius: 5px;
	transition: all 300ms ease-in-out;
	background-color: rgba(249, 249, 249, 0.3);
}

/* On mouse-over, add a grey background color */
.base-checkbox:hover input ~ .checkmark {
	background-color: rgba(249, 249, 249, 0.6);
}

/* When the checkbox is checked, add a blue background */
.base-checkbox input:checked ~ .checkmark {
	background-color: white;
	// background-color: #f0c95e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
	content: '';
	display: none;
	position: absolute;
}

/* Show the checkmark when checked */
.base-checkbox input:checked ~ .checkmark::after {
	display: block;
}

/* Style the checkmark/indicator */
.base-checkbox .checkmark::after {
	left: 9px;
	top: 4px;
	width: 8.5px;
	height: 14px;
	border: solid #722432;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
</style>
