import api from './base.api'

const Invoices = {
	get: (query) => api.get('/invoices', query),
	getById: (id, query) => api.get(`/invoices/${id}`, query),
	post: (body) => api.post('/invoices', body),
	patch: (body) => api.post('/invoices', body),
	delete: (id) => api.delete('/invoices/' + id),
}

export { Invoices }
