import API from '../../api'
import router from '../../router'

import { get } from 'lodash'
import { cookies, diff } from '../../helpers'
import { DEFAULT_ROUTE, ROLE } from '../../consts'
import { createGetters, createMutations, handleAction } from 'vuelpers'

const initalState = () => ({
	isAuth: false,
	accessToken: null,
	user: null,
	currentUser: {
		id: null,
	},
	globalLoading: false,
})

const state = initalState()
const mutations = createMutations('SET', 'RESET')

const getters = {
	...createGetters('users', 'currentUser', 'tokenExpiresAt', 'accessToken', {
		currentUser({ currentUser }) {
			const role = currentUser.role

			const isAdmin = role === ROLE.ADMIN
			const isBuyer = role === ROLE.BUYER
			const isSupplier = role === ROLE.SUPPLIER

			const buySup =
				get(currentUser, 'buyer.0') || get(currentUser, 'supplier.0')
			const buyerSubscription = get(currentUser, 'buyer.0.buyerSubscription')
			const isOwner = isAdmin || buySup?.pivot?.isOwner

			return {
				...(currentUser || {}),
				buySup,
				isOwner,
				// subscription
				buyerSubscription,
				isSubscribed: !!buyerSubscription,
				// role
				isBuyer,
				isAdmin,
				isSupplier,

				isPending: !isAdmin && buySup?.status === 'pending',

				// temp
				// attachment: {
				// 	attachedLink:
				// 		'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
				// },
			}
		},
	}),
	$isAuth: ({ isAuth }) => isAuth,
	$isLoggedIn(state) {
		return !!(state.currentUser.id && state.accessToken)
	},

	$defaultRoute(state) {
		return (
			state.currentUser &&
			state.currentUser.role &&
			DEFAULT_ROUTE[state.currentUser.role]
		)
	},
	isContractSigned: state => {
		if (state.currentUser.role === ROLE.ADMIN) return true
		const roleData = state.currentUser[state.currentUser.role]
		if (!roleData) return false
		return roleData[0].contracts.length > 0
	},
}

const actions = {
	login: async ({ commit }, payload) => {
		return handleAction(API.auth.login(payload), res => {
			const expires = payload.remember_me ? diff.day(res.expiresAt) : null
			cookies.set(
				{ key: 'isAuth', value: true, expires },
				{ key: 'accessToken', value: res.token, expires },
				{ key: 'userId', value: res.user.id, expires },
				{ key: 'isAcceptTerms', value: res.user.isAcceptTerms, expires }
			)
			API.setHeaders({
				Authorization: `Bearer ${res.token}`,
			})
			commit('SET', {
				isAuth: true,
				accessToken: res.token,
				currentUser: res.user,
			})
		})
	},
	logout: async ({ commit }) => {
		await API.auth.logout()
		commit('RESET', initalState())
		cookies.remove('isAuth', 'accessToken', 'userId')
		return { error: false }
	},
	forgotPassword: async (_, payload) => {
		return handleAction(API.auth.forgotPass(payload))
		// console.log(payload);
		// let res = await API.auth.forgotPass(payload);
		// return res
	},
	verifyToken(payload) {
		return handleAction(API.auth.verify(payload))
	},
	resetPassword(payload) {
		return handleAction(API.auth.resetPass(payload))
	},

	sendOtp(payload) {
		return handleAction(API.auth.sendOtp(payload))
	},
	verifyOtp(payload) {
		return handleAction(API.auth.checkOtp(payload))
	},
	buyerRegistration(_, payload) {
		return handleAction(API.auth.buyerReg(payload), res => {
			console.log('buyer registration', res)
		})
	},
	supplierRegistration(_, payload) {
		return handleAction(API.auth.supplierReg(payload), res => {
			console.log('supplierRegistration', res)
		})
	},
	acceptTermsAndcondition({ commit }) {
		return handleAction(API.auth.acceptTerms(), res => {
			console.log(res)
			commit('SET', {
				'currentUser.isAcceptTerms': 1,
			})
		})
	},
	verifyPhone(_, payload) {
		return handleAction(API.auth.verifyPhone(payload))
	},
	verifyEmail(_, payload) {
		return handleAction(API.auth.verifyEmail(payload))
	},
	resetbyPhone(_, payload) {
		return handleAction(API.auth.resetPassPhone(payload))
	},
	resetbyEmail(_, payload) {
		return handleAction(API.auth.resetPassEmail(payload))
	},
	fetchProfile: async ({ commit, dispatch }) => {
		return handleAction(
			API.auth.fetchProfile(),
			res => {
				commit('SET', {
					isAuth: true,
					user: res.user,
					currentUser: res.user,
				})
			},
			async err => {
				if (err.statusCode === 401) {
					await dispatch('logout')
					router.replace('/login')
				}
			}
		)
	},
	updateCurrentUser(_, payload) {
		return handleAction(
			API.auth.updateCurrentUser(payload),
			res => {
				console.log({ updateCurrentUser: res })
			},
			err => {
				console.log({ updateCurrentUserError: err })
			}
		)
	},
	contactUs: async (_, payload) => {
		return handleAction(API.auth.contactUs(payload))
	},
	updatePassword(_, payload) {
		return handleAction(API.auth.resetPass(payload))
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}
