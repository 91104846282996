<template>
	<v-menu :nudge-bottom="height">
		<template #activator="{ on, attrs }">
			<div
				v-on="on"
				v-bind="attrs"
				:style="{ height: `${height}px` }"
				class="tw-cursor-pointer tw-rounded tw-flex tw-pl-3 tw-pr-2 tw-border-gray-300 tw-items-center tw-border"
			>
				<BaseStatus :status="value" :translate="translate" />
				<v-icon class="tw-ml-auto tw-text-gray-300"
					>mdi-chevron-down</v-icon
				>
			</div>
		</template>
		<v-list dense>
			<v-list-item
				v-for="status in statuses"
				:key="status.value"
				@click="onChangeStatus(status.value)"
			>
				<BaseStatus :status="status.value" :translate="translate" />
			</v-list-item>
		</v-list>
	</v-menu>
</template>
<script>
export default {
	name: 'BaseStatusChanger',
	props: {
		value: {
			type: String,
			required: true,
		},
		items: {
			type: Array,
			default: () => [],
		},
		height: {
			default: 40,
			type: [Number, String],
		},
		onChange: {
			type: Function,
			required: true,
		},
		translate: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		statuses() {
			return this.items.filter((status) => {
				return status.value !== this.value
			})
		},
	},
	methods: {
		onChangeStatus(status) {
			this.$root.confirmation = {
				title: `Status Change Confirmation`,
				message: `Are you sure you want to change the status to '${this._.capitalize(
					status.split('-').join(' ')
				)}'?`,
				handler: async () => {
					const [err, res] = await this.onChange(status)
					if (err) {
						return this.$toast.error(
							'Error while updating the status. Please try again.'
						)
					}
					this.$emit('input', status)
					this.$emit('change', res)
					return this.$toast.success('Status Updated Successfully')
				},
			}
		},
	},
}
</script>
