import { render, staticRenderFns } from "./RouterWrapper.vue?vue&type=template&id=3781a874&"
import script from "./RouterWrapper.vue?vue&type=script&lang=js&"
export * from "./RouterWrapper.vue?vue&type=script&lang=js&"
import style0 from "./RouterWrapper.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports