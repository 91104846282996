<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="21.62"
		height="17.349"
		viewBox="0 0 21.62 17.349"
	>
		<g id="map-location-svgrepo-com" transform="translate(0 -23.466)">
			<path
				id="Path_34283"
				data-name="Path 34283"
				d="M21.328,23.589a.683.683,0,0,0-.626-.081l-3.532,1.3a4.139,4.139,0,0,0-6.065-.035L7.669,23.508a.683.683,0,0,0-.471,0L.447,25.989A.683.683,0,0,0,0,26.629v13.5a.683.683,0,0,0,.918.641l6.516-2.394,6.516,2.394a.683.683,0,0,0,.471,0l6.752-2.481a.682.682,0,0,0,.447-.641v-13.5A.683.683,0,0,0,21.328,23.589Zm-7.207,1.242A2.787,2.787,0,0,1,16.9,27.615c0,2.4-1.947,4.213-2.783,4.881-.837-.667-2.783-2.478-2.783-4.881A2.787,2.787,0,0,1,14.121,24.831Zm6.134,12.345-6.07,2.23L7.669,37.011a.683.683,0,0,0-.471,0L1.365,39.155V27.106l6.069-2.23,2.894,1.063a4.122,4.122,0,0,0-.355,1.676c0,3.266,2.688,5.508,3.53,6.13v3.434a.683.683,0,1,0,1.365,0V33.647c.958-.736,3.4-2.918,3.4-6.032a4.126,4.126,0,0,0-.336-1.635l2.322-.853V37.176Z"
				transform="translate(0)"
				fill="#fff"
			/>
			<path
				id="Path_34284"
				data-name="Path 34284"
				d="M74.869,55.924a.683.683,0,0,0-.683.683v7.6a.683.683,0,0,0,1.365,0v-7.6A.683.683,0,0,0,74.869,55.924Z"
				transform="translate(-67.435 -29.504)"
				fill="#fff"
			/>
		</g>
	</svg>
</template>
<script>
export default {
	name: 'IconMapMarker',
}
</script>
