<template>
	<span class="tw-flex tw-items-center tw-justify-center" v-bind="attrs">
		<svg
			id="_353403_cart_icon"
			data-name="353403_cart_icon"
			xmlns="http://www.w3.org/2000/svg"
			width="26.265"
			height="23.599"
			viewBox="0 0 26.265 23.599"
		>
			<path
				id="Path_34269"
				data-name="Path 34269"
				d="M41.439,17.837H26.123a.626.626,0,0,0-.635.862l2.423,7.1a1.049,1.049,0,0,0,.943.693H38.9a1.047,1.047,0,0,0,.942-.693l2.112-7.265A.5.5,0,0,0,41.439,17.837Z"
				transform="translate(-15.716 -13.387)"
				fill="#fff"
			/>
			<circle
				id="Ellipse_1195"
				data-name="Ellipse 1195"
				cx="2.291"
				cy="2.291"
				r="2.291"
				transform="translate(9.501 18.988)"
				fill="#fff"
			/>
			<circle
				id="Ellipse_1196"
				data-name="Ellipse 1196"
				cx="2.291"
				cy="2.291"
				r="2.291"
				transform="translate(19.196 19.017)"
				fill="#fff"
			/>
			<path
				id="Path_34270"
				data-name="Path 34270"
				d="M30.93,24.7h-13.4L12.167,9.261H7.99a1.3,1.3,0,1,0,0,2.595h2.332L15.686,27.29H30.93a1.3,1.3,0,1,0,0-2.595Z"
				transform="translate(-6.693 -9.261)"
				fill="#fff"
			/>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconCartWhite',
	props: {
		size: {
			default: '24',
			type: [String, Number],
		},
	},
	computed: {
		attrs() {
			return {
				style: {
					width: `${this.size}px`,
					height: `${this.size}px`,
				},
			}
		},
	},
}
</script>
