import { branch } from '../../api'
import { initialList } from '../store.helpers'
import { createGetters, createMutations, handleAction } from 'vuelpers'

const initialState = () => {
	return {
		branches: initialList(),
	}
}

const state = initialState()

const getters = {
	...createGetters('branches'),
}

const mutations = {
	...createMutations('SET', 'PUSH', 'UNSHIFT', 'DELETE', 'UPDATE', 'MERGE'),
}

const actions = {
	createBranch({ commit }, payload) {
		return handleAction(branch.post(payload), (res) => {
			commit('PUSH', { 'branches.data': res.branch })
		})
	},
	deleteBranch({ commit }, payload = {}) {
		return handleAction(branch.delete(payload), () => {
			commit('DELETE', ['branches.data', payload])
		})
	},
	updateBranch({ commit }, payload = {}) {
		return handleAction(branch.patch(payload), (res) => {
			commit('UPDATE', ['branches.data', res.branch])
		})
	},
	getBranch({ commit }, payload = {}) {
		return handleAction(branch.getById(payload.id, payload.query), (res) => {
			commit('UNSHIFT', ['branches.data', res.branch])
		})
	},
	getBranches({ commit }, payload = {}) {
		commit('SET', { 'branches.isRefreshing': true })
		return handleAction(branch.get(payload), (res) => {
			commit('SET', {
				branches: {
					...res.branches,
					isLoaded: true,
					isRefreshing: false,
				},
			})
		})
	},
	resetState({ commit }) {
		commit('SET', initialState())
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
