<template>
	<span
		class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-text-black tw-opacity-75"
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18.424"
			height="20.548"
			viewBox="0 0 18.424 20.548"
		>
			<path
				id="bank-svgrepo-com"
				d="M42.961,8.889V4.88L33.849,0,24.737,4.88v4.01h.887v7.179h-.887v4.266H42.961V16.068h-.887V8.889h.887Zm-11,7.179V8.889h3.777v7.179ZM26.017,7.609V5.9H41.681V7.609ZM39.77,4.623H27.927l5.921-3.171ZM26.9,8.889h3.777v7.179H26.9ZM41.681,19.055H26.017V17.348H41.681v1.707Zm-.887-2.986H37.017V8.889h3.777Z"
				transform="translate(-24.637 0.113)"
				fill="currentColor"
				stroke="currentColor"
				stroke-width="0.2"
			/>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconBank',
}
</script>
