import api from '../../api'
import {
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'

// interface State {
// 	permissions
// }

const initialState = () => {
	return {
		permissions: {},
	}
}

const state = initialState()

const getters = {
	...createGetters('permissions'),
}

const mutations = {
	...createMutations('SET', 'MERGE'),
}

const actions = {
	getPermissions({ commit }, payload = {}) {
		const { perPage = 20, page = 1 } = payload
		return handleAction(api.permissions.get({ perPage, page }), (res) => {
			commit('SET', { permissions: res.permissions })
		})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
