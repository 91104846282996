<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21.069"
			height="18.23"
			viewBox="0 0 21.069 18.23"
		>
			<g id="print-svgrepo-com" transform="translate(-796 -689.055)">
				<path
					id="Path_34291"
					data-name="Path 34291"
					d="M815.455,693.651h-1.13v-3.585a1.013,1.013,0,0,0-1.012-1.012H799.756a1.013,1.013,0,0,0-1.012,1.012v3.585h-1.13A1.615,1.615,0,0,0,796,695.266v5.807a1.615,1.615,0,0,0,1.615,1.615h1.13v3.585a1.013,1.013,0,0,0,1.012,1.012h13.557a1.013,1.013,0,0,0,1.012-1.012v-3.585h1.13a1.615,1.615,0,0,0,1.615-1.615v-5.807A1.615,1.615,0,0,0,815.455,693.651Zm-15.441-3.327h13.043v3.327H800.013Zm0,15.691v-7.607h13.043v7.607Z"
					transform="translate(0)"
					fill="#722432"
				/>
				<path
					id="Path_34292"
					data-name="Path 34292"
					d="M858.692,793.89h-8.52a.635.635,0,1,0,0,1.269h8.52a.635.635,0,0,0,0-1.269Z"
					transform="translate(-47.897 -93.791)"
					fill="#722432"
				/>
				<path
					id="Path_34293"
					data-name="Path 34293"
					d="M858.692,819.869h-8.52a.635.635,0,1,0,0,1.269h8.52a.635.635,0,0,0,0-1.269Z"
					transform="translate(-47.897 -117.033)"
					fill="#722432"
				/>
			</g>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconPrint',
}
</script>
