<template>
	<v-list-group
		v-if="hasChildrens"
		:value="false"
		:prepend-icon="icon || iconComponent"
	>
		<template #prependIcon>
			<component v-if="iconComponent" :is="iconComponent"></component>
			<v-icon v-else-if="icon">{{ icon }}</v-icon>
		</template>
		<template v-slot:activator>
			<v-list-item-title class>{{ name }}</v-list-item-title>
		</template>
		<slot></slot>
	</v-list-group>
	<v-list-item
		link
		v-else
		:to="to"
		:exact="exact"
		:active="$route.path === to"
		class="my-1 tw-h-60p text--white tw-relative"
		@click="$emit('click')"
	>
		<v-list-item-icon v-if="icon || iconComponent" class="tw-self-center">
			<span
				:class="[
					$vuetify.rtl ? 'tw-right-0' : 'tw-left-0',
					$route.path === to ? 'tw-block' : 'tw-hidden',
				]"
				class="tw-bg-72 tw-h-full tw-rounded-full tw-top-0 tw-w-2 tw-absolute tw--ms-1.5"
			></span>

			<component
				:is="iconComponent || 'v-icon'"
				:class="{
					'tw-opacity-75': icon,
					'tw-ms-8 lg:tw-ms-14': $drawer,
				}"
				color="black"
				class="tw-text-black tw-fill-current tw-flex-none"
			>
				{{ icon }}
			</component>
		</v-list-item-icon>
		<v-list-item-title>
			<span
				class="tw-text-black tw-opacity-60"
				:class="$drawer ? 'tw-ms-2 lg:tw-ms-4' : ''"
				>{{ name }}</span
			>
		</v-list-item-title>
	</v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'
import { VIcon } from 'vuetify/lib/components'

export default {
	name: 'SidebarNavListItem',
	components: {
		VIcon,
	},
	props: [
		'icon',
		'name',
		'to',
		'active',
		'exact',
		'hasChildrens',
		'iconComponent',
	],
	computed: {
		...mapGetters(['$drawer', '$loading', '$theme']),
	},
}
</script>

<style>
.border-left {
	border-left: 7px solid;
	border-radius: 4px !important;
}
</style>
