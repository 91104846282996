<template>
	<v-dialog v-model="modal" max-width="500" @close="onCloseModal">
		<v-form ref="formRef" v-model="isValid" @submit.prevent="onSubmitForm">
			<v-card>
				<v-card-title>
					<template v-if="category && category.isMain">
						{{
							category.id ? $t('updateCategory') : $t('createCategory')
						}}
					</template>
					<template v-else>
						{{
							category.id
								? $t('updateSubCategory')
								: $t('createSubCategory')
						}}</template
					>
				</v-card-title>
				<v-card-subtitle>{{ $t('hint.fillAndCreate') }}</v-card-subtitle>

				<v-card-text>
					<div class="tw-relative tw-w-max tw-group tw-mx-auto tw-mb-4">
						<v-img
							:src="$image(category.image.src, '164x164')"
							width="164"
							height="164"
							ref="profileImageRef"
							class="tw-rounded-lg tw-border-2 tw-border-solid tw-border-gray-400"
							@error="$onImgError({ ref: $refs.profileImageRef })"
						></v-img>
						<v-btn
							small
							icon
							class="tw-absolute tw-bottom-1 tw-right-1 tw-bg-white"
							@click="onClickUploadImage"
						>
							<v-icon small> mdi-camera</v-icon>
						</v-btn>
					</div>

					<v-autocomplete
						v-model="category.type"
						:label="$t('type')"
						:disabled="!$currentUser.isAdmin"
						:placeholder="$t('selectType')"
						:rules="[rules.required($t('type'))]"
						:items="types"
						outlined
						class="tw-mb-4"
						hide-details="auto"
					/>

					<v-autocomplete
						v-if="!category.isMain"
						v-model="category.mainCategoryId"
						:disabled="!$currentUser.isAdmin"
						:label="$t('parentCategory')"
						:placeholder="$t('selectParentCategory')"
						:rules="[rules.required($t('parentCategory'))]"
						:items="$categories.data"
						item-text="name"
						item-value="id"
						outlined
						class="tw-mb-4"
						hide-details="auto"
					/>

					<v-text-field
						v-model="category.name"
						outlined
						hide-details="auto"
						:rules="[
							rules.required($t('name')),
							rules.min(3, $t('name')),
						]"
						:label="
							category.isMain ? $t('categoryName') : $t('subCategory')
						"
						:placeholder="
							category.isMain
								? $t('writeCategoryName')
								: $t('writeSubCategoryName')
						"
					/>
				</v-card-text>

				<v-card-actions class="px-4 pb-4">
					<v-spacer></v-spacer>
					<v-btn
						text
						large
						outlined
						type="button"
						color="error"
						class="px-6 tw-normal-case tw-rounded-lg"
						@click="onCloseModal"
						><span class="tw-capitalize tw-text-72">
							{{ $t('actions.cancel') }}
						</span>
					</v-btn>
					<v-btn
						:loading="isLoading"
						large
						depressed
						type="submit"
						color="primary"
						class="px-6 tw-normal-case tw-rounded-lg"
						@click="onSubmitForm"
					>
						<span class="tw-capitalize tw-text-white">
							{{ $t('actions.create') }}
						</span>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import { omitEmpties, openFilePicker, toFormData } from 'vuelpers'
import { mapGetters, mapActions } from 'vuex'
import { createFormMixin } from '../../mixins/form-mixin'

const newCategory = (v = {}) => {
	return {
		id: v?.id,
		type: v?.type || '',
		name: v?.name || '',
		isMain: v?.isMain ?? 1,
		mainCategoryId: v?.mainCategoryId,
		image: {
			file: null,
			src: v?.image,
		},
	}
}

export default {
	name: 'DialogCategory',
	mixins: [
		createFormMixin({
			rules: ['required', 'min'],
		}),
	],
	data: () => ({
		isValid: false,
		isLoading: false,
		category: newCategory(),
	}),
	computed: {
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('category', ['$create', '$categories']),
		modal: {
			get() {
				return this.$create.modal
			},
			set(v) {
				this.setCategoryState({
					'create.modal': v,
				})
			},
		},
		types() {
			return [
				{
					text: this.$t('product'),
					value: 'product',
				},
				{
					text: this.$t('buyerText'),
					value: 'buyer',
				},
				{
					text: this.$t('complaint'),
					value: 'complaint',
				},
				{
					text: this.$t('headers.offer'),
					value: 'offer',
				},
				{
					text: this.$t('reason'),
					value: 'reason',
				},
				{
					text: this.$t('supplierText'),
					value: 'supplier',
				},
				{
					text: this.$t('marketingOffer'),
					value: 'marketing-offer',
				},
				{
					text: this.$t('headers.color'),
					value: 'color',
				},
				{
					text: this.$t('size'),
					value: 'size',
				},
			]
		},
	},
	watch: {
		'$create.data': {
			deep: true,
			immediate: true,
			handler(data) {
				if (!data) return
				console.log(data)
				this.category = newCategory(data)
			},
		},
	},
	methods: {
		...mapActions('category', [
			'setCategoryState',
			'createCategory',
			'updateCategory',
		]),
		onCloseModal() {
			this.$refs?.formRef?.reset()
			this.category = newCategory()
			this.modal = false
		},
		onClickUploadImage() {
			openFilePicker({}, async (image) => {
				this.category.image = image
			})
		},
		async onSubmitForm() {
			if (this.isLoading) return

			if (!this.$refs?.formRef?.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			this.isLoading = true
			const submitFunc = this.category.id
				? this.updateCategory
				: this.createCategory

			const [err] = await submitFunc(
				toFormData(
					omitEmpties({
						...this.category,
						image: this.category.image.file,
					}),
					{
						convertCase: 'snake_case',
					}
				)
			)
			this.isLoading = false

			if (err) {
				this.$utils.scrollIntoError(this)
				return
			}

			this.onCloseModal()
		},
	},
}
</script>
