<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14.48"
			height="11.109"
			viewBox="0 0 14.48 11.109"
		>
			<g id="Group_39008" data-name="Group 39008" transform="translate(0 0)">
				<path
					id="Path_31230"
					data-name="Path 31230"
					d="M92.715,204.364a1.288,1.288,0,0,1,0,1.788l-1,1a1.288,1.288,0,0,1-1.789,0l-4.368-4.4a1.288,1.288,0,0,1,0-1.789l1-1a1.288,1.288,0,0,1,1.788,0Z"
					transform="translate(-85.2 -196.401)"
					fill="#fff"
				/>
				<path
					id="Path_31231"
					data-name="Path 31231"
					d="M172.328,125.561a1.288,1.288,0,0,1,1.788,0l1,1a1.288,1.288,0,0,1,0,1.788l-7.567,7.532a1.288,1.288,0,0,1-1.788,0l-1-1a1.288,1.288,0,0,1,0-1.788Z"
					transform="translate(-160.995 -125.2)"
					fill="#fff"
				/>
			</g>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconCheck',
}
</script>
