import API from '../../api'
import { initialList } from '../store.helpers'
import { createGetters, createMutations, handleAction, SET } from 'vuelpers'

const initialState = () => {
	return {
		create: {
			data: null,
			modal: false,
			isLoading: false,
		},
		categories: initialList(),
		complaintCategories: initialList(),
		complaints: initialList(),
		productCategories: initialList(),
		productCategoriesForMarketing: initialList(),
		unAuthcategories: initialList(),
		reasonCategories: initialList(),
	}
}

const state = initialState()

const getters = {
	...createGetters(
		'categories',
		'complaints',
		'create',
		'productCategoriesForMarketing',
		'unAuthcategories',
		'complaintCategories',
		'reasonCategories',
		{
			mainCategories(state) {
				return {
					...state.categories,
					data: state.categories.data.filter(
						(category) => category.isMain
					),
				}
			},
		}
	),
	$productCategories(state) {
		return {
			...state.categories,
			data: state.categories.data.filter((category) => {
				return category.type === 'product'
			}),
		}
	},
	$offerCategories(state) {
		return {
			...state.categories,
			data: state.categories.data.filter((category) => {
				return category.type === 'offer'
			}),
		}
	},
	$marketingOfferCategories(state) {
		return {
			...state.categories,
			data: state.categories.data.filter((category) => {
				return category.type === 'marketing-offer'
			}),
		}
	},
	$rejectCategories(state) {
		return {
			...state.categories,
			data: state.categories.data.filter((category) => {
				return category.type === 'reason'
			}),
		}
	},
	$buyerCategories(state) {
		return {
			...state.categories,
			data: state.categories.data.filter((category) => {
				return category.type === 'buyer'
			}),
		}
	},
	$supplierCategories(state) {
		return {
			...state.categories,
			data: state.categories.data.filter((category) => {
				return category.type === 'supplier'
			}),
		}
	},
	$unAuthBuyerCategories(state) {
		return {
			...state.unAuthcategories,
			data: state.unAuthcategories.data.filter((category) => {
				return category.type === 'buyer'
			}),
		}
	},
	$unAuthSupplierCategories(state) {
		return {
			...state.unAuthcategories,
			data: state.unAuthcategories.data.filter((category) => {
				return category.type === 'supplier'
			}),
		}
	},
}

const mutations = {
	...createMutations('SET', 'PUSH', 'UNSHIFT', 'DELETE', 'UPDATE', 'MERGE'),
}

const actions = {
	setCategoryState({ commit }, payload) {
		commit('SET', payload)
	},
	createCategory({ commit }, payload) {
		return handleAction(API.categories.post(payload), (res) => {
			if (!res.category.isMain) {
				commit('SET', {
					'categories.data': (categories) => {
						return categories.map((category) => {
							if (category.id != res.category.mainCategoryId) {
								return category
							}
							if (!category.childCategories) {
								category.childCategories = []
							}
							category.childCategories.unshift(res.category)
							return category
						})
					},
				})
			}
			commit('UNSHIFT', {
				'categories.data': {
					...res.category,
					childCategories: res.category.childCategories || [],
				},
			})
		})
	},
	updateCategory({ dispatch, state }, payload) {
		return handleAction(
			API.categories.patch(payload.get('id'), payload),
			() => {
				dispatch('getCategories', {
					perPage: state.categories.perPage,
					page: state.categories.currentPage,
				})
			}
		)
	},
	deleteCategoryById({ commit }, payload) {
		return handleAction(API.categories.delete(payload.id), () => {
			if (payload.isMain) {
				return commit('DELETE', ['categories.data', payload.id])
			}
			return commit('SET', {
				'categories.data': (categories) => {
					return categories.map((category) => {
						if (category.id != payload.mainCategoryId) {
							return category
						}
						category.childCategories = category.childCategories.filter(
							(subCategory) => {
								return subCategory.id != payload.id
							}
						)
						return category
					})
				},
			})
		})
	},
	getComplaints({ commit }, payload = {}) {
		payload.type = 'complaint'
		return handleAction(API.categories.get(payload), (res) => {
			commit('SET', { categories: res.data })
		})
	},
	getCategories({ commit }, payload = {}) {
		commit(SET, { 'categories.isRefreshing': true })
		return handleAction(API.categories.get(payload), (res) => {
			commit('SET', {
				categories: (v) => ({
					...v,
					...res.data,
					isLoaded: true,
					isRefreshing: false,
				}),
			})
		})
	},
	getReasonCategories({ commit }, payload = {}) {
		payload.type = 'reason'
		payload.canPaginate = 0
		return handleAction(API.categories.get(payload), (res) => {
			commit('SET', { 'reasonCategories.data': res.data })
		})
	},
	getComplaintCategories({ commit }, payload = {}) {
		return handleAction(
			API.categories.get({
				...payload,
				type: 'complaint',
			}),
			(res) => {
				const statePath = Array.isArray(res.data)
					? 'complaintCategories.data'
					: 'complaintCategories'
				commit('SET', { [statePath]: res.data })
			}
		)
	},
	getUnAuthorisedCategories({ commit }, payload = {}) {
		return handleAction(API.categories.unAuthorised(payload), (res) => {
			commit('SET', { 'unAuthcategories': res.data })
		})
	},
	getCategoriesForProduct({ commit }, payload = {}) {
		commit(SET, { 'productCategoriesForMarketing.isRefreshing': true })
		return handleAction(API.categories.get(payload), (res) => {
			commit('SET', {
				productCategoriesForMarketing: (v) => ({
					...v,
					...res.data,
					isLoaded: true,
					isRefreshing: false,
				}),
			})
		})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
