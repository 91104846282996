import { AdSpaces, category } from '../../api'
import { initialList } from '../store.helpers'
import { createGetters, createMutations, handleAction } from 'vuelpers'

const initialState = () => ({
	adSpaces: initialList(),
	categories: initialList(),
})

const state = initialState()

const getters = {
	...createGetters('adSpaces', 'categories'),
}

const mutations = {
	...createMutations('SET', 'PUSH', 'UNSHIFT', 'DELETE', 'UPDATE', 'MERGE'),
}

const actions = {
	setAdSpaceState({ commit }, payload) {
		commit('SET', payload)
	},
	createAdSpace({ commit }, payload = {}) {
		return handleAction(AdSpaces.post(payload), (res) => {
			commit('UNSHIFT', ['adSpaces.data', res.adSpace])
		})
	},
	deleteAdSpace({ commit }, payload = {}) {
		return handleAction(AdSpaces.delete(payload), () => {
			commit('DELETE', ['adSpaces.data', payload])
		})
	},
	updateAdSpace({ commit }, payload = {}) {
		return handleAction(AdSpaces.patch(payload), (res) => {
			commit('UPDATE', ['adSpaces.data', res.adSpace])
		})
	},
	changeStatus({ commit }, payload = {}) {
		return handleAction(AdSpaces.changeStatus(payload), (res) => {
			commit('UPDATE', ['adSpaces.data', res.adSpace])
		})
	},
	getAdSpace({ commit }, payload = {}) {
		return handleAction(
			AdSpaces.getById(payload.id, payload.query),
			(res) => {
				commit('UNSHIFT', ['adSpaces.data', res.adSpace])
			}
		)
	},
	getAdSpaces({ commit }, payload = {}) {
		commit('SET', { 'adSpaces.isRefreshing': true })
		return handleAction(AdSpaces.get(payload), (res) => {
			commit('SET', {
				adSpaces: (v) => ({
					...v,
					...res.adSpaces,
					isLoaded: true,
					isRefreshing: false,
				}),
			})
		})
	},
	getCategoriesForAdSpace({ commit }) {
		return handleAction(category.get({ type: 'ad-space' }), (res) => {
			commit('SET', { categories: res.data })
		})
	},
	resetState({ commit }) {
		commit('SET', initialState())
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
