<template>
	<span
		class="tw-flex tw-items-center tw-justify-center"
		:style="{
			width: `${size}px`,
			height: `${size}px`,
		}"
	>
		<svg
			v-bind="attrs"
			xmlns="http://www.w3.org/2000/svg"
			class="tw-fill-current tw-stroke-current"
		>
			<g
				v-if="outlined"
				id="favourites-filled-star-symbol"
				transform="translate(-3.479 -1.397)"
			>
				<path
					:stroke-width="stroke"
					id="Path_9376"
					fill="none"
					data-name="Path 9376"
					d="M18.459,9.413l-5.315-.5a.919.919,0,0,1-.761-.563l-1.9-4.614a.911.911,0,0,0-1.691,0L6.9,8.347a.9.9,0,0,1-.761.563l-5.315.5a.919.919,0,0,0-.518,1.6L4.312,14.53a.911.911,0,0,1,.289.9L3.4,20.362a.919.919,0,0,0,1.355,1.005l4.432-2.6a.921.921,0,0,1,.929,0l4.432,2.6A.916.916,0,0,0,15.9,20.362l-1.188-4.934a.911.911,0,0,1,.289-.9l4.005-3.518A.93.93,0,0,0,18.459,9.413Z"
					transform="translate(3.891 -1.365)"
				/>
			</g>
			<path
				v-else
				id="Path_9376"
				data-name="Path 9376"
				d="M20.082,9.963,14.3,9.416a1,1,0,0,1-.828-.613L11.4,3.783a.991.991,0,0,0-1.839,0L7.507,8.8a.979.979,0,0,1-.828.613L.9,9.963A1,1,0,0,0,.334,11.7l4.357,3.827a.991.991,0,0,1,.315.977L3.7,21.874a1,1,0,0,0,1.474,1.094l4.821-2.833a1,1,0,0,1,1.011,0l4.821,2.833A1,1,0,0,0,17.3,21.874l-1.292-5.367a.991.991,0,0,1,.315-.977L20.678,11.7A1.012,1.012,0,0,0,20.082,9.963Z"
				transform="translate(0 -3.162)"
			/>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconStar',
	props: {
		outlined: {
			type: Boolean,
			default: true,
		},
		size: {
			default: 24,
			type: [String, Number],
		},
		stroke: {
			default: '0.8',
			type: [String, Number],
		},
	},
	computed: {
		attrs() {
			if (this.outlined) {
				return {
					width: '20.124',
					height: '19.154',
					viewBox: '0 0 20.124 19.154',
				}
			}
			return {
				width: '20.995',
				height: '19.947',
				viewBox: '0 0 20.995 19.947',
			}
		},
	},
}
</script>
