import api from './base.api'

const Contracts = {
	get: (query) => api.get('/contract', query),
	post: (body) => api.post('/contract', body),
	patch: (body) => api.post('/contract/update', body),
	delete: (id) => api.delete('/contract/' + id),
	getById: (id, query) => api.get('/contract/' + id, query),
	changeStatus: (body) => api.post('/contract/change-status', body),

	// Details
	getDetails: (query) => api.get('/contract/details', query),
	createDetails: (body) => api.post('/contract/details', body),
	updateDetails: (body) => api.post('/contract/details', body),
	signContract: (body) => api.post('/contract', body),

	// dependencies
	getBuyers: (query) => api.get('/buyer', query),
	getSuppliers: (query) => api.get('/supplier', query),
}

export { Contracts }
