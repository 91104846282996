'use strict'

const getSingleOfferOfProduct = (item) => {
	return item.cartable.offers.find((offer) => {
		return offer.isSingleProductOffer
	})
}

const useCart = (products = [], offers = []) => {
	// PRODUCTS SUB-TOTAL
	const subtotalOfProducts = products.reduce((total, cartItem) => {
		return total + cartItem.quantity * cartItem.cartable.price
	}, 0)

	// OFFERS SUB-TOTAL
	const subtotalOfOffers = offers.reduce((total, offerCartItem) => {
		const price = offerCartItem.cartable.products.reduce(
			(total, offerProduct) => {
				return (
					total + offerProduct.pivot.price * offerProduct.pivot.quantity
				)
			},
			0
		)
		return total + offerCartItem.quantity * price
	}, 0)

	const discountedSubtotalOfProducts = products.reduce((total, product) => {
		let offer = getSingleOfferOfProduct(product)
		const price = offer ? offer.finalPrice : product.cartable.price
		return total + product.quantity * price
	}, 0)

	const discountedSubtotalOfOffers = offers.reduce((total, item) => {
		return total + item.quantity * item.cartable.finalPrice
	}, 0)

	const discountOfProducts = subtotalOfProducts - discountedSubtotalOfProducts
	const discountOfOffers = subtotalOfOffers - discountedSubtotalOfOffers

	const subtotal = subtotalOfProducts + subtotalOfOffers
	const vat = (subtotal / 100) * 15
	const shippingFee = 0
	const total = subtotal + vat + shippingFee
	const discount = discountOfProducts + discountOfOffers
	const grandTotal = total - discount

	return {
		subtotalOfProducts,
		subtotalOfOffers,

		discountedSubtotalOfProducts,
		discountedSubtotalOfOffers,

		discountOfProducts,
		discountOfOffers,

		vat,
		discount,
		shippingFee,

		subtotal,
		total,
		grandTotal,
	}
}

export { useCart }
