import { PERMISSION, ROLE } from '../consts'
import { view } from '../helpers'

const adminRotues = {
	path: '/admin',
	redirect: 'admin/dashboard',
	component: () => import('@/layouts/Default.vue'),
	meta: {
		requiresRole: [ROLE.ADMIN],
	},
	children: [
		{
			path: 'dashboard',
			name: 'AdminDashboard',
			component: () => import('@/views/Supplier/Dashboard.vue'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'management',
			name: 'AdminManagement',
			component: view('Admin/Management'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'buyers/register',
			name: 'AdminRegisterBuyer',
			component: view('RegistrationRequest'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'buyers/:id',
			name: 'AdminBuyer',
			component: view('RegistrationRequest'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'buyers/:id/update',
			name: 'AdminBuyerUpdate',
			component: view('RegistrationRequest'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'buyers/:id/approve',
			name: 'AdminBuyerApprove',
			component: view('RegistrationRequest'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'buyers/register',
			name: 'AdminRegisterSupplier',
			component: view('RegistrationRequest'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'suppliers/:id',
			name: 'AdminSupplier',
			component: view('RegistrationRequest'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'suppliers/:id/update',
			name: 'AdminSupplierUpdate',
			component: view('RegistrationRequest'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'suppliers/:id/approve',
			name: 'AdminSupplierApprove',
			component: view('RegistrationRequest'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		// {
		// 	path: 'branches',
		// 	name: 'AdminBranches',
		// 	component: view('Supplier/Branches'),
		// 	meta: {
		// 		requiresAuth: true,
		// 		requiresRole: [ROLE.ADMIN],
		// 	},
		// },
		// {
		// 	path: 'create-branch',
		// 	name: 'adminCreateBranch',
		// 	component: view('Supplier/CreateBranch'),
		// 	meta: {
		// 		requiresRole: [ROLE.ADMIN],
		// 	},
		// },
		{
			path: 'users',
			name: 'AdminUsers',
			component: view('Users'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
				requirePermissions: [PERMISSION.USERS],
			},
		},
		{
			path: 'products',
			name: 'AdminProducts',
			component: view('Supplier/Products'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
				requirePermissions: [PERMISSION.PRODUCTS],
			},
		},
		{
			path: 'contracts',
			name: 'AdminContracts',
			component: view('Admin/Contracts'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
				requirePermissions: [PERMISSION.CONTRACT],
			},
		},
		{
			path: 'contracts/templates',
			name: 'AdminContractTemplates',
			component: view('Admin/ContractTemplates'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
				requirePermissions: [PERMISSION.CONTRACT],
			},
		},
		{
			path: 'package',
			name: 'AdminPackages',
			component: view('Admin/Packages'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
				requirePermissions: [PERMISSION.SUBSCRIPTION],
			},
		},

		{
			path: 'offers',
			name: 'AdminOffers',
			component: view('Supplier/Offers'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
				requirePermissions: [PERMISSION.OFFERS],
			},
		},
		{
			path: 'complaints',
			name: 'AdminComplaints',
			component: view('Complaints'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
				requirePermissions: [PERMISSION.COMPLAINT],
			},
		},
		{
			path: 'orders',
			name: 'AdminOrders',
			component: view('Supplier/Orders'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
				requirePermissions: [PERMISSION.ORDERS],
			},
		},
		{
			path: 'ad-spaces',
			name: 'AdSpaces',
			component: view('Admin/AdSpaces'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
				requirePermissions: [PERMISSION.ADS],
			},
		},
		{
			path: 'ads',
			name: 'AdminAds',
			component: view('Supplier/Ads'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
				requirePermissions: [PERMISSION.ADS],
			},
		},
		{
			path: 'invoices',
			name: 'AdminInvoices',
			component: view('Admin/Invoices'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'invoices/:id',
			name: 'AdminInvoice',
			component: view('Admin/Invoice'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'settings',
			name: 'AdminSettings',
			component: view('Settings'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'approval/:id',
			name: 'adminApproval',
			component: view('Admin/Approval'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'categories',
			name: 'AdminCategories',
			component: view('Admin/Category'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'banks',
			name: 'AdminsBanks',
			component: view('Admin/Banks'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: 'banners',
			name: 'AdminBanners',
			component: view('Admin/Banners'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
		{
			path: '*',
			name: 'AdminNotFound',
			component: view('404'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.ADMIN],
			},
		},
	],
}

export default adminRotues
