export const initialList = () => ({
	to: 1,
	from: 0,
	total: 0,
	data: [],
	perPage: 15,
	lastPage: 1,
	currentPage: 1,
	isLoaded: false,
	isRefreshing: false,
})

export const initialForm = (value = {}) => {
	return {
		isVisible: false,
		isValid: false,
		isLoading: false,
		isUpdate: false,
		data: null,
		...value,
	}
}
