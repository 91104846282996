import API from '../../api'
import { initialList } from '../store.helpers'
import { createGetters, createMutations, handleAction } from 'vuelpers'

const initialState = () => {
	return {
		banks: [],
		bank: initialList(),
		adminBanks: initialList(),
	}
}

const state = initialState()

const getters = {
	...createGetters('bank', 'banks', 'adminBanks'),
	formattedBank: (state) => {
		return state.banks.map((bank) => {
			return {
				label: bank.bankName,
				value: bank.id,
			}
		})
	},
}

const mutations = createMutations(
	'SET',
	'PUSH',
	'UNSHIFT',
	'DELETE',
	'UPDATE',
	'MERGE'
)

const actions = {
	fetchBanks({ commit, state: { adminBanks } }, payload) {
		commit('SET', {
			adminBanks: (v) => ({
				...v,
				isLoading: !adminBanks.isLoaded,
				isRefreshing: adminBanks.isLoaded,
			}),
		})
		return handleAction(API.bank.get(payload), (res) => {
			commit('SET', {
				adminBanks: (v) => ({
					...v,
					...res.banks,
					isLoaded: true,
					isLoading: false,
					isRefreshing: false,
				}),
			})
		})
	},
	createBank({ commit, dispatch }, payload = {}) {
		return handleAction(API.bank.post(payload), (res) => {
			commit('UNSHIFT', ['adminBanks.data', res.bank])
			dispatch('fetchBanks')
		})
	},
	updateBank({ commit }, payload) {
		return handleAction(API.bank.update(payload), (res) => {
			commit('UPDATE', ['adminBanks.data', res.bank])
		})
	},
	deleteBank({ commit }, payload) {
		return handleAction(API.bank.delete(payload), () => {
			commit('DELETE', ['adminBanks.data', payload])
		})
	},
	getBank({ commit }, payload = {}) {
		return handleAction(
			API.bank.get({ ...payload, can_paginate: 0 }),
			(res) => commit('SET', { banks: res.banks })
		)
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
