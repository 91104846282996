<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17.297"
			height="18.869"
			viewBox="0 0 17.297 18.869"
		>
			<g id="copy_11_" data-name="copy (11)" transform="translate(-1)">
				<path
					id="Path_31205"
					data-name="Path 31205"
					d="M17.4,19.724H7.966A1.968,1.968,0,0,1,6,17.759V5.966A1.968,1.968,0,0,1,7.966,4H17.4a1.968,1.968,0,0,1,1.966,1.966V17.759A1.968,1.968,0,0,1,17.4,19.724ZM7.966,4.786A1.181,1.181,0,0,0,6.786,5.966V17.759a1.181,1.181,0,0,0,1.179,1.179H17.4a1.181,1.181,0,0,0,1.179-1.179V5.966A1.181,1.181,0,0,0,17.4,4.786Z"
					transform="translate(-1.069 -0.855)"
				/>
				<path
					id="Path_31206"
					data-name="Path 31206"
					d="M3.752,16.511H2.966A1.968,1.968,0,0,1,1,14.545V1.966A1.968,1.968,0,0,1,2.966,0H12.4a1.968,1.968,0,0,1,1.966,1.966.393.393,0,1,1-.786,0A1.181,1.181,0,0,0,12.4.786H2.966A1.181,1.181,0,0,0,1.786,1.966v12.58a1.181,1.181,0,0,0,1.179,1.179h.786a.393.393,0,0,1,0,.786Z"
				/>
				<path
					id="Path_31207"
					data-name="Path 31207"
					d="M16.683,16.786h-6.29a.393.393,0,1,1,0-.786h6.29a.393.393,0,0,1,0,.786Z"
					transform="translate(-1.924 -3.42)"
				/>
				<path
					id="Path_31208"
					data-name="Path 31208"
					d="M16.683,20.786h-6.29a.393.393,0,0,1,0-.786h6.29a.393.393,0,0,1,0,.786Z"
					transform="translate(-1.924 -4.276)"
				/>
				<path
					id="Path_31209"
					data-name="Path 31209"
					d="M16.683,12.786h-6.29a.393.393,0,0,1,0-.786h6.29a.393.393,0,0,1,0,.786Z"
					transform="translate(-1.924 -2.565)"
				/>
				<path
					id="Path_31210"
					data-name="Path 31210"
					d="M16.683,8.786h-6.29a.393.393,0,0,1,0-.786h6.29a.393.393,0,0,1,0,.786Z"
					transform="translate(-1.924 -1.71)"
				/>
			</g>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconNoteList',
}
</script>
