<template>
	<div class="base-data-table">
		<v-toolbar flat dense class="tw-flex-none">
			<v-toolbar-title class="tw-font-semibold tw-text-22">
				{{ title }}
			</v-toolbar-title>

			<v-spacer />

			<!-- TABS -->
			<div v-if="tabs.length > 0" class="tw-flex">
				<v-btn
					v-for="tab in tabs"
					:key="tab.value"
					:color="tab.value === currentTab ? '#722432' : '#D8D8D8'"
					:text="tab.value !== currentTab"
					depressed
					style="min-width: 180px;"
					class="lg:tw-h-12"
					@click="onClickCurrentTab(tab.value)"
				>
					<span
						class="tw-normal-case tw-text-sm tw-font-normal"
						:class="
							tab.value === currentTab ? 'tw-text-white' : 'tw-text-CB'
						"
						>{{ tab.text }}</span
					>
				</v-btn>
			</div>

			<div class="tw-flex tw-items-center">
				<slot name="search">
					<div class="lg:tw-ps-4 tw-max-w-xs tw-flex tw-items-center ">
						<v-text-field
							outlined
							hide-details="auto"
							prepend-inner-icon="mdi-magnify"
							:placeholder="$t('hintSearch')"
							:dense="$vuetify.breakpoint.mdAndDown"
							:style="{
								maxHeight: `${
									$vuetify.breakpoint.mdAndDown ? 40 : 50
								}px`,
							}"
							class="base-data-table__search-field"
							v-model="keyword"
							@input="onInputKeyword"
							@keyup.enter="onEnterKeyword"
						/>
					</div>
				</slot>
				<slot v-if="add" name="add">
					<v-btn
						:height="$vuetify.breakpoint.mdAndDown ? 40 : 50"
						dark
						depressed
						color="#03735D"
						class="tw-ms-2 xl:tw-ms-5"
						@click="$emit('click:add', $event)"
					>
						<v-icon>mdi mdi-plus</v-icon>
					</v-btn>
				</slot>
				<slot v-if="bulkUpload" name="bulkUpload">
					<v-menu
						:nudge-bottom="'80px'"
						bottom
						left
						content-class="my-menu tw-rounded-lg"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								height="50"
								class="tw-ms-2"
								dark
								color="#03735D"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi mdi-plus</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item
								v-for="(item, index) in bulkDropDown"
								@click="$emit(item.event)"
								:key="index"
								class="tw-cursor-pointer"
							>
								<v-list-item-title>{{ item.title }}</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</slot>
			</div>
		</v-toolbar>

		<slot name="extension"> </slot>

		<slot v-if="innerTabs.length" name="tab">
			<div class="tw-px-4">
				<v-tabs v-model="innerTab">
					<v-tab
						v-for="(tab, i) in innerTabs"
						:key="i"
						:value="tab.value"
						@click="onClickInnerTab(tab)"
					>
						{{ tab.text }}
					</v-tab>
				</v-tabs>
			</div>
		</slot>

		<v-data-table
			class="mt-3 "
			disable-sort
			hide-default-footer
			:show-expand="showExpand"
			:single-expand="singleExpand"
			:expanded.sync="expandedModel"
			:headers="cHeaders"
			:items="provider.data"
			:items-per-page="perPageValue"
			:loading="provider.isRefreshing || provider.isLoading"
			loading-text=""
		>
			<template #progress>
				<div v-if="provider.isLoading" class="skeletons">
					<div
						class="tw-flex tw-w-full"
						v-for="row in 5"
						:key="row"
						:class="{ 'tw-mt-2': row }"
					>
						<v-skeleton-loader
							v-for="i in headers.length"
							:key="i"
							type="list-item"
							class="tw-flex-1"
						></v-skeleton-loader>
					</div>
				</div>
				<v-progress-linear
					v-else-if="provider.isRefreshing"
					indeterminate
					color="primary"
				/>
			</template>
			<template
				v-for="header in headers"
				v-slot:[`item.${header.value}`]="props"
			>
				<div :class="header.class" :key="header.value">
					<slot
						:item="props.item"
						:index="props.index"
						:expand="props.expand"
						:headers="headers"
						:isMobile="props.isMobile"
						:isExpanded="props.isExpanded"
						:name="`item.${header.value}`"
					>
						{{
							header.computed
								? header.computed(props.item, header)
								: get(props.item, header.value)
						}}
					</slot>
				</div>
			</template>

			<!-- ACTIONS -->
			<template v-slot:[`item.actions`]="{ item }">
				<BaseTableActions
					:actions="actions"
					:item="item"
					v-on="actionsOn"
				/>
			</template>

			<!-- FOOTER -->
			<template #footer>
				<div class="pt-2 tw-flex tw-justify-end tw-pb-8">
					<div
						class=" base-data-table-per-page tw-flex tw-items-center tw-space-s-2"
					>
						<div class="tw-flex-none ">
							{{ $t('pagination.perPage') }}{{ $t('colon') }}
						</div>
						<div class="tw-w-20">
							<v-combobox
								v-model="perPage"
								:items="perPageItems"
								:readonly="disabledPerPage"
								solo
								flat
								dense
								outlined
								type="number"
								hide-details="auto"
								@change="onChangePerPage"
							/>
						</div>
					</div>
					<v-pagination
						v-model="currentPage"
						:length="Math.ceil(provider.total / provider.perPage)"
						:total-visible="$vuetify.breakpoint.mdAndDown ? 3 : 7"
						outlined
						color="#03735D"
					/>
				</div>
			</template>

			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length" class="tw-p-0 ">
					<slot
						name="expanded-item"
						:headers="headers"
						:item="item"
					></slot>
				</td>
			</template>
		</v-data-table>
		<v-overlay :value="actionMenu" opacity="0.18" color="black" />
	</div>
</template>

<script>
import { get, isObject } from 'lodash'
// import { ROLE } from '@/consts.js'
import { mapGetters } from 'vuex'
import {
	omitEmpties,
	secureDataType,
	changeLocationQuery,
	queryString as qs,
	convertKeysToCamelCase,
	convertKeysToSnakeCase,
} from 'vuelpers'
import { i18n } from '../../plugins/vue-i18n'

export default {
	name: 'BaseDataTable',
	// role: ROLE,
	props: {
		translate: Boolean,
		fetchFunction: Function,
		expanded: {
			type: Array,
			default: () => [],
		},
		showExpand: {
			default: false,
			type: Boolean,
		},
		singleExpand: {
			default: false,
			type: Boolean,
		},
		autoExpandFirst: {
			default: false,
			type: Boolean,
		},
		title: {
			type: String,
			default: i18n.t('heading.baseDataTable'),
		},
		name: {
			type: String,
		},
		headers: {
			type: Array,
			default: () => [],
		},
		add: {
			type: Boolean,
			default: true,
		},
		bulkUpload: {
			type: Boolean,
			default: false,
		},
		disabledPerPage: {
			type: Boolean,
			default: false,
		},
		provider: {
			type: Object,
			default: () => ({
				data: [],
				page: 1,
				total: 0,
				perPage: 15,
			}),
		},
		actions: {
			type: Array,
			default: () => [
				{
					icon: 'mdi-eye-outline',
					event: 'view',
					text: i18n.t('actions.viewDetails'),
				},
				{
					icon: 'mdi-pencil-outline',
					event: 'update',
					text: i18n.t('actions.update'),
				},
				{
					icon: 'mdi-delete-outline',
					event: 'delete',
					text: i18n.t('actions.delete'),
				},
			],
		},
		perPageItems: {
			type: Array,
			default: () => [
				{ text: '5', value: 5 },
				{ text: '10', value: 10 },
				{ text: '15', value: 15 },
				{ text: '20', value: 20 },
				{ text: '50', value: 50 },
				{ text: '100', value: 100 },
				// { text: 'All', value: 'ALL' },
			],
		},
		tabs: {
			type: Array,
			default: () => [],
		},
		currentTab: {
			type: String,
			default: '',
		},
		tabSlider: {
			type: String,
		},
		tabSliders: {
			type: Array,
			default: () => [],
		},
	},
	data: () => ({
		keyword: '',
		perPage: null,
		isLoaded: false,
		fetchQuery: null,
		actionMenu: false,
		bulkDropDown: [
			{
				event: 'uploadSingleProduct',
				title: i18n.t('actions.addSingleProduct'),
			},
			{
				event: 'uploadBulkProduct',
				title: i18n.t('actions.addBulkProduct'),
			},
		],
	}),
	watch: {
		tabSliders: {
			deep: true,
			handler(v) {
				if (!v.length) return
				this.$emit('update:tab-slider', v[0]?.value)
			},
		},
	},
	created() {
		let { q, page, perPage, tab, innerTab } = convertKeysToCamelCase(
			this.$route.query
		)
		this.keyword = secureDataType(q) || ''
		this.perPage = this.getPerPageObject(perPage || this.provider.perPage)
		this.fetchQuery = omitEmpties({
			q: this.keyword,
			perPage: this.perPageValue,
			page: page || this.provider.currentPage,
		})

		// Set Tabs
		if (tab) this.onClickCurrentTab(tab, { skipInner: !!innerTab })
		if (innerTab) this.onClickInnerTab({ value: innerTab })

		this.fetchItems(this.fetchQuery).then(() => {
			this.isLoaded = true
			if (this.autoExpandFirst && this.provider.data.length) {
				this.expandedModel = [this.provider.data[0]]
			}
		})
	},
	computed: {
		...mapGetters('auth', ['currentUser']),

		innerTabs() {
			return this.tabSliders
		},
		innerTab: {
			get() {
				return this.innerTabs.findIndex(v => {
					return v.value === this.tabSlider
				})
			},
			set(v) {
				this.$emit(
					'update:tab-slider',
					typeof v === 'number' ? this.innerTabs[v]?.value : v
				)
			},
		},
		actionsOn() {
			return this.actions
				.filter(action => action.event)
				.reduce((on, action, index) => {
					const name = action.event ?? `action:${index}`
					on[name] = e => this.$emit(name, e)
					return on
				}, {})
		},
		expandedModel: {
			get() {
				return this.expanded
			},
			set(v) {
				this.$emit('update:expanded', v)
			},
		},
		perPageValue() {
			return +(isObject(this.perPage)
				? this.perPage.value === 'ALL'
					? this.provider.total
					: this.perPage.value
				: this.perPage)
		},
		cHeaders() {
			if (!this.translate) return this.headers
			return this.headers.map(header => {
				return {
					...header,
					text: this.$t(header.text),
				}
			})
		},
		currentPage: {
			get() {
				return this.provider.currentPage
			},
			set(v) {
				this.fetchItems({ ...this.fetchQuery, page: v })
			},
		},
	},
	methods: {
		get,
		onInputKeyword(v) {
			if (v) return
			this.onEnterKeyword()
			this.onChangeLocationQuery({ q: '' }, false)
		},
		onChangeLocationQuery(queryParams, omit = true) {
			queryParams = omit ? omitEmpties(queryParams) : queryParams
			changeLocationQuery(
				qs.stringify({
					...qs.parse(),
					...convertKeysToSnakeCase(queryParams),
				})
			)
		},
		onEnterKeyword() {
			console.log('onEnterKeyword')
			this.fetchItems({
				...this.fetchQuery,
				q: this.keyword,
			})
		},
		getPerPageObject(v) {
			const perPageItem = this.perPageItems.find(ppi => ppi.value == v)
			if (!perPageItem) return { text: v, value: v }
			return perPageItem
		},
		onChangePerPage() {
			console.log('onChangePerPage')
			this.fetchItems({
				...this.fetchQuery,
				perPage: this.perPageValue,
			})
		},
		async fetchItems(queryParams) {
			if (!this.fetchFunction) return
			if (
				this.provider.isLoaded &&
				queryParams.perPage >= this.provider.total &&
				queryParams.page > 1
			) {
				queryParams.page = 1
			}
			this.fetchQuery = omitEmpties(queryParams)

			await this.fetchFunction(this.fetchQuery)
			this.onChangeLocationQuery(this.fetchQuery)
		},
		onClickCurrentTab(tab, { skipInner = false } = {}) {
			this.$emit('update:current-tab', tab)
			this.onChangeLocationQuery({ tab })

			this.$nextTick(() => {
				if (!skipInner) {
					// Set Inner Tab
					this.onChangeLocationQuery({ innerTab: this.tabSlider })
					this.innerTab = this.tabSlider
				}

				// Fetch Items
				// if (!this.isLoaded) return
				this.fetchItems({
					...this.fetchQuery,
					q: this.keyword,
					page: 1,
				})
			})
		},
		onClickInnerTab(tab) {
			this.innerTab = tab.value
			this.onChangeLocationQuery({ innerTab: tab.value })
			this.$nextTick(() => {
				// if (!this.isLoaded) return
				this.fetchItems({
					...this.fetchQuery,
					q: this.keyword,
					page: 1,
				})
			})
		},
	},
}
</script>
<style lang="scss">
.base-data-table {
	--item-height: 64px;
	--item-space-between: 0.5rem;
	--item-border-radius: 0.5rem;
	--item-background-color: #fdfdfd;
	&__search-field {
		.v-input__slot {
			min-height: 50px !important;
		}
		.v-input__prepend-inner {
			margin-top: 14px !important;
		}
		fieldset {
			border-color: #f0f0f0 !important;
		}
	}
	.skeletons {
		.v-skeleton-loader > .v-skeleton-loader__bone {
			height: var(--item-height) !important;
			background-color: var(--item-background-color) !important;
		}
	}
	.v-toolbar {
		&,
		.v-toolbar__content {
			height: auto !important;
			min-height: 68px !important;
		}
		.v-toolbar__content {
			flex-wrap: wrap;
		}
	}
	table {
		border-spacing: 0 var(--item-space-between) !important;
		border-collapse: separate !important;
		tbody {
			tr {
				td {
					border: none !important;
					height: var(--item-height) !important;
					background-color: var(--item-background-color) !important;
				}
			}
		}
		td {
			border: solid 1px #000;
		}
		tr td:first-child {
			border-bottom-left-radius: var(--item-border-radius);
			border-top-left-radius: var(--item-border-radius);
		}
		tr td:last-child {
			border-bottom-right-radius: var(--item-border-radius);
			border-top-right-radius: var(--item-border-radius);
		}
	}
	.v-data-table {
		> .v-data-table__wrapper {
			table {
				thead > tr > th {
					font-size: 15px;
					color: #000 !important;
					font-weight: 400 !important;
					text-transform: uppercase !important;
				}
				thead > tr:last-child > th {
					border-bottom: none !important;
				}
			}
			tbody {
				tr:first-child:hover {
					td:first-child {
						border-top-left-radius: var(--item-border-radius) !important;
					}
					td:last-child {
						border-top-right-radius: var(--item-border-radius) !important;
					}
				}
				tr:last-child:hover {
					td:first-child {
						border-bottom-left-radius: var(
							--item-border-radius
						) !important;
					}
					td:last-child {
						border-bottom-right-radius: var(
							--item-border-radius
						) !important;
					}
				}
			}
			tbody > tr > td {
				font-size: 14px;
				color: #000 !important;
				font-weight: 600 !important;
			}
		}
		.v-data-footer {
			border-top: none !important;
		}
	}
	.base-data-table-per-page {
		.v-input__control,
		.v-input__slot {
			min-height: 32px !important;
		}
		.v-input__append-inner {
			display: none !important;
		}
	}
	.v-tab {
		&--active {
			border-radius: 4px;
			background-color: rgba(229, 231, 235, 1);
			// background-color: rgba(114, 36, 50, 0.25) !important;
		}
	}
}
.my-menu {
	contain: initial;
	overflow: visible;
}
.my-menu::before {
	position: absolute !important;
	content: '';
	top: 0 !important;
	right: 20px !important;
	transform: translateY(-100%) !important;
	width: 10px !important;
	height: 13px !important;
	border-left: 10px solid transparent !important;
	border-right: 10px solid transparent !important;
	border-bottom: 13px solid #fdfdfd !important;
}
</style>
