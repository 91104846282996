<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="19.113"
		height="14.36"
		viewBox="0 0 19.113 14.36"
	>
		<path
			id="envelope_2_"
			data-name="envelope (2)"
			d="M1.782,59.882H17.23a1.784,1.784,0,0,1,1.782,1.782V72.359a1.784,1.784,0,0,1-1.782,1.782H1.782A1.784,1.784,0,0,1,0,72.359V61.664A1.784,1.784,0,0,1,1.782,59.882Zm0,1.188a.591.591,0,0,0-.228.046l7.952,6.892,7.952-6.892a.591.591,0,0,0-.228-.046Zm0,11.883H17.23a.6.6,0,0,0,.594-.594V62.372L9.9,69.243a.6.6,0,0,1-.779,0L1.188,62.372v9.987A.6.6,0,0,0,1.782,72.953Z"
			transform="translate(0.05 -59.832)"
			fill="#fff"
			stroke="#fff"
			stroke-width="0.1"
		/>
	</svg>
</template>

<script>
export default {
	name: 'IconEmail',
}
</script>
