export const _baseURL = process.env.BASE_URL
export const _isDev = process.env.NODE_ENV === 'development'
export const _isProd = process.env.NODE_ENV === 'production'

export const _serverBaseURL = process.env.VUE_APP_SERVER_BASE_URL
// export const _serverBaseURL = 'https://machla.sa/backend'
// export const _serverBaseURL = 'http://192.168.31.42:8001'
// export const _serverBaseURL = 'http://192.168.31.100:8001'
// export const _serverBaseURL = 'http://192.168.31.100:8000'

export const TAX_RATE = 15 / 100

export const ROLE = {
	ADMIN: 'admin',
	BUYER: 'buyer',
	SUPPLIER: 'supplier',
}

export const DEFAULT_ROUTE = {
	[ROLE.ADMIN]: '/admin',
	[ROLE.BUYER]: '/',
	[ROLE.SUPPLIER]: '/supplier',
}

export const MODELS = {
	BUYER: 'App\\Models\\Buyer',
	SUPPLIER: 'App\\Models\\Supplier',
	OFFER: 'App\\Models\\Offer',
	PRODUCT: 'App\\Models\\Product',
}

export const BranchableType = [
	{ value: MODELS.BUYER, text: 'Buyer' },
	{ value: MODELS.SUPPLIER, text: 'Supplier' },
]

export const USER_TYPE = {
	SYSTEM_ADMIN: 'admin',
	RESOURCE_USER: 'supplier',
	BUYER_AFFLIATE: 'buyer',
}

export const PAYTAB_PAYMENT_TYPE = {
	CART: 'cart',
	SUBSCRIPTION: 'subscription',
	ADVERTISEMENT: 'advertisement',
	MARKETING_OFFER: 'marketing_offer',
}
export const CONTRACT_WITH = [
	{
		label: 'buyerLabel',
		value: ROLE.BUYER,
	},
	{
		label: 'supplierLabel',
		value: ROLE.SUPPLIER,
	},
]
export const USER_TYPES = [
	{
		value: USER_TYPE.SYSTEM_ADMIN,
		label: 'userType.systemAdmin',
	},
	{
		value: USER_TYPE.RESOURCE_USER,
		label: 'userType.resourceUser',
	},
	{
		value: USER_TYPE.BUYER_AFFLIATE,
		label: 'userType.buyerAffliate',
	},
]

export const PERMISSION = {
	REPORTS: 'reports',
	PACKAGES: 'packages',
	ADS: 'ads-management',
	COMPLAINT: 'complaint-management',
	COMPLAINTS: 'complaints',
	USERS: 'user-management',
	ORDERS: 'order-management',
	PRODUCTS: 'product-management',
	BILLING: 'billing-management',
	ACCOUNTS: 'account-management',
	OFFERS: 'offer-management',
	SUBSCRIPTION: 'subscription-management',
	SETTINGS: 'settings',
	REPORT: 'report-management',
	CONTRACT: 'contract-management',
}

export const STATUS = {
	TRUE: 1,
	FALSE: 0,
	ACTIVE: 'active',
	INACTIVE: 'inactive',
	DELETED: 'deleted',
	PENDING: 'pending',
	APPROVED: 'approved',
	REJECTED: 'rejected',
	CANCELLED: 'cancelled',
	COMPLETED: 'completed',
	PENDING_APPROVAL: 'pending_approval',
	NEW: 'new',
	PROCCESSING: 'processing',
	DELIVERED: 'delivered',
	DELAY: 'delay',
	IN_STORE: 'in-store',
	OUT_OF_STOCK: 'out-of-stock',
	ON_DELIVERY: 'on-delivery',
	AWAITING_PAYMENT: 'awaiting-payment',
	DRIVEN: 'driven',
	PAID: 'paid',
	CLOSED: 'closed',
}

export const InvoiceStatuses = [
	{
		value: STATUS.PAID,
		text: 'Paid',
	},
	{
		value: STATUS.PENDING,
		text: 'Awaiting Payment',
	},
	{
		value: STATUS.CANCELLED,
		text: 'Cancelled',
	},
]

InvoiceStatuses.get = function(value) {
	return this.find(item => item.value === value)
}

InvoiceStatuses.exclude = function(value) {
	return this.filter(item => item.value !== value)
}

export const ComplaintStatuses = [
	{
		value: STATUS.NEW,
		text: 'New',
	},
	{
		value: STATUS.PROCCESSING,
		text: 'Under Process',
	},
	{
		value: STATUS.CLOSED,
		text: 'Closed',
	},
]

export const STATUS_COLOR = {
	[STATUS.TRUE]: '#AAD4A9',
	[STATUS.FALSE]: '#722432',
	[STATUS.NEW]: '#9C27B0',
	[STATUS.CANCELLED]: '#F44336',
	[STATUS.PROCCESSING]: '#42A5F5',
	[STATUS.DELIVERED]: '#4CAF50',
	[STATUS.PENDING]: '#FFC107',
	[STATUS.DELAY]: '#E91E63',
	[STATUS.IN_STORE]: '#4CAF50',
	[STATUS.OUT_OF_STOCK]: '#F44336',
	[STATUS.ON_DELIVERY]: '#00BCD4',
}
