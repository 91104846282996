<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="15.445"
			height="16.891"
			viewBox="0 0 15.445 16.891"
		>
			<g
				id="Group_40465"
				data-name="Group 40465"
				transform="translate(0.25 0.25)"
			>
				<path
					id="Path_34290"
					data-name="Path 34290"
					d="M36.155,15.719V3.54a.672.672,0,0,0-1.344,0V14.976H23.443c-.532,0-.921-.283-.921-.566s.389-.566.921-.566h9.631a.671.671,0,0,0,.672-.672h0V.672A.671.671,0,0,0,33.075,0H23.9a2.514,2.514,0,0,0-2.691,2.265V14.444a2.1,2.1,0,0,0,2.265,1.947H35.517A.623.623,0,0,0,36.155,15.719ZM22.594,2.265c0-.495.6-.887,1.31-.887h8.5V12.5H23.481a2.6,2.6,0,0,0-.887.153V2.265Z"
					transform="translate(-21.213)"
					stroke="#fff"
					stroke-width="0.5"
				/>
				<path
					id="Path_34291"
					data-name="Path 34291"
					d="M118.985,110.344h4.5a.7.7,0,0,0,.709-.672.671.671,0,0,0-.672-.672h-4.533a.694.694,0,0,0-.672.672A.671.671,0,0,0,118.985,110.344Z"
					transform="translate(-115.001 -105.282)"
					stroke="#fff"
					stroke-width="0.5"
				/>
			</g>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconBook',
}
</script>
