import { complaints } from '../../api'
import { initialList } from '../store.helpers'
import {
	createGetters,
	createMutations,
	handleAction,
} from 'vuelpers'

const initialState = () => {
	return {
		complaints: initialList(),
		categories: initialList(),
	}
}

const state = initialState()

const getters = {
	...createGetters('complaints', 'categories'),
}

const mutations = {
	...createMutations('SET', 'PUSH', 'UNSHIFT', 'DELETE', 'UPDATE', 'MERGE'),
}

const actions = {
	setComplaintState({ commit }, payload) {
		commit('SET', payload)
	},
	createComplaint({ commit }, payload = {}) {
		return handleAction(complaints.post(payload), (res) => {
			commit('UNSHIFT', ['complaints.data', res.complaint])
		})
	},
	deleteComplaint({ commit }, payload = {}) {
		return handleAction(complaints.delete(payload), () => {
			commit('DELETE', ['complaints.data', payload])
		})
	},
	updateComplaint({ commit }, payload = {}) {
		return handleAction(complaints.patch(payload), (res) => {
			commit('UPDATE', ['complaints.data', res.complaint])
		})
	},
	changeStatusOfComplaint({ commit }, payload = {}) {
		return handleAction(complaints.changeStatus(payload), (res) => {
			commit('UPDATE', ['complaints.data', res.complaint])
		})
	},
	getComplaint({ commit }, payload = {}) {
		return handleAction(
			complaints.getById(payload.id, payload.query),
			(res) => {
				commit('UNSHIFT', ['complaints.data', res.complaint])
			}
		)
	},
	getCategories({ commit }) {
		return handleAction(complaints.get({ type: 'complaint' }), (res) => {
			commit('SET', { categories: res.data })
		})
	},
	getComplaints({ commit }, payload = {}) {
		return handleAction(complaints.get(payload), (res) => {
			commit('SET', { complaints: res.complaints })
		})
	},
	resetState({ commit }) {
		commit('SET', initialState())
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
