<template>
	<v-btn
		v-bind="$attrs"
		v-on="$listeners"
		text
		x-large
		depressed
		class="tw-normal-case tw-tracking-normal tw-relative"
	>
		<div class="tw-text-right">
			<div class="tw-font-pop tw-text-18px tw-font-semibold tw-text-black">
				{{ $currentUser.name }}
			</div>
			<div class="tw-font-pop tw-text-94a tw-text-13px tw-font-normal">
				{{ $currentUser.email }}
			</div>
		</div>
		<div class="tw-ms-6">
			<!-- <v-badge
				:content="_.capitalize($currentUser.role)"
				bottom
				bordered
				offset-x="76"
				offset-y="20"
			> -->
			<v-avatar size="48" color="primary" class="white--text">
				<v-img
					v-if="$currentUser.avatar"
					:alt="$currentUser.name"
					:src="$image($currentUser.avatar.attachedLink)"
				/>
				<template v-else>
					{{ $utils.useInitial($currentUser.name) }}
				</template>
			</v-avatar>
			<!-- </v-badge> -->
		</div>
	</v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'AppBarMenuActivator',
	computed: {
		...mapGetters('auth', ['$currentUser']),
	},
}
</script>
