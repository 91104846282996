<template>
	<div>
		<v-sheet
			ref="dzone"
			tabindex="0"
			width="100%"
			id="dropzone"
			color="lighten-4"
			style="cursor: pointer"
			:title="$t('heading.grabFile')"
			class="tw-rounded tw-relative"
			:class="{
				'tw-bg-accent tw-bg-opacity-10': dragging,
			}"
			@drop.prevent="onDrop"
			@dragenter.prevent="dragging = true"
			@dragover.prevent="dragging = true"
			@dragleave.prevent="dragging = false"
		>
			<slot
				name="placeholder"
				v-bind="{ error, readonly, dragging }"
				:on="{ click: onOpenFilePicker }"
				:attrs="{
					class: {
						'tw-border-red-500': error && !dragging,
						'tw-border-gray-300': !error && !dragging,
						'tw-border-accent tw-bg-accent tw-bg-opacity-10': dragging,
					},
				}"
			>
				<div
					v-if="!readonly"
					class="tw-border-2 tw-border-dashed tw-py-8"
					:class="{
						'tw-border-red-500': error && !dragging,
						'tw-border-gray-300': !error && !dragging,
						'tw-border-accent tw-bg-accent tw-bg-opacity-10': dragging,
					}"
					@click="onOpenFilePicker"
				>
					<v-container fluid>
						<v-row justify="center" class="">
							<svg
								id="upload"
								xmlns="http://www.w3.org/2000/svg"
								width="43.002"
								height="31.904"
								viewBox="0 0 43.002 31.904"
							>
								<path
									id="Path_19678"
									data-name="Path 19678"
									d="M36.414,75.775a6.655,6.655,0,0,0-.711.04A7.963,7.963,0,0,0,25.57,70.639,9.7,9.7,0,0,0,8.037,73c-.02,0-.04,0-.06,0a7.976,7.976,0,0,0,0,15.952H36.414a6.589,6.589,0,1,0,0-13.178Z"
									transform="translate(-0.001 -66.065)"
									fill="#f2f6fd"
								/>
								<path
									id="Path_19679"
									data-name="Path 19679"
									d="M36.739,161.324H31.211a.694.694,0,0,1-.694-.694v-2.2a2.774,2.774,0,0,0-.813-1.962l-4.042-4.042a2.774,2.774,0,0,0-1.962-.813H14.565a2.081,2.081,0,0,0-2.081,2.081v6.443a.7.7,0,0,1-.929.657,7.9,7.9,0,0,1-3.928-11.938A7.969,7.969,0,0,0,0,157.086a8.143,8.143,0,0,0,8.225,7.706H36.412q.268,0,.531-.021A1.729,1.729,0,0,0,36.739,161.324Z"
									transform="translate(0 -141.904)"
									fill="#e1e7f4"
								/>
								<path
									id="Path_19680"
									data-name="Path 19680"
									d="M179.727,219H165.856a.694.694,0,0,1-.694-.694V198.89a.694.694,0,0,1,.694-.694h9.136a1.387,1.387,0,0,1,.981.406l4.042,4.042a1.387,1.387,0,0,1,.406.981V218.31A.694.694,0,0,1,179.727,219Z"
									transform="translate(-151.29 -187.099)"
									fill="#f2ca51"
								/>
								<g
									id="Group_11355"
									data-name="Group 11355"
									transform="translate(17.86 2.045)"
								>
									<path
										id="Path_19681"
										data-name="Path 19681"
										d="M219.738,299.209l-2.774-3.468a.9.9,0,0,0-1.355,0l-2.774,3.468a.867.867,0,1,0,1.355,1.084l1.23-1.538v5.158a.867.867,0,0,0,1.734,0v-5.158l1.23,1.538a.867.867,0,1,0,1.355-1.084Z"
										transform="translate(-212.645 -278.215)"
										fill="#fff"
									/>
									<path
										id="Path_19682"
										data-name="Path 19682"
										d="M217.676,94.785a.866.866,0,0,1-.694-.346,6.058,6.058,0,0,0-3.641-2.308.867.867,0,0,1,.344-1.7,7.791,7.791,0,0,1,4.684,2.965.867.867,0,0,1-.693,1.388Z"
										transform="translate(-212.646 -90.414)"
										fill="#fff"
									/>
									<path
										id="Path_19683"
										data-name="Path 19683"
										d="M348.5,146.053a.868.868,0,0,1-.767-.462,4.327,4.327,0,0,0-2.39-2.066.867.867,0,0,1,.577-1.635,6.059,6.059,0,0,1,3.347,2.891.867.867,0,0,1-.766,1.272Z"
										transform="translate(-333.669 -137.521)"
										fill="#fff"
									/>
								</g>
								<path
									id="Path_19684"
									data-name="Path 19684"
									d="M273.9,198.324a1.383,1.383,0,0,0-.575-.129h-.813v4.855a1.389,1.389,0,0,0,1.387,1.387h4.855v-.813a1.382,1.382,0,0,0-.129-.575H273.9v-4.726Z"
									transform="translate(-249.629 -187.098)"
									fill="#ddae1f"
								/>
							</svg>
						</v-row>
						<div class="tw-text-center tw-mt-4">
							<p
								class="title tw-text-sm tw-text-black tw-opacity-50 tw-font-pop tw-font-semibold"
							>
								{{ $t('actions.upload') }} {{ type }}
							</p>
							<p class="tw-text-xs tw-text-CA">
								{{
									$t('hint.dropMax2mb', [
										type == 'image' ? $t('image') : $t('file'),
									])
								}}
							</p>
						</div>
					</v-container>
				</div>
			</slot>

			<div v-if="!hideList && attachments.length" class="tw-mt-4">
				<div class="tw-flex tw-flex-wrap tw--ms-4 tw--mt-4">
					<v-card
						v-for="(attachment, index) in attachments"
						:key="index"
						outlined
						width="156"
						height="156"
						class="tw-relative tw-overflow-hidden tw-ms-4 tw-mt-4"
					>
						<v-btn
							icon
							x-small
							v-if="!readonly"
							class="tw-absolute tw-top-0.5 tw-right-0.5 tw-z-10 error tw-text-white"
							@click="onRemoveAttachment(index)"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
						<div class="tw-p-1">
							<slot name="img" :src="attachment.src">
								<v-img
									:src="attachment.src"
									:ref="`imgRef-${index}`"
									aspect-ratio="1"
									class="tw-rounded"
									@error="
										$onImgError({ ref: $refs[`imgRef-${index}`] })
									"
								/>
							</slot>
						</div>
						<v-badge
							v-if="attachment.file"
							dot
							bordered
							color="blue"
							class="tw-absolute tw-bottom-1 tw-right-2 tw-z-10"
						></v-badge>
					</v-card>
				</div>
			</div>

			<v-tooltip top v-if="!hideList && !readonly && hasNewAttachments">
				<template #activator="{ on, attrs }">
					<v-btn
						icon
						v-on="on"
						v-bind="attrs"
						color="white"
						type="button"
						class="tw-bg-red-500 tw-absolute tw-bottom-1 tw-right-1"
						@click="onResetChanges"
					>
						<v-icon>mdi-backup-restore</v-icon>
					</v-btn>
				</template>
				<span>{{ $t('hint.resetChanges') }}</span>
			</v-tooltip>
		</v-sheet>
	</div>
</template>
<script>
import { onFilePicked, openFilePicker } from 'vuelpers'
import { i18n } from '../../plugins/vue-i18n'

export default {
	name: 'BaseDropZone',
	props: {
		error: Boolean,
		readonly: Boolean,
		hideList: Boolean,
		accept: {
			type: String,
			default: 'image/x-png,image/gif,image/jpeg',
		},
		value: {
			required: false,
		},
		type: {
			type: String,
			default: i18n.t('image'),
		},
	},
	data: () => ({
		dragging: false,
	}),
	computed: {
		hasNewAttachments() {
			return (
				this.attachments.findIndex((attachment) => {
					return !!attachment.file
				}) > -1
			)
		},
		isMultiple() {
			return Array.isArray(this.value)
		},
		attachments: {
			get() {
				return Array.isArray(this.value)
					? this.value
					: this.value
					? [this.value]
					: []
			},
			set(v) {
				this.$emit('input', this.isMultiple ? v : v[0])
			},
		},
	},
	methods: {
		onRemoveAttachment(index) {
			if (this.readonly) return
			this.attachments = this.attachments.filter((_, i) => {
				return i !== index
			})
		},
		onResetChanges() {
			if (this.readonly) return
			this.attachments = this.attachments.filter((attach) => {
				return !attach.file
			})
		},
		onAttchment(attachment) {
			if (this.readonly) return
			// Ensure array
			const attachments = Array.isArray(attachment)
				? attachment
				: [attachment]

			if (this.isMultiple) {
				// Finding unique attachments
				const newAttachments = attachments.filter((attachment) => {
					return !this.attachments.some((attach) => {
						if (!attach.file) return attach.src === attachment.src
						return attach.file.name === attachment.file.name
					})
				})

				// Pushing new attachments
				this.attachments.push(...newAttachments)
			}
			// Single
			else {
				this.attachments = attachments
			}

			// Fire event to parent
			this.$emit('change', this.attachments)
			this.dragging = false
		},
		onDrop(e) {
			if (this.readonly) return
			const event = {
				target: {
					files: e?.dataTransfer?.files || [],
				},
			}
			return onFilePicked(
				event,
				{
					accept: this.accept,
					multiple: this.isMultiple,
				},
				this.onAttchment
			)
		},
		onOpenFilePicker() {
			if (this.readonly) return
			return openFilePicker(
				{
					accept: this.accept,
					multiple: this.isMultiple,
				},
				this.onAttchment
			)
		},
	},
}
</script>
