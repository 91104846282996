<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="18.868"
		height="20.977"
		viewBox="0 0 18.868 20.977"
	>
		<g id="location-1-svgrepo-com" transform="translate(-3 -2)">
			<path
				id="Path_34281"
				data-name="Path 34281"
				d="M20.868,11.434c0,4.658-3.776,8.434-8.434,10.543C7.776,19.868,4,16.092,4,11.434a8.434,8.434,0,1,1,16.868,0Z"
				transform="translate(0 0)"
				fill="none"
				stroke="#fff"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
			/>
			<path
				id="Path_34282"
				data-name="Path 34282"
				d="M15.326,11.163A3.163,3.163,0,1,1,12.163,8,3.163,3.163,0,0,1,15.326,11.163Z"
				transform="translate(0.271 0.271)"
				fill="none"
				stroke="#fff"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
			/>
		</g>
	</svg>
</template>
<script>
export default {
	name: 'IconMarker',
}
</script>
