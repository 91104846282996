import moment from 'moment'
import Cookies from 'js-cookie'

import { isFile, TIME } from 'vuelpers'
import { ROLE, _serverBaseURL } from './consts'

export const isImage = (url) => {
	return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
}

export const getImage = (src, size = '400x400') => {
	if (!src) return `https://via.placeholder.com/${size}`
	if (src.startsWith('blob')) return src
	return `${_serverBaseURL}/storage/${src}`
}

export const toAttachment = (v, file = {}, force = false) => {
	if (!v && !force) return
	return {
		...(v || {}),
		src: getImage(v?.attachedLink),
		file: {
			...file,
			size: v?.attachedSize,
			name: v?.attachmentName,
		},
	}
}

export const getFile = (v) => (isFile(v) ? v : null)

export const useScroll = (el = window) => {
	let lastScrollTime = null
	let onScrollCallback = null

	const onScrollFn = (event) => {
		lastScrollTime = Date.now()
		onScrollCallback && onScrollCallback(event)
	}

	el.addEventListener('scroll', onScrollFn)

	return {
		isScrolling() {
			if (!lastScrollTime) return false
			return Date.now() < lastScrollTime + 500
		},
		onScroll(callback) {
			onScrollCallback = callback
		},
		removeListener() {
			if (!el) return
			el.removeEventListener('scroll', onScrollFn)
		},
	}
}

export const getDefaultRoute = (role) => {
	return {
		[ROLE.BUYER]: '/',
		[ROLE.ADMIN]: '/admin',
		[ROLE.SUPPLIER]: '/supplier',
	}[role]
}

/**
 * *********************************************************
 * |||| This will return a callback function to import  ||||
 * |||| specified Vue component inside views folder     ||||
 * *********************************************************
 * @param {String} name - ViewName or child-folder/ChildView
 * @returns {Function} () => import('@/views/ViewName.vue')
 */
export const view = (name) => () => import(`@/views/${name}.vue`)

/**
 * *********************************************************
 * |||| This will return a callback function to import  ||||
 * |||| specified Vue component inside layouts folder   ||||
 * *********************************************************
 * @param {String} name - LayoutName
 * @returns {Function} () => import('@/layouts/LayoutName.vue')
 */
export const layout = (name) => () => import(`@/layouts/${name}.vue`)

/**
 * *********************************************************
 * |||| Fix the type of any variable                    ||||
 * *********************************************************
 * @param {any} value - 'true' -> true | '18' -> 18 | 'null' -> null
 */
export const fixType = (value) => {
	const types = {
		true: true,
		false: false,
		null: null,
		undefined: undefined,
	}
	return +value ? +value : value in types ? types[value] : value
}

export const cookies = {
	set(...cookies) {
		if (typeof cookies !== 'object')
			throw new Error('Cookies have to be an object or array.')
		cookies.forEach(
			({ key, value, secure = false, expires = Date.now() + TIME.WEEK }) => {
				Cookies.set(key, value, {
					expires,
					secure,
				})
			}
		)
	},
	get(...keys) {
		return keys.reduce((acc, key) => {
			acc[key] = fixType(Cookies.get(key))
			return acc
		}, {})
	},
	remove(...keys) {
		keys.forEach((key) => {
			Cookies.remove(key)
		})
	},
}

export const formatDate = (date) => {
	let d = date ? new Date(date) : new Date(),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear()
	if (month.length < 2) month = '0' + month
	if (day.length < 2) day = '0' + day
	return [year, month, day].join('-')
}

/**
 *
 * @param {number} time - How much time have to sleep
 * @param {string} unit - Unit of time ['s'-seconds,'m'-minute,'h'-houre]
 */
export const sleep = (time, unit) =>
	new Promise((resolve) => {
		const ms =
			unit === 's'
				? time * 1000
				: unit === 'm'
				? time * 60 * 1000
				: unit === 'h'
				? time * 60 * 60 * 1000
				: time
		setTimeout(() => resolve(), ms)
	})

export const to12Hour = (time) => {
	// Check correct time format and split into components
	time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
		time,
	]

	if (time.length > 1) {
		// If time format correct
		time = time.slice(1) // Remove full string match value
		time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
		time[0] = +time[0] % 12 || 12 // Adjust hours
	}
	return time.join('') // return adjusted time or original string
}

/**
 * *********************************************************
 * // Difference between dates
 * *********************************************************
 */
export const diff = {
	day(a, b = Date.now()) {
		return moment(new Date(a).getTime()).diff(b, 'days')
	},
}

export const numberWithCommas = (x) => {
	if (!x) return x
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
