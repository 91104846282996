<template>
	<v-menu v-model="menu" :disabled="readonly" offset-y>
		<template #activator="{ on, attrs }">
			<slot name="activator" :on="on" :attrs="attrs">
				<div>
					<div
						v-on="on"
						v-bind="attrs"
						:class="{ 'tw-h-14': !multiple }"
						:style="multiple ? 'min-height:56px' : ''"
						class="tw-rounded tw-border tw-flex tw-items-center tw-px-3 tw-relative tw-flex-wrap"
					>
						<template v-if="hasSelected">
							<slot v-if="!multiple" name="selection" :item="mItem">
								<div class="tw-text-base tw-text-black tw-flex-none">
									{{ get(mItem, itemText) }}
								</div>
							</slot>

							<template v-else>
								<div v-for="(item, index) in mItem" :key="index">
									<slot name="selection" :item="item" :index="index">
										<div
											class="tw-text-base tw-text-black tw-flex-none"
										>
											{{ get(item, itemText) }},
										</div>
									</slot>
								</div>
							</template>
						</template>

						<input
							v-model="mSearchInput"
							:disabled="readonly"
							:class="{ 'tw-h-14': !multiple }"
							:placeholder="!hasSelected ? placeholder : ''"
							type="text"
							ref="searchInputRef"
							class="tw-text-base tw-text-black tw-ml-1 focus:tw-outline-none tw-flex-1"
							@input="onInputSearch"
							@keyup="onKeyupSearch"
						/>

						<v-icon
							class="tw-absolute tw-top-1/2 tw-transform tw--translate-y-1/2 tw-right-3"
						>
							mdi-chevron-down
						</v-icon>
					</div>
					<v-progress-linear
						v-if="loading"
						indeterminate
						color="primary"
					/>
					<div
						v-if="mErrorMessages.length"
						class="error--text tw-text-xs tw-mt-1 tw-px-4 tw-truncate"
					>
						{{ mErrorMessages[0] }}
					</div>
				</div>
			</slot>
		</template>
		<v-card :loading="isSearching" max-height="300">
			<v-card-text v-show="!filteredItems.length">
				<div class="tw-text-sm tw-text-black">No data available</div>
			</v-card-text>

			<v-list v-if="filteredItems.length > 0">
				<v-list-item-group :value="vRawValue" :multiple="multiple">
					<v-list-item
						v-for="(item, index) in filteredItems"
						:key="index"
						:value="get(item, itemValue)"
						@click="onClickItem(item, index)"
					>
						<slot name="item" :item="item" :index="index">
							<v-list-item-title>
								{{ get(item, itemText) }}
							</v-list-item-title>
						</slot>
					</v-list-item>
				</v-list-item-group>
			</v-list>

			<div
				v-intersect="onIntersect"
				class="pb-3 tw-flex tw-items-center tw-justify-center tw-bg-white"
			>
				<v-progress-circular v-if="loading" indeterminate color="primary" />
			</div>
		</v-card>
	</v-menu>
</template>

<script>
import { get } from 'lodash'
import { omitEmpties } from 'vuelpers'
export default {
	name: 'BaseAutocomplete',
	props: {
		value: {},
		multiple: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array,
			default: () => [],
		},
		itemText: {
			type: String,
			default: 'text',
		},
		itemValue: {
			type: String,
			default: 'value',
		},
		returnObject: {
			type: Boolean,
			default: false,
		},
		rules: {
			type: Array,
			default: () => [],
		},
		page: {
			type: Number,
			default: 1,
		},
		perPage: {
			type: Number,
			default: 10,
		},
		total: {
			type: Number,
			default: 0,
		},
		onFetch: {
			type: Function,
			default: () => {},
		},
		loading: {
			type: Boolean,
			default: false,
		},
		searchInput: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		filter: {
			type: Function,
			default: null,
		},
		formRef: {
			type: Object,
			default: null,
		},
	},
	data: () => ({
		menu: false,
		mSearchInput: '',
		mSearchInputEmptyCount: 0,
		mErrorMessages: [],
		isSearching: false,
		isSearchPending: false,
	}),
	watch: {
		formRef: {
			immediate: true,
			handler(formRef) {
				formRef?.register(this)
			},
		},
	},
	beforeDestroy() {
		this.formRef?.unregister(this)
	},
	computed: {
		mValue: {
			get() {
				if (this.multiple && !Array.isArray(this.value)) {
					return [this.value].filter(Boolean)
				}
				if (!this.multiple && Array.isArray(this.value)) {
					return this.value[0]
				}
				return this.value
			},
			set(v) {
				this.$emit('input', v)
				this.$nextTick(() => {
					this.validate()
				})
			},
		},
		mItem() {
			if (this.multiple) {
				if (this.returnObject) return this.mValue
				return this.mValue.map(this.getItemByValue).filter(Boolean)
			}

			if (this.returnObject) return this.mValue
			return this.getItemByValue(this.mValue)
		},
		vRawValue() {
			if (!this.returnObject) return this.mValue
			if (!this.multiple) return this.get(this.mValue, this.itemValue)
			return this.mValue.map((v) => this.get(v, this.itemValue))
		},
		hasSelected() {
			if (!this.multiple) return !!this.mValue
			return this.mValue.length > 0
		},
		itemValues() {
			return this.items.map((item) => {
				return get(item, this.itemValue)
			})
		},
		mItems() {
			if (!this.returnObject) return this.items

			const isExist = (item) => {
				return this.itemValues.includes(this.get(item, this.itemValue))
			}

			if (!this.multiple) {
				if (!this.mValue || isExist(this.mValue)) return this.items
				return [this.mValue, ...this.items]
			}

			return this.mValue.reduce(
				(items, value) => {
					if (isExist(value)) return items
					return [value, ...items]
				},
				[this.items]
			)
		},
		filteredItems() {
			if (!this.mSearchInput) return this.mItems
			return this.mItems.filter((item) => {
				if (this.filter) {
					return this.filter(
						item,
						this.mSearchInput,
						this.get(item, this.itemText)
					)
				}
				return this.get(item, this.itemText)
					.toLowerCase()
					.includes(this.mSearchInput.toLowerCase())
			})
		},
		isFetchedAll() {
			return this.total <= this.page * this.perPage
		},
	},
	methods: {
		get,
		reset() {
			this.mErrorMessages = []
		},
		validate() {
			this.mErrorMessages = this.rules
				.map((rule) => rule(this.mValue))
				.filter((res) => res !== true)
			return !this.mErrorMessages.length
		},
		onClearSearchInput() {
			this.mSearchInput = ''
			this.$refs.searchInputRef.value = ''
			this.$refs.searchInputRef.focus()
		},
		getItemByValue(value) {
			return this.items.find((item) => {
				return value === this.get(item, this.itemValue)
			})
		},
		isSelectedAlready(item) {
			const itemValue = this.get(item, this.itemValue)
			if (this.multiple) {
				if (!this.returnObject) return this.mValue.includes(itemValue)
				return this.mValue.some((v) => {
					return itemValue === this.get(v, this.itemValue)
				})
			}
			if (!this.returnObject) return itemValue === this.mValue
			return itemValue === this.get(this.mValue, this.itemValue)
		},
		onClickItem(item) {
			if (this.readonly || this.isSelectedAlready(item)) return

			const value = !this.returnObject
				? this.get(item, this.itemValue)
				: item

			if (!this.multiple) this.mValue = value
			else this.mValue.push(value)

			this.$emit('change', this.mValue)
			this.onClearSearchInput()
		},
		onIntersect(_, __, isIntersecting) {
			if (!isIntersecting || this.isFetchedAll) return
			this.onFetch(
				omitEmpties({
					q: this.mSearchInput,
					page: this.page + 1,
					perPage: this.perPage,
				})
			)
		},
		onKeyupSearch(event) {
			if (this.readonly) return
			if ([38, 40, 13].includes(event.keyCode)) return

			const value = event.target.value
			if (value) return (this.mSearchInputEmptyCount = 0)

			this.mSearchInputEmptyCount += 1
			if (this.mSearchInputEmptyCount <= 1) return

			if (this.multiple) this.mValue.pop()
			else this.onClickItem(null)

			this.mSearchInputEmptyCount = 0
		},
		async onSearch(params = {}) {
			if (this.isSearching) {
				this.isSearchPending = true
				return
			}

			this.isSearching = true
			await this.onFetch(
				omitEmpties({
					page: this.page,
					q: this.mSearchInput,
					perPage: this.perPage,
					...params,
				})
			)
			this.isSearching = false

			if (this.isSearchPending) {
				this.isSearchPending = false
				return this.onSearch(params)
			}
		},
		onInputSearch(event) {
			if (this.readonly) return
			if (!this.menu) this.menu = true

			this.onSearch({ page: 1 })
			this.$emit('update:searchInput', event.target.value)
		},
	},
}
</script>
