import { Buyer } from '../../api'
import { initialList } from '../store.helpers'
import { createGetters, createMutations, handleAction } from 'vuelpers'

const initialState = () => {
	return {
		buyer: {},
		buyers: initialList(),
		activeBuyers: initialList(),
		pendingBuyers: initialList(),
		rejectedBuyers: initialList(),
	}
}

const state = initialState()

const getters = createGetters(
	'buyer',
	'buyers',
	'activeBuyers',
	'pendingBuyers',
	'rejectedBuyers'
)

const mutations = {
	...createMutations('SET', 'PUSH', 'UNSHIFT', 'DELETE', 'UPDATE', 'MERGE'),
}

const actions = {
	changeBuyerStatus({ dispatch, state }, payload) {
		return handleAction(Buyer.changeStatus(payload), () => {
			const payload = {
				page: state.buyers.currentPage,
				perPage: state.buyers.perPage,
			}
			dispatch('getActiveBuyers', payload)
			dispatch('getRejectedBuyers', payload)
		})
	},
	deleteBuyer({ commit, dispatch, state }, payload) {
		return handleAction(Buyer.delete(payload), () => {
			const paths = [
				'buyers',
				'activeBuyers',
				'pendingBuyers',
				'rejectedBuyers',
			]
			paths.forEach((path) => commit('DELETE', [`${path}.data`, payload]))
			dispatch('getActiveBuyers', {
				page: state.buyers.currentPage,
				perPage: state.buyers.perPage,
			})
		})
	},
	updateBuyer(_, payload = {}) {
		return handleAction(Buyer.update(payload), (res) => {
			console.log('updateBuyer', res)
		})
	},
	buyerApprove(_, payload = {}) {
		return handleAction(Buyer.review(payload), () => {
			// commit('SET', { buyers: res.buyers })
			console.log('supplier approve')
		})
	},
	buyerProfileEdit(_, payload = {}) {
		return handleAction(Buyer.update(payload), (res) => {
			console.log(res)
		})
	},
	getBuyersByStatus({ commit }, payload) {
		const path = `${payload.status.split(',').shift()}Buyers`
		commit('SET', {
			[path]: (v) => ({
				...v,
				isLoading: !v.isLoaded,
				isRefreshing: v.isLoaded,
			}),
		})
		return handleAction(Buyer.get(payload), (res) => {
			commit('SET', {
				[path]: (v) => ({
					...v,
					...res.buyers,
					isLoaded: true,
					isLoading: false,
					isRefreshing: false,
				}),
			})
		})
	},
	getRejectedBuyers({ dispatch }, payload = {}) {
		return dispatch('getBuyersByStatus', {
			...payload,
			status: 'rejected',
		})
	},
	getPendingBuyers({ dispatch }, payload = {}) {
		return dispatch('getBuyersByStatus', {
			...payload,
			status: 'pending',
		})
	},
	getActiveBuyers({ dispatch }, payload = {}) {
		return dispatch('getBuyersByStatus', {
			...payload,
			status: 'active,deactive',
		})
	},
	getBuyer({ commit, state: { buyers } }, payload) {
		console.log('getBuyer', payload)

		commit('SET', {
			buyers: (v) => ({
				...v,
				isLoading: !buyers.isLoaded,
				isRefreshing: buyers.isLoaded,
			}),
		})

		return handleAction(Buyer.get(payload), (res) => {
			console.log('res', res)
			commit('SET', {
				buyers: (v) => ({
					...v,
					...res.buyers,
					isLoaded: true,
					isLoading: false,
					isRefreshing: false,
				}),
			})
		})
	},
	getSingleBuyer({ commit }, payload = {}) {
		return handleAction(Buyer.getById(payload), (res) => {
			commit('SET', { buyer: res.buyer })
			console.log(res)
			console.log('get buyer')
		})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
