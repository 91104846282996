import API from '../../api'

import { initialList } from '../store.helpers'
import { createGetters, createMutations, handleAction } from 'vuelpers'
import { omit } from 'lodash'

const initialState = () => {
	return {
		tags: [],
		sizes: [],
		colors: [],
		currencies: [],
		currentProductId: null,
		offerPreview: {
			offer: null,
			modal: false,
		},
		productPreview: {
			modal: false,
			product: null,
		},
		products: initialList(),
		trialProducts: initialList(),
		favoriteProducts: initialList(),
		topSellingProducts: initialList(),
		todayBestPickProducts: initialList(),
		banners: [
			{
				id: 1,
				attachments: [
					{
						attachedLink:
							'https://media.istockphoto.com/vectors/promo-web-banner-for-black-friday-sale-vector-id868916538',
					},
				],
			},
			{
				id: 2,
				attachments: [
					{
						attachedLink:
							'https://media.istockphoto.com/vectors/promo-web-banner-for-black-friday-sale-vector-id868919560',
					},
				],
			},
			{
				id: 3,
				attachments: [
					{
						attachedLink:
							'https://media.istockphoto.com/vectors/black-friday-sale-banner-vector-id1177932424',
					},
				],
			},
		],
	}
}

const state = initialState()

const getters = {
	...createGetters(
		'tags',
		'sizes',
		'colors',
		'banners',
		'currencies',
		'products',
		'trialProducts',
		'productPreview',
		'offerPreview',
		'favoriteProducts',
		'topSellingProducts',
		'todayBestPickProducts'
	),
	$product(state) {
		return state.products.data.find(product => {
			return product.id == state.currentProductId
		})
	},
}

const mutations = {
	...createMutations('SET', 'PUSH', 'UNSHIFT', 'DELETE', 'UPDATE', 'MERGE'),
}

const actions = {
	setProductsState({ commit }, payload) {
		commit('SET', payload)
	},
	createProduct({ commit }, payload) {
		// commit('SET', { 'products.isRefreshing': true })
		return handleAction(API.products.post(payload), res => {
			commit('UNSHIFT', ['products.data', res.product])
			// commit('SET', { 'products.isRefreshing': false })
		})
	},
	bulkUploadProducts(_, payload) {
		return handleAction(API.products.bulkUpload(payload))
	},
	deleteProduct({ commit }, payload = {}) {
		// commit('SET', { 'products.isRefreshing': true })
		return handleAction(API.products.delete(payload), () => {
			commit('DELETE', ['products.data', payload])
			// commit('SET', { 'products.isRefreshing': false })
		})
	},
	updateProduct({ commit }, payload = {}) {
		// commit('SET', { 'products.isRefreshing': true })
		return handleAction(API.products.patch(payload), res => {
			commit('UPDATE', ['products.data', res.product])
			// commit('SET', { 'products.isRefreshing': false })
		})
	},
	getProduct({ commit }, payload = {}) {
		commit('SET', { 'products.isRefreshing': true })

		return handleAction(
			API.products.getById(payload.id, payload.query),
			res => {
				console.log(res)

				commit('UNSHIFT', ['products.data', res.product])
				commit('SET', { 'products.isRefreshing': false })
			}
		)
	},
	getProducts({ commit, state: { products } }, payload = {}) {
		// LOADERS
		commit('SET', { 'products.isRefreshing': true })
		if (!products.data.length) {
			commit('SET', { 'products.isLoading': true })
		}

		const paginate = payload.paginate || false
		delete payload.paginate

		return handleAction(API.products.get(payload), res => {
			commit('SET', {
				products: v => ({
					...v,
					...(!paginate ? res.products : omit(res.products, 'data')),
					isLoaded: true,
					isLoading: false,
					isRefreshing: false,
				}),
			})
			if (paginate) {
				commit('MERGE', ['products.data', res.products.data, 'id', 'push'])
			}
		})
	},
	getTiralProducts({ commit }, payload = {}) {
		return handleAction(API.products.getTrial(payload), res => {
			commit('SET', { trialProducts: res.trialProducts })
		})
	},
	getProductsDependency({ commit }, payload) {
		return handleAction(API.products.getDependencies(payload), res => {
			commit('SET', {
				tags: res.attributes.tag,
				sizes: res.attributes.size,
				colors: res.attributes.color,
				currencies: res.currencies,
			})
			commit('category/MERGE', ['categories.data', res.categories || []], {
				root: true,
			})
		})
	},
	resetState({ commit }) {
		commit('SET', initialState())
	},
	getFavoriteProducts({ commit }, payload) {
		return handleAction(API.products.favorite(payload), res => {
			commit('SET', {
				favoriteProducts: res.favoriteProducts,
			})
		})
	},
	getTopSellingProducts({ commit }, payload) {
		return handleAction(API.products.topSelling(payload), res => {
			commit('SET', { topSellingProducts: res.topSellingProducts })
		})
	},
	getTodayBestPickProducts({ commit }, payload) {
		return handleAction(API.products.todayBestPick(payload), res => {
			commit('SET', { todayBestPickProducts: res.todayBestPickupsProducts })
		})
	},
	addToFavorite({ commit, state, rootState }, payload) {
		return handleAction(API.products.addFavorite(payload), () => {
			const product = state.products.data.find(
				product => product.id == payload
			)
			product &&
				product.favoriteFromUsers.push({
					id: rootState.auth.currentUser.id,
				})
			commit('UPDATE', ['topSellingProducts.data', product])
		})
	},
	removeFavorite({ commit, state, rootState }, payload) {
		return handleAction(API.products.removeFavorite(payload), () => {
			const product = state.products.data
				.concat(state.favoriteProducts.data)
				.find(product => product.id == payload)

			if (!product) return

			product.favoriteFromUsers = product.favoriteFromUsers.filter(
				product => product.id != rootState.auth.currentUser.id
			)

			commit('UPDATE', ['products.data', product])
			commit('DELETE', ['favoriteProducts.data', product.id])
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}
