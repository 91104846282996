<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			id="Group_40467"
			data-name="Group 40467"
			xmlns="http://www.w3.org/2000/svg"
			width="16.2"
			height="15.725"
			viewBox="0 0 16.2 15.725"
		>
			<path
				id="Path_34292"
				data-name="Path 34292"
				d="M0,4.844V20.569H16.2V4.844Zm5.658.982h4.884V9.892L8.1,8.188l-2.442,1.7Zm9.56,13.761H.982V5.826H4.677v5.949L8.1,9.385l3.424,2.39V5.826h3.695Z"
				transform="translate(0 -4.844)"
			/>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconPackage',
}
</script>
