<template>
	<v-dialog
		v-model="mValue"
		v-bind="$attrs"
		v-on="$listeners"
		scrollable
		persistent
		max-width="768"
		content-class="tw-rounded-2xl tw-shadow-lg"
	>
		<template #activator="{ on, attrs }">
			<slot name="activator" :attrs="attrs" :on="on" />
		</template>

		<v-form
			v-model="isValid"
			:loading="isLoading"
			:readonly="readonly"
			ref="formRef"
			lazy-validation
			@submit.prevent="onSubmit"
		>
			<v-card>
				<base-drop-zone
					v-model="mUser.avatar"
					hide-list
					accept="image/*"
					class="tw-mt-12 tw-mb-6 tw-w-min tw-mx-auto"
					@input="errors.avatar = []"
				>
					<template #placeholder="{ on, attrs }">
						<v-badge
							:content="_.capitalize($currentUser.role)"
							bottom
							bordered
							offset-x="32"
							offset-y="32"
						>
							<div
								v-on="on"
								v-bind="attrs"
								class="tw-rounded-full tw-border-4 tw-border-dashed pa-1"
							>
								<v-avatar
									size="128"
									color="primary"
									class="white--text"
								>
									<v-img
										v-if="mUser.avatar"
										:alt="$currentUser.name"
										:src="mUser.avatar.src"
									/>
									<span v-else class="text-h4">
										{{ $utils.useInitial($currentUser.name) }}
									</span>
								</v-avatar>
							</div>
						</v-badge>
						<div
							v-if="errors.avatar"
							class="tw-text-sm error--text tw-text-center"
						>
							{{ errors.avatar[0] }}
						</div>
					</template>
				</base-drop-zone>

				<v-card-text class="px-6 tw-pb-0">
					<div class="tw-grid tw-grid-cols-2 tw-gap-6">
						<BaseTextField
							v-model="mUser.name"
							:label="$t('name')"
							:error-messages="errors.name"
							:rules="[rules.required($t('name'))]"
							label-icon="mdi-account-outline"
							name="name"
							class="tw-col-span-2"
							placeholder="Write your name..."
							@input="errors.name = []"
						/>
						<BaseTextField
							v-model="mUser.email"
							:label="$t('emailAddress')"
							:error-messages="errors.email"
							:rules="[rules.required($t('email')), rules.email]"
							name="email"
							type="email"
							label-icon="mdi-email-outline"
							placeholder="Write your email..."
							@input="errors.email = []"
						/>
						<BaseTextField
							v-model="mUser.phone"
							:label="$t('phoneNumber')"
							:error-messages="errors.phone"
							:rules="[rules.required($t('phone'))]"
							type="tel"
							name="phone"
							label-icon="mdi-phone-outline"
							placeholder="Write your phone..."
							@input="errors.phone = []"
						/>
						<BaseTextField
							v-model="mUser.iqamaCardNumber"
							:label="$t('card-number-or-iqama')"
							:error-messages="errors.iqamaCardNumber"
							:rules="[rules.required($t('number'))]"
							type="number"
							label-icon="mdi-card-account-details-outline"
							name="iqamaCardNumber"
							placeholder="#### #### #### ####"
							@input="errors.iqamaCardNumber = []"
						/>
						<BaseDatePicker
							v-model="mUser.iqamaCardExpDate"
							:label="$t('iqamaCardExpDate')"
							:height="$vuetify.breakpoint.lgAndUp ? '60px' : ''"
							:error-messages="errors.iqamaCardExpDate"
							:rules="[rules.required($t('date'))]"
							label-icon="mdi-calendar-range-outline"
							name="iqamaCardExpDate"
							placeholder="YYYY-MM-DD"
							input-class="custom-outlined placeholder-padding tw-rounded-md"
							@input="errors.iqamaCardExpDate = []"
						/>

						<div class="tw-col-span-2">
							<div class="tw-flex tw-items-center tw-space-s-3 tw-mb-4">
								<v-icon color="#D5D5D5"> mdi-attachment </v-icon>
								<span class="tw-font-medium tw-text-black">
									{{ $t('copyOfCard') }}
								</span>
							</div>
							<BaseUpload
								v-model="mUser.copyOfCard"
								:rules="[rules.required($t('copyOfCard'))]"
								:error-messages="errors.file"
								title="adminContracts.UploadCardCopy"
								subtitle="max500Image"
								table-class="tw-mt-4"
								@input="errors.file = []"
							/>
						</div>
					</div>
				</v-card-text>

				<v-card-actions
					class="tw-pt-0 tw-mt-12 tw-space-x-6 tw-px-6 tw-pb-6"
				>
					<v-btn
						text
						outlined
						height="58"
						color="primary"
						class="tw-flex-1 tw-rounded-lg"
						@click="onCancelUpdate"
					>
						<span
							class="tw-text-lg tw-font-medium tw-capitalize tw-text-72"
						>
							{{ $t('actions.cancel') }}
						</span>
					</v-btn>
					<v-btn
						:loading="isLoading"
						depressed
						height="58"
						type="submit"
						color="primary"
						class="tw-flex-1 tw-font-medium tw-rounded-lg"
					>
						<span
							class="tw-text-lg tw-font-medium tw-capitalize tw-text-white"
						>
							{{ $t('actions.update') }}
						</span>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import api from '../../api'

import { toFormData } from 'vuelpers'
import { mapActions, mapGetters } from 'vuex'
import { getFile, toAttachment } from '../../helpers'
import { createFormMixin } from '../../mixins/form-mixin'

export default {
	name: 'DialogFormCurrentUser',
	mixins: [
		createFormMixin({
			rules: ['required', 'email'],
		}),
	],
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		user: {
			type: Object,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isValid: false,
			isLoading: false,

			mUser: {},
			errors: {},
		}
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		mValue: {
			get() {
				return this.value
			},
			set(v) {
				this.$emit('input', v)
			},
		},
	},
	methods: {
		...mapActions('auth', ['updateCurrentUser', 'fetchProfile']),
		initialUser(v = this.$currentUser) {
			return this._.cloneDeep({
				id: v?.id,
				name: v?.name,
				email: v?.email,
				phone: v?.phone,
				iqamaCardNumber: v?.iqamaCardNumber,
				avatar: toAttachment(v?.avatar),
				copyOfCard: toAttachment(v?.copyOfCard),
				iqamaCardExpDate: v?.iqamaCardExpDate
					? this.$m(v.iqamaCardExpDate).format('YYYY-MM-DD')
					: null,
			})
		},
		onCancelUpdate() {
			this.mValue = false
		},
		async onSubmit() {
			if (!this.$refs.formRef.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}

			this.isLoading = true
			const [error] = await api.users.update(
				toFormData(
					{
						...this.mUser,
						copyOfCard: null,
						role: this.$currentUser.role,
						file: getFile(this.mUser.copyOfCard?.file),
						avatar: getFile(this.mUser.avatar?.file),
						buyerId: this._.get(this.$currentUser, 'buyer.0.id'),
						supplierId: this._.get(this.$currentUser, 'supplier.0.id'),
						iqamaCardExpDate: this.$m(this.mUser.iqamaCardExpDate).format(
							'YYYY-MM-DD HH:mm:ss'
						),
					},
					{ convertCase: 'snake_case' }
				)
			)
			this.isLoading = false

			if (error) {
				this.errors = error.errors || {}
				this.$utils.scrollIntoError(this)
				if (!error.errors && error.message) {
					this.$toast.error(error.message)
				}
				return
			}

			this.fetchProfile()
			this.mValue = false
		},
	},
	watch: {
		mValue: {
			immediate: true,
			handler(v) {
				v && (this.mUser = this.initialUser())
			},
		},
	},
}
</script>
