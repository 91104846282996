<template>
	<div>
		<v-overlay color="#ffffff" class="tw-z-50" v-if="$globalLoading">
			<v-progress-circular
				:size="90"
				:width="8"
				color="#722432"
				indeterminate
			/>
		</v-overlay>
		<router-view />

		<base-confirmation
			v-model="$root.confirmation.handler"
			:title="$root.confirmation.title"
			@done="$root.confirmation.handler = null"
		>
			{{ $root.confirmation.message }}
		</base-confirmation>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'App',
	data() {
		return {
			loading: true,
		}
	},
	computed: {
		...mapGetters('settings', ['$globalLoading']),
	},
}
</script>
