<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19.637"
			height="16.892"
			viewBox="0 0 19.637 16.892"
		>
			<g
				id="store-svgrepo-com_2_"
				data-name="store-svgrepo-com (2)"
				transform="translate(-1.108 -2.504)"
			>
				<path
					id="Path_34275"
					data-name="Path 34275"
					d="M21.119,8.562l-1.366-4.1A.683.683,0,0,0,19.07,4H4.045a.683.683,0,0,0-.683.464L2,8.562A.813.813,0,0,0,2,8.78v4.1a.683.683,0,0,0,.683.683h.683V20.39H4.728V13.561h4.1V20.39H19.753V13.561h.683a.683.683,0,0,0,.683-.683V8.78a.813.813,0,0,0,0-.219ZM18.387,19.024h-8.2V13.561h8.2Zm1.366-6.829H17.021V9.463H15.656v2.732H12.241V9.463H10.875v2.732H7.46V9.463H6.094v2.732H3.362V8.889L4.537,5.365H18.579l1.175,3.524Z"
					transform="translate(-0.631 -1.244)"
					stroke="#fff"
					stroke-width="0.5"
				/>
			</g>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconStore',
}
</script>
