<template>
	<div class="tw-w-full tw-px-0.5 tw-group tw-relative">
		<div
			role="button"
			class="tw-relative tw-rounded-22px tw-overflow-hidden tw-mb-4"
			@click="$emit('onClick', productInfo.id)"
		>
			<v-img
				:src="
					$image($get(productInfo, 'attachments.0.attachedLink'), '215')
				"
				alt
				center
				cover
				ref="imgRef"
				class="tw-w-full tw-h-215px tw-rounded-22px tw-cursor-pointer tw-bg-gray-100"
				@mouseover="onHover"
				@mouseleave="onLeave"
				@error="$onImgError({ ref: $refs.imgRef })"
			/>

			<div
				class="tw-opacity-0 tw-invisible group-hover:tw-opacity-100 group-hover:tw-visible tw-transition-all tw-duration-300 tw-h-96 tw-w-96 tw-absolute tw--top-28 tw--right-28 tw-z-10 tw-rounded-full tw-bg-gradient-to-bl tw-from-black tw-via-transparent tw-to-transparent"
			/>
		</div>
		<v-btn
			icon
			width="20"
			height="20"
			class="tw-absolute tw-top-4 tw-right-4 tw-z-20"
			@click="toFavorite(productInfo.id)"
		>
			<icon-star
				size="20"
				stroke="1.5"
				class="tw-text-white"
				:outlined="!isFavorite"
			/>
		</v-btn>
		<div class="tw-flex tw-space-s-2 tw-justify-between tw-items-start">
			<span
				class="tw-h-12 tw-font-medium tw-overflow-hidden tw-text-black tw-capitalize"
			>
				{{ formatProductName(productInfo.name) }}
			</span>
			<div
				class="tw-flex tw-space-s-2 tw-items-center tw-whitespace-nowrap tw-mt-0.5"
			>
				<span
					:class="
						productInfo.status == 'in-store' ? 'tw-bg-69' : 'tw-bg-AB'
					"
					class="tw-block tw-w-1.5 tw-h-1.5 tw-rounded-full"
				/>
				<span class="tw-text-11px tw-capitalize">
					{{ productInfo.status }}
				</span>
			</div>
		</div>
		<div
			class="tw-flex tw-justify-between tw-items-center tw-space-s-4 tw-mt-1.5"
		>
			<!-- representativeName -->
			<span class="tw-text-11px tw-truncate">
				{{ $get(productInfo, 'supplier.representativeName') }}
			</span>

			<div
				class="tw-flex-none tw-flex tw-items-center tw-space-x-1 tw-ml-auto"
			>
				<!-- Original Price -->
				<div
					class="tw-flex tw-space-s-2 tw-items-center tw-font-Rubik-text"
					:class="[
						offerPrice
							? 'tw-line-through tw-font-normal tw-opacity-80 tw-text-sm'
							: 'tw-font-medium',
					]"
				>
					<template v-if="!offerPrice">{{ currency }}</template>
					{{ formatPrice(productInfo.price) }}
				</div>

				<!-- Offer Price -->
				<div
					v-if="offerPrice"
					class="tw-flex tw-space-s-2 tw-items-center tw-font-Rubik-text tw-font-medium"
				>
					{{ currency }} {{ formatPrice(offerPrice) }}
				</div>
			</div>
		</div>
		<div v-if="showActions" class="tw-flex tw-justify-between">
			<div
				class="tw-rounded-md tw-justify-between tw-w-full tw-flex tw-items-center tw-mt-3"
			>
				<div class="tw-flex tw-items-center tw-bg-F8 tw-rounded-md">
					<base-quantity
						:max="maxQuantity"
						:disabled="productInfo.status !== 'in-store'"
						v-model="quantity"
						@change="onChangeQuantity"
					/>
				</div>
				<v-btn
					dark
					tile
					depressed
					style="background-color: #f5ca15"
					class="tw-rounded-md tw-p-0 tw-m-0 tw-h-8 tw-min-w-10 cart-btn"
					:color="'#F5CA15'"
					:loading="isUpdating"
					:disabled="productInfo.status !== 'in-store'"
					@click="onAddToCart"
				>
					<v-icon size="15">mdi mdi-cart</v-icon>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import { truncate } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import { numberWithCommas } from '../../helpers'

export default {
	name: 'Productcard',
	props: {
		productInfo: Object,
		isGreyQuantityBg: {
			type: Boolean,
			default: true,
		},
		showActions: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			quantity: 1,
			hoverTimeOut: null,
			isUpdating: false,
		}
	},
	computed: {
		...mapGetters('cart', ['$cart']),
		...mapGetters('auth', ['$currentUser']),
		offerPrice() {
			return (this.productInfo.offers ?? []).find((offer) => {
				return offer.isSingleProductOffer
			})?.finalPrice
		},
		maxQuantity() {
			if (!this.productInfo || !this.productInfo.isSpecifiedQuantity) {
				return Infinity
			}
			return +this.productInfo.quantity
		},
		currency() {
			return this.productInfo?.currency?.currency ?? 'SAR'
		},
		isInCart() {
			return !!this.cartItem
		},
		cartItem() {
			return this.$cart.products.data.find((cartItem) => {
				return cartItem.cartableId == this.productInfo.id
			})
		},
		isFavorite() {
			return this.productInfo.favoriteFromUsers
				? this.productInfo.favoriteFromUsers.some((user) => {
						return user.id == this.$currentUser.id
				  })
				: false
		},
	},
	watch: {
		cartItem: {
			deep: true,
			immediate: true,
			handler(cartItem) {
				if (!cartItem) return (this.quantity = 1)
				if (cartItem.quantity != this.quantity && !this.isUpdating) {
					this.quantity = cartItem.quantity
				}
			},
		},
	},
	methods: {
		...mapActions('cart', ['updateCartItem']),
		...mapActions('products', ['addToFavorite', 'removeFavorite']),
		async onAddToCart() {
			if (this.isUpdating) return

			if (this.isInCart) {
				this.quantity += 1
				return this.onChangeQuantity(this.quantity)
			}

			this.isUpdating = true
			const [err] = await this.$store.dispatch('cart/addToCart', {
				userId: this.$currentUser.id,
				cartableType: 'product',
				quantity: this.quantity,
				cartableId: this.productInfo.id,
			})
			this.isUpdating = false
			if (!err) this.$toast.success(this.$t('success.addedToCart'))
			else this.$toast.error(this.$t('error.errorAddingCart'))
		},
		onHover() {
			this.hoverTimeOut = setTimeout(() => {
				this.$emit('onHover', this.productInfo)
			}, 1500)
		},
		onLeave() {
			if (this.hoverTimeOut) clearTimeout(this.hoverTimeOut)
			this.hoverTimeOut = setTimeout(() => {
				this.$emit('onLeave')
			}, 1000)
		},
		formatPrice(price) {
			return numberWithCommas(price)
		},
		formatProductName(name) {
			return truncate(name, {
				length: 34,
				separator: '...',
			})
		},
		async onChangeQuantity(payload) {
			if (!this.isInCart) return this.onAddToCart()

			this.isUpdating = true
			await this.updateCartItem({
				...this.cartItem,
				quantity: payload,
			})
			this.isUpdating = false
		},
		async toFavorite(id) {
			if (this.$route.path === '/favorites') {
				await this.handleRemoveFavorite(id)
			} else {
				!this.isFavorite
					? await this.handleAddFavorite(id)
					: await this.handleRemoveFavorite(id)
			}
		},
		async handleAddFavorite(id) {
			let [err] = await this.addToFavorite(id)
			if (!err) {
				this.$toast.success(this.$t('m.success.addedToFav'))
				this.$emit('fetchFavorite')
			} else this.$toast.error(this.$t('m.error.errorAddingFav'))
		},
		async handleRemoveFavorite(id) {
			let [err] = await this.removeFavorite(id)
			if (!err) {
				this.$toast.success(this.$t('m.success.removedFromFav'))
				this.$emit('fetchFavorite')
			} else this.$toast.error(this.$t('m.error.errorRemovingFav'))
		},
	},
}
</script>

<style lang="scss" scoped>
.product-name {
	line-height: 1.5rem;
	height: 3rem;
	overflow: hidden;
}
</style>
