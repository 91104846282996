import { Banner } from '../../api'
import {
	createGetters,
	createMutations,
	createPaginaion,
	handleAction,
} from 'vuelpers'

const initialState = () => ({
	banners: createPaginaion(),
})

const state = initialState()
const getters = createGetters('banners')
const mutations = createMutations('SET', 'DELETE')

const actions = {
	createBanner({ commit }, payload) {
		return handleAction(Banner.post(payload), (res) => {
			commit('SET', { 'banners.data': res.banners })
		})
	},
	deleteBanner({ commit }, payload = {}) {
		return handleAction(Banner.delete(payload), () => {
			commit('DELETE', ['banners.data', payload])
		})
	},
	getBanners({ commit }, payload = {}) {
		commit('SET', { 'banners.isRefetching': true })
		return handleAction(Banner.get(payload), (res) => {
			commit('SET', {
				banners: (v) => ({
					...v,
					data: res.banners,
					isLoaded: true,
					isRefetching: false,
				}),
			})
		})
	},
	resetState({ commit }) {
		commit('SET', initialState())
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
