import { Invoices } from '../../api'
import { initialList } from '../store.helpers'
import { createGetters, createMutations, handleAction, SET } from 'vuelpers'

const initialState = () => ({
	buyerInvoices: initialList(),
	supplierInvoices: initialList(),
})

const state = initialState()
const mutations = createMutations(SET)
const getters = createGetters('buyerInvoices', 'supplierInvoices')

const actions = {
	fetchBuyerInvoices({ commit }, payload = {}) {
		commit(SET, { 'buyerInvoices.isRefreshing': true })

		payload.type = 'buyer'
		return handleAction(Invoices.get(payload), (res) => {
			commit(SET, {
				buyerInvoices: (prevInvoices) => ({
					...prevInvoices,
					...res.invoices,
					isLoaded: true,
					isRefreshing: false,
				}),
			})
		})
	},
	fetchSupplierInvoices({ commit }, payload = {}) {
		commit(SET, { 'supplierInvoices.isRefreshing': true })

		payload.type = 'supplier'
		return handleAction(Invoices.get(payload), (res) => {
			commit(SET, {
				supplierInvoices: (prevInvoices) => ({
					...prevInvoices,
					...res.invoices,
					isLoaded: true,
					isRefreshing: false,
				}),
			})
		})
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
	namespaced: true,
}
