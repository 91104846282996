<template>
	<div class="base-text-field">
		<div
			v-if="label || labelIcon"
			class="tw-flex tw-items-center tw-space-s-3 tw-mb-4"
		>
			<v-icon v-if="labelIcon" color="#D5D5D5"> {{ labelIcon }} </v-icon>
			<span v-if="label" class="tw-font-medium tw-text-black">
				{{ label }}
			</span>
		</div>
		<v-text-field
			v-bind="$attrs"
			v-on="$listeners"
			:height="height"
			outlined
			hide-details="auto"
			class="custom-outlined placeholder-padding tw-rounded-md"
		/>
	</div>
</template>

<script>
export default {
	name: 'BaseTextField',
	props: {
		label: String,
		labelIcon: String,
	},
	computed: {
		height() {
			const { lgAndUp } = this.$vuetify.breakpoint
			return lgAndUp ? '60px' : 'auto'
		},
	},
}
</script>
