<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16.251"
			height="13.081"
			viewBox="0 0 16.251 13.081"
		>
			<g id="advertising-svgrepo-com" transform="translate(-2.5 -5.325)">
				<path
					id="Path_34287"
					data-name="Path 34287"
					d="M4.76,13.625H3V10.106H4.76L18.251,6V17.732Z"
					fill="none"
					stroke="#000"
					stroke-miterlimit="10"
					stroke-width="1"
				/>
				<path
					id="Path_34288"
					data-name="Path 34288"
					d="M12.339,21.056l-.176.587a1.831,1.831,0,0,1-2.288,1.232h0a1.831,1.831,0,0,1-1.232-2.288L8.819,20"
					transform="translate(-2.3 -5.788)"
					fill="none"
					stroke="#000"
					stroke-miterlimit="10"
					stroke-width="1"
				/>
			</g>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconAdvertising',
}
</script>
