<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18.818"
			height="20.879"
			viewBox="0 0 18.818 20.879"
		>
			<path
				id="cube-svgrepo-com"
				d="M21.518,4.8c0-.005,0-.01,0-.016a.354.354,0,0,0-.011-.074.312.312,0,0,0-.012-.029.324.324,0,0,0-.024-.053c-.006-.01-.014-.019-.021-.028a.345.345,0,0,0-.035-.042.325.325,0,0,0-.029-.024c-.009-.007-.017-.016-.027-.022L21.338,4.5s-.009-.007-.014-.009L12.065.035a.342.342,0,0,0-.307,0L3.185,4.5l-.01.007-.012.005a.315.315,0,0,0-.031.025c-.01.008-.021.015-.03.024a.332.332,0,0,0-.034.042.337.337,0,0,0-.021.028.317.317,0,0,0-.025.058c0,.009-.008.016-.01.025A.342.342,0,0,0,3,4.8V15.775a.343.343,0,0,0,.19.307l8.916,4.458c.005,0,.011,0,.016,0a.346.346,0,0,0,.138.032.34.34,0,0,0,.138-.033c.005,0,.011,0,.016,0l8.916-4.458a.342.342,0,0,0,.19-.307V4.8h0ZM11.922.727,20.4,4.807,12.259,8.876,4.1,4.8ZM3.686,5.356l8.23,4.115V19.679l-8.23-4.115ZM20.832,15.563,12.6,19.679V9.472l8.23-4.115Z"
				transform="translate(-2.85 0.152)"
				stroke="#000"
				stroke-width="0.3"
			/>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconBox',
}
</script>
