import { Packages } from '../../api'
import { initialList } from '../store.helpers'
import { createGetters, createMutations, handleAction } from 'vuelpers'

const initialState = () => ({
	transactionRef: null,
	packages: initialList(),
	currentSubscription: null,
	subscriptions: [],
})

const state = initialState()

const getters = {
	...createGetters('packages', 'subscriptions', 'currentSubscription'),
	subscriptionTransactionRef: (state) => state.transactionRef,
}

const mutations = {
	...createMutations('SET', 'PUSH', 'UNSHIFT', 'DELETE', 'UPDATE', 'MERGE'),
}

const actions = {
	setPackageState({ commit }, payload) {
		commit('SET', payload)
	},
	unsubscribe({ commit }, payload) {
		commit
		return handleAction(
			Packages.unsubscribe({ id: payload.id, status: 'cancelled' })
			// () =>
			// 	commit(
			// 		'auth/SET',
			// 		{ 'currentUser.buyer.0.subscription': null },
			// 		{ root: true }
			// 	)
		)
	},
	subscribe({ commit }, payload = {}) {
		return handleAction(Packages.subscribe(payload), (res) => {
			commit('UNSHIFT', ['subscriptions', res.buyerSubscription])
		})
	},
	getSubscriptions({ commit }, payload = {}) {
		return handleAction(Packages.subscriptions(payload), (res) => {
			commit('SET', { subscriptions: res.buyerSubscriptions })
		})
	},
	getCurrentSubscription({ commit }, payload = {}) {
		return handleAction(Packages.getCurrentSubscription(payload), (res) => {
			commit('SET', { currentSubscription: res.buyerSubscription })
		})
	},
	getPackages({ commit }, payload = {}) {
		commit('SET', { 'packages.isRefreshing': true })
		return handleAction(Packages.get(payload), (res) => {
			commit('SET', {
				packages: (v) => ({
					...v,
					...res.subscriptions,
					isLoaded: true,
					isRefreshing: false,
				}),
			})
		})
	},
	getPackagesAdmin({ commit }, payload = {}) {
		return handleAction(Packages.Admin.get(payload), (res) => {
			commit('SET', { packages: res.subscriptions })
		})
	},
	getPackageAdmin({ commit }, payload = {}) {
		return handleAction(Packages.Admin.getById(payload), (res) => {
			commit('UNSHIFT', ['packages.data', res.subscription])
		})
	},
	createPackage({ commit }, payload = {}) {
		return handleAction(Packages.Admin.post(payload), (res) => {
			commit('UNSHIFT', ['packages.data', res.subscription])
		})
	},
	deletePackage({ commit }, payload = {}) {
		return handleAction(Packages.Admin.delete(payload), () => {
			commit('DELETE', ['packages.data', payload])
		})
	},
	updatePackage({ commit }, payload = {}) {
		return handleAction(Packages.Admin.update(payload), (res) => {
			commit('UPDATE', ['packages.data', res.subscription])
		})
	},
	resetState({ commit }) {
		commit('SET', initialState())
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
	namespaced: true,
}
