const { getParentElement } = require('./getParentElement')

export const scrollIntoError = (instance) => {
	instance.$nextTick(() => {
		const errorElement = document.querySelector('.v-input .error--text')
		if (!errorElement) return

		const vInputElement = getParentElement(errorElement, '.v-input')
		;(vInputElement || errorElement)?.scrollIntoView({ behavior: 'smooth' })
	})
}
