<template>
	<div>
		<v-menu
			left
			bottom
			nudge-left="48"
			nudge-bottom="-10"
			content-class="shadow tw-rounded-xl actions-menu"
			transition="slide-x-transition"
			v-model="menu"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn dark icon v-bind="attrs" v-on="on" color="#CACACA">
					<v-icon>mdi-dots-vertical</v-icon>
				</v-btn>
			</template>

			<div class="menu__caret "></div>

			<v-list nav dense class="tw-rounded-xl">
				<template v-for="(action, i) in actions">
					<v-divider
						v-if="action.divider"
						:key="'divider' + i"
						class="tw-my-2"
					/>
					<v-list-item
						v-else
						:key="i"
						:color="action.color || 'primary'"
						@click="action.event && $emit(action.event, item)"
					>
						<v-list-item-icon>
							<v-icon
								v-text="action.icon"
								:color="action.color || '#F2CA51'"
							/>
						</v-list-item-icon>
						<v-list-item-content class="tw-pe-8">
							<v-list-item-title
								class=" tw-text-sm tw-font-normal"
								:class="[
									action.color
										? `${action.color}--text`
										: 'tw-text-AAB',
								]"
							>
								<span v-if="isFunction(action.text)">
									{{ action.text(item) }}
								</span>
								<span v-else-if="action.text == 'Deactive'">
									{{
										item.status === 'deactive' || item.status == 0
											? 'active'
											: 'deactive'
									}}
								</span>
								<span v-else>
									{{ action.text }}
								</span>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-list>
		</v-menu>
		<v-overlay :value="menu" opacity="0.18" color="black" />
	</div>
</template>

<script>
import { isFunction } from 'lodash'
import { i18n } from '../../plugins/vue-i18n'
export default {
	name: 'BaseTableActions',
	props: {
		item: {
			required: true,
			type: Object,
		},
		actions: {
			type: Array,
			default: () => [
				{
					icon: 'mdi-eye-outline',
					event: 'view',
					text: i18n.t('actions.viewDetails'),
				},
				{
					icon: 'mdi-pencil-outline',
					event: 'update',
					text: i18n.t('actions.update'),
				},
				{ divider: true },
				{
					icon: 'mdi-delete-outline',
					event: 'delete',
					text: i18n.t('actions.delete'),
				},
			],
		},
	},
	data: () => ({
		menu: false,
		selectedAction: null,
	}),
	methods: {
		isFunction,
	},
}
</script>

<style lang="scss">
.actions-menu {
	contain: unset !important;
	overflow: visible !important;
	.menu__caret {
		top: 24px;
		right: -6px;
		width: 12px;
		height: 12px;
		background-color: white;
		transform: rotate(45deg);
		position: absolute;
	}
}
</style>
