<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			id="pdf_9_"
			data-name="pdf (9)"
			xmlns="http://www.w3.org/2000/svg"
			width="24.342"
			height="26.38"
			viewBox="0 0 24.342 26.38"
		>
			<path
				id="Path_18496"
				data-name="Path 18496"
				d="M24.821,154.177V160.6H19.774v-4.624a1.8,1.8,0,0,1,1.8-1.8h3.247Z"
				transform="translate(-19.774 -146.233)"
				fill="#bed8fb"
			/>
			<path
				id="Path_18497"
				data-name="Path 18497"
				d="M94.212,10.126V24.263A2.116,2.116,0,0,1,92.1,26.38H74.707a2.117,2.117,0,0,1-2.058-1.623l-.009-1.21-.049-7.207V2.117A2.116,2.116,0,0,1,74.707,0h9.379c.843,0,3.573,2.327,5.986,4.779,2.2,2.237,4.14,4.577,4.14,5.347Z"
				transform="translate(-69.87 0)"
				fill="#ddeafb"
			/>
			<path
				id="Path_18498"
				data-name="Path 18498"
				d="M362.839,98.1v5.179c0-.006,0-.012,0-.018-.159-6.355-5.335-6.649-7.028-8.341l2.889-2.167C360.9,94.985,362.839,97.325,362.839,98.1Z"
				transform="translate(-338.496 -87.969)"
				fill="#cbe2ff"
			/>
			<path
				id="Path_18499"
				data-name="Path 18499"
				d="M200.357,0H195.2c6.355.159,6.649,5.335,8.341,7.028L205.7,4.14c-2.237-2.2-4.578-4.14-5.347-4.14Z"
				transform="translate(-186.141 0)"
				fill="#cbe2ff"
			/>
			<path
				id="Path_18500"
				data-name="Path 18500"
				d="M289.335,10.126v.9a3.414,3.414,0,0,0-3.414-3.414h-2.078a2.117,2.117,0,0,1-2.117-2.117V3.414A3.414,3.414,0,0,0,278.314,0h.9a6.08,6.08,0,0,1,4.3,1.78l4.047,4.047A6.08,6.08,0,0,1,289.335,10.126Z"
				transform="translate(-264.993 0)"
				fill="#bed8fb"
			/>
			<path
				id="Path_18501"
				data-name="Path 18501"
				d="M86.918,259.728v4.747a2.78,2.78,0,0,1-2.78,2.78h-11.5l-.049-7.207v-3.1H84.137A2.78,2.78,0,0,1,86.918,259.728Z"
				transform="translate(-69.867 -243.709)"
				fill="#cbe2ff"
			/>
			<path
				id="Path_18502"
				data-name="Path 18502"
				d="M38.632,193.7v4.747a2.78,2.78,0,0,1-2.78,2.78H21.581a1.573,1.573,0,0,1-.159-.007,1.8,1.8,0,0,1-1.642-1.793V189.121a1.8,1.8,0,0,0,1.642,1.793,1.573,1.573,0,0,0,.159.007h14.27A2.78,2.78,0,0,1,38.632,193.7Z"
				transform="translate(-19.781 -179.377)"
				fill="#dd636e"
			/>
			<path
				id="Path_18503"
				data-name="Path 18503"
				d="M21.423,190.914v10.308a1.8,1.8,0,0,1-1.642-1.793V189.121a1.8,1.8,0,0,0,1.642,1.793Z"
				transform="translate(-19.781 -179.377)"
				fill="#da4a54"
			/>
			<g
				id="Group_10492"
				data-name="Group 10492"
				transform="translate(3.63 13.724)"
			>
				<path
					id="Path_18504"
					data-name="Path 18504"
					d="M278.115,267.9a.4.4,0,1,0,0-.8h-2.052a.4.4,0,0,0-.4.4v5.064a.4.4,0,1,0,.8,0v-2.182h1.491a.4.4,0,1,0,0-.8H276.46V267.9h1.654Z"
					transform="translate(-266.11 -267.062)"
					fill="#fff"
				/>
				<path
					id="Path_18505"
					data-name="Path 18505"
					d="M176.185,267.1h-1.434a.4.4,0,0,0-.4.4v0h0v4.976a.422.422,0,0,0,.408.48h0c.043,0,1.064,0,1.471-.011a2.244,2.244,0,0,0,1.771-.9,3.323,3.323,0,0,0,.623-2.018C178.628,268.277,177.647,267.1,176.185,267.1Zm.035,5.053c-.242,0-.719.007-1.062.009,0-.581-.005-1.644-.005-2.138,0-.413,0-1.526,0-2.128h1.035c.8,0,1.647.561,1.647,2.134C177.832,271.262,177.169,272.135,176.22,272.152Z"
					transform="translate(-170.018 -267.062)"
					fill="#fff"
				/>
				<path
					id="Path_18506"
					data-name="Path 18506"
					d="M92.044,266.374h-1.42a.4.4,0,0,0-.4.4h0v5.1a.4.4,0,1,0,.8,0v-1.82l1.022,0a1.838,1.838,0,1,0,0-3.675Zm0,2.879-1.015,0c0-.339,0-.808,0-1.046,0-.2,0-.689,0-1.041h1.021a1.042,1.042,0,1,1,0,2.083Z"
					transform="translate(-90.225 -266.374)"
					fill="#fff"
				/>
			</g>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconFilePdf',
}
</script>
