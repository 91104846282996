import axios from './base.api'

const api = {
	...axios,
	auth: {
		login: (body) => axios.post('/login', body),
		logout: () => axios.post('/logout', {}),
		supplierReg: (body) => axios.post('/supplier/create', body),
		buyerReg: (body) => axios.post('/buyer/create', body),
		currentUser: {
			get: (query) => axios.get('/auth-user', query),
		},
		resetPass: (body) => axios.post('/password/reset', body),
		resetPassEmail: (body) => axios.post('/password/reset-by-email', body),
		resetPassPhone: (body) => axios.post('/password/reset-by-phone', body),
		verify: (token) => axios.get('/password/check?token=' + token),
		forgotPass: (body) => axios.post('/password/forgot', body),
		sendOtp: (userId) => axios.get('/twilio-otp?user_id=' + userId),
		checkOtp: (body) => axios.get('/check-twilio-otp', body),
		acceptTerms: () => axios.get('/accept-terms'),
		verifyEmail: (body) => axios.post('/password/verify-email', body),
		verifyPhone: (body) => axios.post('/password/verify-phone', body),
		fetchProfile: () => axios.get('/auth-user'),
		updateCurrentUser: (body) => axios.post('/auth-user', body),
		contactUs: (body) => axios.post('/contact/store', body),
	},
	users: {
		get: (query) => axios.get('/user', query),
		post: (query) => axios.post('/user', query),
		update: (query) => axios.post('/user/update', query),
		getById: (id, query) => api.get('/user/' + id, query),
		changeStatus: (body) => api.post('/user/change-status', body),
		delete: (id) => api.delete('/user/' + id),
		getBuyers: (query) => api.get('/buyer', query),
		getSuppliers: (query) => api.get('/supplier', query),
	},
	products: {
		get: (query) => api.get('/product', query),
		getTrial: (query) => api.get('/product/trial', query),
		post: (body) => api.post('/product', body),
		delete: (id) => api.delete('/product/' + id),
		getDependencies: (query) => api.get('/product/dependency', query),
		patch: (body) => api.post('/product/update', body),
		getById: (id, query) => api.get('/product/single/' + id, query),
		bulkUpload: (data) => api.post('/product/import', data),
		favorite: (body) => api.get('/product/favorite', body),
		addFavorite: (id) => api.get('/product/favorite/' + id + '/make'),
		removeFavorite: (id) => api.get('/product/favorite/' + id + '/remove'),
		topSelling: (query) => api.get('/product/top-selling', query),
		todayBestPick: (query) => api.get('/product/today-best-pickups', query),
	},
	categories: {
		get: (query) => api.get('/category', query),
		post: (body) => api.post('/category', body),
		delete: (id) => api.delete('/category/' + id),
		patch: (id, body) => api.post('/category/' + id, body),
		getById: (id, query) => api.get('/category/' + id, query),
		unAuthorised: (query) => api.get('/category-data', query),
	},
	bank: {
		get: (query) => axios.get('/bank', query),
		delete: (id) => axios.delete('/bank/' + id),
		post: (body) => axios.post('/bank', body),
		update: (body) => axios.post('/bank/update', body),
	},
	offers: {
		get: (query) => api.get('/offer', query),
		getRunning: (query) => api.get('/offer/running', query),
		post: (body) => api.post('/offer', body),
		delete: (id) => api.delete('/offer/' + id),
		patch: (body) => api.post('/offer/update', body),
		getById: (id, query) => api.get('/offer/' + id, query),
		Marketing: {
			get: (query) => api.get('/offer/marketing-offer', query),
			post: (body) => api.post('/offer/marketing-offer', body),
			delete: (id) => api.delete('/offer/marketing-offer/' + id),
			patch: (body) => api.post('/offer/marketing-offer/update', body),
			getById: (id, query) => api.get('/offer/marketing-offer/' + id, query),
			countBuyer: (query) =>
				api.get('/offer/count-buyer-by-category', query),
		},
	},

	orders: {
		get: (query) => api.get('/purchase/order', query),
		post: (body) => api.post('/purchase/order', body),
		delete: (id) => api.delete('/purchase/order/' + id),
		patch: (body) => api.post('/purchase/update', body),
		changeStatus: (body) => api.post('/purchase/order/change-status', body),
		getById: (id, query) => api.get('/purchase/order/' + id, query),
		print: (id) => api.get('/purchase/order/print/' + id), 
	},
	permissions: {
		get: (query) => api.get('/permissions', query),
	},
}

export const Cart = {
	addItem: (item) => axios.post('/cart', item),
	getCartList: (query) => axios.get('/cart', query),
	updateItem: (item) => axios.post('/cart/update', item),
	removeItem: (itemId) => axios.delete('/cart/' + itemId),
}
export const complaints = {
	get: (query) => api.get('/complaint', query),
	post: (body) => api.post('/complaint', body),
	patch: (body) => api.post('/complaint', body),
	changeStatus: (body) => api.patch('/complaint', body),
	delete: (id) => api.delete('/complaint/' + id),
	getById: (id, query) => api.get('/complaint/' + id, query),
}
export const category = {
	get: (query) => api.get('/category', query),
	post: (body) => api.post('/category', body),
	delete: (id) => api.delete('/category/' + id),
	patch: (id, body) => api.post('/category/' + id, body),
	getById: (id, query) => api.get('/category/' + id, query),
}

export const branch = {
	get: (query) => api.get('/branch', query),
	getById: (id, query) => api.get('/branch/' + id, query),
	post: (body) => api.post('/branch/create', body),
	patch: (body) => api.post('/branch/update', body),
	delete: (id) => api.delete('/branch/' + id),
}

export const Banner = {
	get: (query) => api.get('/banner', query),
	post: (body) => api.post('/banner', body),
	delete: (id) => api.delete('/banner/' + id),
}

export const AdSpaces = {
	get: (query) => api.get('/ad-space', query),
	post: (body) => api.post('/ad-space', body),
	patch: (body) => api.patch('/ad-space/update', body),
	changeStatus: (body) => api.patch('/ad-space', body),
	delete: (id) => api.delete('/ad-space/' + id),
	getById: (id, query) => api.get('/ad-space/' + id, query),
}

export const Packages = {
	get: (query) => api.get('/buyer/subscription', query),
	getCurrentSubscription: (query) =>
		api.get('/buyer/subscription/purchased/latest', query),
	subscriptions: (query) => api.get('/buyer/subscription/purchased', query),
	subscribe: (data) => api.post('/buyer/subscription', data),
	unsubscribe: (data) => api.post('/buyer/subscription/change-status', data),
	Admin: {
		get: (query) => api.get('/subscription', query),
		post: (body) => api.post('/subscription', body),
		delete: (id) => api.delete('/subscription/' + id),
		update: (body) => api.put('/subscription', body),
		getById: ({ id, ...query }) => api.get('/subscription/' + id, query),
	},
}

export const Buyer = {
	get: (query) => api.get('/buyer', query),
	review: (body) => api.patch('/buyer/admin-review', body),
	getById: (id) => api.get('/buyer/' + id),
	update: (body) => api.post('/buyer/update', body),
	delete: (id) => api.delete(`/buyer/${id}`),
	changeStatus: (data) => api.post(`/buyer/change-status`, data),
}

export const Supplier = {
	get: (query) => api.get('/supplier', query),
	review: (body) => api.patch('/supplier/admin-review', body),
	getById: (id) => api.get('/supplier/' + id),
	update: (body) => api.post('/supplier/update', body),
	delete: (id) => api.delete(`/supplier/${id}`),
	changeStatus: (data) => api.post(`/supplier/change-status`, data),
}

export * from './addresses.api'
export * from './advertisements.api'
export * from './contracts.api'
export * from './invoices.api'

export default api
