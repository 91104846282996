<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6"
		><svg
			xmlns="http://www.w3.org/2000/svg"
			width="18.008"
			height="18.009"
			viewBox="0 0 18.008 18.009"
			class="tw-fill-current"
		>
			<path
				id="exit_1_"
				data-name="exit (1)"
				d="M13.2,13.788V15.2a2.817,2.817,0,0,1-2.814,2.814H3.314A2.817,2.817,0,0,1,.5,15.2V2.814A2.817,2.817,0,0,1,3.314,0h7.07A2.817,2.817,0,0,1,13.2,2.814V4.221a.7.7,0,0,1-1.407,0V2.814a1.409,1.409,0,0,0-1.407-1.407H3.314A1.409,1.409,0,0,0,1.907,2.814V15.2A1.409,1.409,0,0,0,3.314,16.6h7.07A1.409,1.409,0,0,0,11.791,15.2V13.788a.7.7,0,0,1,1.407,0Zm4.8-5.992L16.419,6.221a.7.7,0,0,0-.995.995l1.12,1.121H8.1a.7.7,0,0,0,0,1.407h8.447l-1.12,1.121a.7.7,0,1,0,.995.995l1.575-1.575a1.761,1.761,0,0,0,0-2.487Zm0,0"
				transform="translate(-0.5)"
			/></svg
	></span>
</template>
<script>
export default {
	name: 'IconExit',
}
</script>
