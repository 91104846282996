<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			id="pdf_9_"
			data-name="pdf (9)"
			xmlns="http://www.w3.org/2000/svg"
			width="24.143"
			height="26.164"
			viewBox="0 0 24.143 26.164"
		>
			<path
				id="Path_18496"
				data-name="Path 18496"
				d="M24.78,154.177v6.372H19.774v-4.587a1.786,1.786,0,0,1,1.786-1.786h3.22Z"
				transform="translate(-19.774 -146.298)"
				fill="#bed8fb"
			/>
			<path
				id="Path_18497"
				data-name="Path 18497"
				d="M94.035,10.043V24.065a2.1,2.1,0,0,1-2.1,2.1H74.69a2.1,2.1,0,0,1-2.042-1.61l-.008-1.2-.049-7.148V2.1A2.1,2.1,0,0,1,74.69,0h9.3c.836,0,3.543,2.308,5.937,4.74,2.184,2.219,4.106,4.54,4.106,5.3Z"
				transform="translate(-69.892 0)"
				fill="#ddeafb"
			/>
			<path
				id="Path_18498"
				data-name="Path 18498"
				d="M362.781,98.051v5.136c0-.006,0-.012,0-.018-.158-6.3-5.292-6.594-6.971-8.273l2.865-2.149C360.859,94.967,362.781,97.288,362.781,98.051Z"
				transform="translate(-338.638 -88.008)"
				fill="#cbe2ff"
			/>
			<path
				id="Path_18499"
				data-name="Path 18499"
				d="M200.314,0H195.2c6.3.158,6.594,5.292,8.273,6.971l2.149-2.865C203.4,1.923,201.078,0,200.314,0Z"
				transform="translate(-186.215 0)"
				fill="#cbe2ff"
			/>
			<path
				id="Path_18500"
				data-name="Path 18500"
				d="M289.245,10.043v.888a3.386,3.386,0,0,0-3.386-3.386H283.8a2.1,2.1,0,0,1-2.1-2.1V3.386A3.386,3.386,0,0,0,278.314,0h.888a6.031,6.031,0,0,1,4.264,1.766l4.014,4.014A6.03,6.03,0,0,1,289.245,10.043Z"
				transform="translate(-265.102 0)"
				fill="#bed8fb"
			/>
			<path
				id="Path_18501"
				data-name="Path 18501"
				d="M86.8,259.706v4.708a2.757,2.757,0,0,1-2.757,2.758H72.637l-.049-7.148v-3.076H84.043A2.757,2.757,0,0,1,86.8,259.706Z"
				transform="translate(-69.889 -243.818)"
				fill="#cbe2ff"
			/>
			<path
				id="Path_18502"
				data-name="Path 18502"
				d="M38.477,193.664v4.708a2.757,2.757,0,0,1-2.757,2.757H21.567a1.561,1.561,0,0,1-.157-.007,1.785,1.785,0,0,1-1.628-1.778V189.121a1.786,1.786,0,0,0,1.628,1.778,1.565,1.565,0,0,0,.157.007H35.72A2.757,2.757,0,0,1,38.477,193.664Z"
				transform="translate(-19.781 -179.457)"
				fill="#4aaaff"
			/>
			<path
				id="Path_18503"
				data-name="Path 18503"
				d="M21.409,190.9v10.223a1.785,1.785,0,0,1-1.628-1.778V189.121a1.786,1.786,0,0,0,1.628,1.778Z"
				transform="translate(-19.781 -179.457)"
				fill="#4aaaff"
			/>
			<text
				id="DOC"
				transform="translate(2.904 18.192)"
				fill="#fff"
				font-size="5"
				font-family="Poppins-Medium, Poppins"
				font-weight="500"
			>
				<tspan x="0" y="0">DOC</tspan>
			</text>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconFileDoc',
}
</script>
