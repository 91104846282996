<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24.288"
			height="20.975"
			viewBox="0 0 24.288 20.975"
		>
			<g
				id="connection_1_"
				data-name="connection (1)"
				transform="translate(1.784 -22.853)"
			>
				<path
					id="Path_34181"
					data-name="Path 34181"
					d="M67.755,24.157a6.569,6.569,0,0,1,6.562,6.562H75.51c-.427-10.291-15.087-10.283-15.51,0h1.193A6.569,6.569,0,0,1,67.755,24.157Z"
					transform="translate(-57.394 0)"
					stroke="#fff"
					stroke-width="0.3"
				/>
				<path
					id="Path_34183"
					data-name="Path 34183"
					d="M10.19,28.761l9.005-2.967.74,5.863L13.613,30.6l-2.4-.706-2.367-.6L4.607,31.233H-.337l.742-6.251Z"
					transform="translate(0.171 0.283)"
					fill="#fff"
				/>
				<path
					id="Path_34214"
					data-name="Path 34214"
					d="M21.62,208.818a2.8,2.8,0,1,0-4.059,0,4.229,4.229,0,0,0-1.4,1.262,4.932,4.932,0,0,0-1.991-1.927,3.5,3.5,0,1,0-4.541,0,4.932,4.932,0,0,0-1.991,1.927,4.228,4.228,0,0,0-1.4-1.262,2.8,2.8,0,1,0-4.059,0A4.2,4.2,0,0,0,0,212.492v2.8H23.788v-2.8a4.2,4.2,0,0,0-2.168-3.674Zm-3.429-1.924a1.4,1.4,0,1,1,1.4,1.4A1.4,1.4,0,0,1,18.191,206.895Zm-8.4-1.4a2.1,2.1,0,1,1,2.1,2.1A2.1,2.1,0,0,1,9.8,205.5Zm-7,1.4a1.4,1.4,0,1,1,1.4,1.4A1.4,1.4,0,0,1,2.8,206.895Zm4.2,7H1.4v-1.4a2.8,2.8,0,0,1,5.6,0Zm8.4,0h-7v-1.4a3.5,3.5,0,0,1,7,0Zm7,0h-5.6v-1.4a2.8,2.8,0,0,1,5.6,0Z"
					transform="translate(-1.534 -171.712)"
					stroke="#fff"
					stroke-width="0.5"
				/>
			</g>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconUsers',
}
</script>
