<template>
	<img
		class="tw-object-cover"
		:alt="alt"
		:width="width"
		:height="height"
		:src="`https://flagcdn.com/${mCode}.svg`"
		:style="{
			width: `${width}px`,
			height: `${height}px`,
		}"
	/>
</template>

<script>
export default {
	name: 'BaseFlag',
	props: {
		alt: {
			type: String,
		},
		width: {
			default: 24,
			type: [String, Number],
		},
		height: {
			default: 16,
			type: [String, Number],
		},
		code: {
			type: String,
			required: true,
		},
	},
	computed: {
		mCode() {
			return this.code.toLowerCase()
		},
	},
}
</script>
