<template>
	<div class="tw-relative" v-on="onHover">
		<div ref="scrollRef" class="scrollbar">
			<slot></slot>
		</div>
		<template v-if="isScrollable">
			<!-- ARROW LEFT -->
			<base-transition name="slide-x" :translate="-24" :duration="250">
				<div
					v-if="showArrows && !scrollbarAtLeft"
					class="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-justify-start tw-px-2"
				>
					<!-- v-on="onHover" -->
					<!-- tw-bg-gradient-to-r tw-from-gray-50 tw-to-transparent tw-w-40-->
					<v-btn
						icon
						dark
						class="tw-bg-black tw-opacity-70 tw-shadow-md"
						@click="onScrollLeft"
					>
						<v-icon>mdi-chevron-left</v-icon></v-btn
					>
				</div>
			</base-transition>

			<!-- ARROW RIGHT -->
			<base-transition name="slide-x" :translate="24" :duration="250">
				<div
					v-if="showArrows && !scrollbarAtRight"
					class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-justify-end tw-px-2"
				>
					<!-- v-on="onHover" -->
					<!-- tw-bg-gradient-to-l tw-from-gray-50 tw-to-transparent tw-w-40-->
					<v-btn
						icon
						dark
						class="tw-bg-black tw-opacity-70 tw-shadow-md"
						@click="onScrollRight"
					>
						<v-icon>mdi-chevron-right</v-icon></v-btn
					>
				</div>
			</base-transition>
		</template>
	</div>
</template>

<script>
export default {
	name: 'BaseSlideable',
	props: {
		value: {
			required: false,
			type: [Number, String, Object, Array],
		},
		showArrowsOnHover: {
			default: false,
			type: Boolean,
		},
	},
	data: () => ({
		hover: false,
		isScrollable: false,
		scrollbarAtLeft: true,
		scrollbarAtRight: false,
	}),
	mounted() {
		const ref = this.getScrollRef()
		ref.addEventListener('scroll', this.onScroll)

		// ref.addEventListener('mouseenter', () => {
		// 	this.hover = true
		// })
		// ref.addEventListener('mouseleave', () => {
		// 	this.hover = false
		// })
	},
	computed: {
		onHover() {
			return {
				mouseenter: () => {
					this.hover = true
				},
				mouseleave: () => {
					this.hover = false
				},
			}
		},
		showArrows() {
			return (
				!this.showArrowsOnHover || (this.showArrowsOnHover && this.hover)
			)
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler() {
				this.$nextTick(() => {
					const el = this.getScrollRef()
					if (!el) return
					this.isScrollable = el.scrollWidth > el.clientWidth
				})
			},
		},
	},
	methods: {
		onScroll() {
			const el = this.getScrollRef()
			this.scrollbarAtLeft = el.scrollLeft - 50 <= 0
			this.scrollbarAtRight =
				el.clientWidth + el.scrollLeft >= el.scrollWidth
		},
		getScrollRef() {
			return this.$refs.scrollRef
		},
		onScrollRight() {
			const el = this.getScrollRef()
			el.scrollTo({
				behavior: 'smooth',
				left: el.scrollLeft + 400,
			})
		},
		onScrollLeft() {
			const el = this.getScrollRef()
			el.scrollTo({
				behavior: 'smooth',
				left: el.scrollLeft - 400,
			})
		},
	},
}
</script>
<style lang="scss">
.scrollbar {
	overflow-x: auto;
	&::-webkit-scrollbar {
		height: 0;
	}
}
</style>
