<template>
	<span class="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
		<svg
			id="calendar_22_"
			data-name="calendar (22)"
			xmlns="http://www.w3.org/2000/svg"
			width="24.362"
			height="24.207"
			viewBox="0 0 24.362 24.207"
		>
			<g id="Group_39176" data-name="Group 39176">
				<g
					id="Group_39175"
					data-name="Group 39175"
					transform="translate(0 0)"
				>
					<path
						id="Path_34138"
						data-name="Path 34138"
						d="M21.836,22.959H20.307V21.608a.638.638,0,1,0-1.269,0v1.352H12.819V21.608a.638.638,0,1,0-1.269,0v1.352H5.267V21.608a.676.676,0,0,0-.635-.708A.676.676,0,0,0,4,21.608v1.352H2.532A2.733,2.733,0,0,0,0,25.825V42.277a2.691,2.691,0,0,0,2.532,2.83h19.3a2.689,2.689,0,0,0,2.532-2.823V25.825A2.723,2.723,0,0,0,21.836,22.959ZM23.1,42.277a1.347,1.347,0,0,1-1.256,1.415H2.532a1.341,1.341,0,0,1-1.263-1.408V25.825a1.376,1.376,0,0,1,1.263-1.451H4V25.4a.676.676,0,0,0,.635.708.676.676,0,0,0,.635-.708V24.374h6.282V25.4a.638.638,0,1,0,1.269,0V24.374h6.219V25.4a.638.638,0,1,0,1.269,0V24.374h1.529A1.376,1.376,0,0,1,23.1,25.825Z"
						transform="translate(0 -20.9)"
						fill="#722432"
					/>
				</g>
			</g>
			<g
				id="Group_39178"
				data-name="Group 39178"
				transform="translate(8.661 8.074)"
			>
				<g
					id="Group_39177"
					data-name="Group 39177"
					transform="translate(0 0)"
				>
					<path
						id="Path_34139"
						data-name="Path 34139"
						d="M140.146,135h-1.238a.708.708,0,1,0,0,1.415h1.238a.708.708,0,0,0,0-1.415Z"
						transform="translate(-138.2 -135)"
						fill="#722432"
					/>
				</g>
			</g>
			<g
				id="Group_39180"
				data-name="Group 39180"
				transform="translate(13.022 8.074)"
			>
				<g
					id="Group_39179"
					data-name="Group 39179"
					transform="translate(0 0)"
				>
					<path
						id="Path_34140"
						data-name="Path 34140"
						d="M209.746,135h-1.238a.708.708,0,0,0,0,1.415h1.238a.708.708,0,1,0,0-1.415Z"
						transform="translate(-207.8 -135)"
						fill="#722432"
					/>
				</g>
			</g>
			<g
				id="Group_39182"
				data-name="Group 39182"
				transform="translate(17.422 8.074)"
			>
				<g
					id="Group_39181"
					data-name="Group 39181"
					transform="translate(0 0)"
				>
					<path
						id="Path_34141"
						data-name="Path 34141"
						d="M279.946,135h-1.238a.708.708,0,1,0,0,1.415h1.238a.708.708,0,0,0,0-1.415Z"
						transform="translate(-278 -135)"
						fill="#722432"
					/>
				</g>
			</g>
			<g
				id="Group_39184"
				data-name="Group 39184"
				transform="translate(8.661 12.956)"
			>
				<g
					id="Group_39183"
					data-name="Group 39183"
					transform="translate(0 0)"
				>
					<path
						id="Path_34142"
						data-name="Path 34142"
						d="M140.146,204h-1.238a.708.708,0,1,0,0,1.415h1.238a.708.708,0,1,0,0-1.415Z"
						transform="translate(-138.2 -204)"
						fill="#722432"
					/>
				</g>
			</g>
			<g
				id="Group_39186"
				data-name="Group 39186"
				transform="translate(4.261 12.956)"
			>
				<g
					id="Group_39185"
					data-name="Group 39185"
					transform="translate(0 0)"
				>
					<path
						id="Path_34143"
						data-name="Path 34143"
						d="M69.953,204H68.708a.708.708,0,1,0,0,1.415h1.238A.708.708,0,0,0,69.953,204Z"
						transform="translate(-68 -204)"
						fill="#722432"
					/>
				</g>
			</g>
			<g
				id="Group_39188"
				data-name="Group 39188"
				transform="translate(13.022 12.956)"
			>
				<g
					id="Group_39187"
					data-name="Group 39187"
					transform="translate(0 0)"
				>
					<path
						id="Path_34144"
						data-name="Path 34144"
						d="M209.746,204h-1.238a.708.708,0,1,0,0,1.415h1.238a.708.708,0,1,0,0-1.415Z"
						transform="translate(-207.8 -204)"
						fill="#722432"
					/>
				</g>
			</g>
			<g
				id="Group_39190"
				data-name="Group 39190"
				transform="translate(17.422 12.956)"
			>
				<g
					id="Group_39189"
					data-name="Group 39189"
					transform="translate(0 0)"
				>
					<path
						id="Path_34145"
						data-name="Path 34145"
						d="M279.946,204h-1.238a.708.708,0,1,0,0,1.415h1.238a.708.708,0,0,0,0-1.415Z"
						transform="translate(-278 -204)"
						fill="#722432"
					/>
				</g>
			</g>
			<g
				id="Group_39192"
				data-name="Group 39192"
				transform="translate(8.661 17.768)"
			>
				<g id="Group_39191" data-name="Group 39191">
					<path
						id="Path_34146"
						data-name="Path 34146"
						d="M140.146,272h-1.238a.708.708,0,0,0,0,1.415h1.238a.708.708,0,0,0,0-1.415Z"
						transform="translate(-138.2 -272)"
						fill="#722432"
					/>
				</g>
			</g>
			<g
				id="Group_39194"
				data-name="Group 39194"
				transform="translate(4.261 17.768)"
			>
				<g id="Group_39193" data-name="Group 39193">
					<path
						id="Path_34147"
						data-name="Path 34147"
						d="M69.953,272H68.708a.708.708,0,0,0,0,1.415h1.238A.708.708,0,0,0,69.953,272Z"
						transform="translate(-68 -272)"
						fill="#722432"
					/>
				</g>
			</g>
			<g
				id="Group_39196"
				data-name="Group 39196"
				transform="translate(13.022 17.768)"
			>
				<g id="Group_39195" data-name="Group 39195">
					<path
						id="Path_34148"
						data-name="Path 34148"
						d="M209.746,272h-1.238a.708.708,0,0,0,0,1.415h1.238a.708.708,0,0,0,0-1.415Z"
						transform="translate(-207.8 -272)"
						fill="#722432"
					/>
				</g>
			</g>
		</svg>
	</span>
</template>
<script>
export default {
	name: 'IconCalendar',
}
</script>
