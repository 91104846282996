<template>
	<div class="tw-w-full tw-px-0.5 tw-relative">
		<router-link :to="`/offers/${offer.id}`">
			<v-img
				:class="[hideDetails ? '' : 'tw-h-215px tw-mb-4']"
				:src="$image($get(offer, 'attachments.0.attachedLink'), '215')"
				alt
				cover
				ref="imgRef"
				aspect-ratio="1"
				class="tw-w-full tw-h-full tw-rounded-22px tw-cursor-pointer"
				@error="$onImgError({ ref: $refs.imgRef })"
			/>
		</router-link>

		<template v-if="!hideDetails">
			<div class="tw-flex tw-space-s-2 tw-justify-between">
				<span class="tw-h-10 tw-capitalize">
					{{ formatProductName(offer.name) }}
				</span>
			</div>
			<div class="tw-mt-1.5">
				<div class="tw-text-11px tw-h-4">
					{{ $get(offer, 'supplier.representativeName') }}
				</div>
				<div
					class="tw-flex tw-space-s-2 tw-items-center tw-font-Rubik-text"
				>
					<div
						class="tw-text-xl tw-font-bold dark:tw-text-gray-300tw-text-gray-700"
					>
						{{ offer.currency.currency }}
						{{ offer.finalPrice }}
					</div>
					<div class="tw-line-through tw-text-base">
						{{ previousPrice(offer) }}
					</div>
				</div>
			</div>
			<div class="tw-flex tw-justify-between">
				<div
					class="tw-rounded-md tw-justify-between tw-w-full tw-flex tw-items-center tw-mt-3"
				>
					<div class="tw-flex tw-items-center tw-bg-F8 tw-rounded-md">
						<base-quantity
							v-model="quantity"
							@change="onChangeQuantity"
						/>
					</div>
					<v-btn
						:loading="isUpdating"
						dark
						tile
						depressed
						color="#F5CA15"
						style="background-color: #f5ca15"
						class="tw-rounded-md tw-p-0 tw-m-0 tw-h-8 tw-min-w-10 cart-btn"
						@click="onAddToCart"
					>
						<v-icon size="15">mdi mdi-cart</v-icon>
					</v-btn>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { truncate } from 'lodash'
import { numberWithCommas } from '@/helpers'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'OfferCard',
	props: {
		offer: Object,
		hideDetails: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			quantity: 1,
			hoverTimeOut: null,
			isUpdating: false,
		}
	},
	computed: {
		...mapGetters('cart', ['$cart']),
		...mapGetters('auth', ['$currentUser']),
		maxQuantity() {
			if (!this.offer || !this.offer.isSpecifiedQuantity) {
				return Infinity
			}
			return +this.offer.quantity
		},
		isInCart() {
			return !!this.cartItem
		},
		cartItem() {
			return this.$cart.offers.data.find((cartItem) => {
				return cartItem.cartableId == this.offer.id
			})
		},
		isFavorite() {
			return this.offer.favoriteFromUsers
				? this.offer.favoriteFromUsers.some((user) => {
						return user.id == this.$currentUser.id
				  })
				: false
		},
	},
	watch: {
		cartItem: {
			deep: true,
			immediate: true,
			handler(cartItem) {
				if (!cartItem) return (this.quantity = 1)
				if (cartItem.quantity != this.quantity && !this.isUpdating) {
					this.quantity = cartItem.quantity
				}
			},
		},
	},
	methods: {
		...mapActions('cart', ['updateCartItem']),
		...mapActions('products', ['addToFavorite', 'removeFavorite']),
		async onAddToCart() {
			if (this.isUpdating) return

			if (this.isInCart) {
				this.quantity += 1
				return this.onChangeQuantity(this.quantity)
			}

			this.isUpdating = true
			const [err] = await this.$store.dispatch('cart/addToCart', {
				userId: this.$currentUser.id,
				cartableType: 'offer',
				quantity: this.quantity,
				cartableId: this.offer.id,
			})
			this.isUpdating = false
			if (!err) this.$toast.success(this.$t('success.addedToCart'))
			else this.$toast.error(this.$t('error.errorAddingCart'))
		},
		previousPrice(offer) {
			if (offer.offeringType === 'amount') {
				return +offer.finalPrice + +offer.discount
			}
			return offer.products.reduce((total, pro) => {
				return +total + +pro.price
			}, 0)
		},
		formatPrice(price) {
			return numberWithCommas(price)
		},
		formatProductName(name) {
			return truncate(name, {
				length: 34,
				separator: '...',
			})
		},
		async onChangeQuantity(payload) {
			if (!this.isInCart) return this.onAddToCart()

			this.isUpdating = true
			await this.updateCartItem({
				...this.cartItem,
				quantity: payload,
			})
			this.isUpdating = false
		},
		async handleAddFavorite(id) {
			let [err] = await this.addToFavorite(id)
			if (!err) {
				this.$toast.success(this.$t('m.success.addedToFav'))
				this.$emit('fetchFavorite')
			} else this.$toast.error(this.$t('m.error.errorAddingFav'))
		},
		async handleRemoveFavorite(id) {
			let [err] = await this.removeFavorite(id)
			if (!err) {
				this.$toast.success(this.$t('m.success.removedFromFav'))
				this.$emit('fetchFavorite')
			} else this.$toast.error(this.$t('m.error.errorRemovingFav'))
		},
	},
}
</script>

<style lang="scss" scoped>
.product-name {
	line-height: 1.5rem;
	height: 3rem;
	overflow: hidden;
}
</style>
