<template>
	<div
		:class="{ 'tw-pointer-events-none tw-opacity-80': disabled }"
		class="tw-text-center tw-cursor-pointer tw-w-24 tw-bg-f2"
		@click="onclick(item.id)"
	>
		<div class="tw-relative">
			<v-img
				:alt="item.name"
				:src="$image(item.image, '96')"
				cover
				ref="imgRef"
				class="tw-w-24 tw-h-24 tw-object-cover tw-rounded-full tw-mb-4 tw-bg-gray-100"
				@error="$onImgError({ ref: $refs.imgRef })"
			/>
			<div
				:class="isSelected ? 'tw-block' : 'tw-hidden'"
				class="tw-absolute tw-w-full tw-h-full tw-top-0 tw-opacity-70 tw-rounded-full tw-bg-F2"
			/>
		</div>
		<span
			:class="isSelected ? 'tw-text-F2' : 'tw-text-AB'"
			class="tw-truncate tw-block"
		>
			{{ item.name }}
		</span>
	</div>
</template>

<script>
export default {
	name: 'Categorycard',
	props: {
		item: Object,
		disabled: Boolean,
		isSelected: Boolean,
	},
	methods: {
		onclick(id) {
			if (this.disabled) return
			this.$emit('onClick', id)
		},
	},
}
</script>
