<template>
	<v-dialog v-model="vModel" max-width="648" content-class="tw-rounded-29px">
		<v-card>
			<v-card-title
				class=" tw-pb-0 tw-pt-12 tw-text-22 tw-font-pop tw-font-semibold tw-justify-center tw-text-black"
			>
				{{ $t('selectLanguage') }}
			</v-card-title>
			<v-card-subtitle
				class=" tw-p-0 tw-m-0 tw-text-sm tw-font-pop tw-text-bcb tw-text-center tw-font-normal"
				>{{ $t('selectPreffLang') }}</v-card-subtitle
			>
			<v-card-text class="tw-p-0 tw-px-16 tw-pb-10 tw-mt-9">
				<div
					class="sm:tw-flex tw-space-y-4 sm:tw-space-y-0 sm:tw-space-s-7"
				>
					<v-card
						flat
						v-for="language in $languages"
						class="tw-pt-8 tw-pb-9 tw-flex-1 tw-rounded-xl tw-overflow-hidden"
						:key="language.locale"
						:class="
							language.locale === selectedLocale
								? 'tw-bg-yellow-500'
								: 'tw-bg-faf'
						"
						@click="selectedLocale = language.locale"
					>
						<div
							class=" tw-w-12 tw-h-12 tw-grid tw-mx-auto tw-bg-white tw-rounded-full tw-overflow-hidden tw-place-items-center"
						>
							<BaseFlag
								:code="language.flag"
								:alt="language.label"
								width="40"
								height="40"
								class="tw-rounded-full"
							/>
						</div>

						<div
							class="
								tw-text-center tw-mt-3.5 tw-font-pop tw-text-sm tw-font-medium
							"
							:class="
								language.locale === selectedLocale
									? 'tw-text-white'
									: 'tw-text-black'
							"
						>
							{{ language.label }}
						</div>
					</v-card>
				</div>
				<base-button
					block
					height="60"
					class="tw-mt-12"
					@click="onChangeLanguage"
					>{{ $t('actions.changeLanguage') }}</base-button
				>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'DialogLanguage',
	data: () => ({
		selectedLocale: null,
	}),
	created() {
		this.selectedLocale = this.$i18n.locale
	},
	computed: {
		...mapGetters('settings', ['$language', '$languages']),
		vModel: {
			get() {
				return this.$language.dialog
			},
			set(v) {
				this.selectedLocale = this.$i18n.locale
				this.$store.commit('settings/SET', {
					'language.dialog': v,
				})
			},
		},
	},
	methods: {
		onChangeLanguage() {
			this.$i18n.locale = this.selectedLocale
			this.$store.commit('settings/SET', {
				language: {
					dialog: false,
					locale: this.selectedLocale,
				},
			})
			this.$vuetify.rtl = this.$i18n.locale === 'ar' ? true : false
		},
	},
}
</script>
