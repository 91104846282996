<template>
	<div>
		<div
			v-if="label || labelIcon"
			class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-space-s-3 tw-text-47"
		>
			<v-icon v-if="labelIcon" color="#D5D5D5"> {{ labelIcon }} </v-icon>
			<span v-if="label" class="tw-font-medium tw-text-black">
				{{ label }}
			</span>
		</div>
		<v-menu
			v-model="menu"
			:disabled="readonly"
			:return-value.sync="date"
			:close-on-content-click="false"
			offset-y
			ref="menuRef"
			min-width="auto"
			transition="scale-transition"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-text-field
					v-model="date"
					v-on="{ ...on, ...$listeners }"
					v-bind="{ ...attrs, ...$attrs }"
					:rules="rules"
					:height="height"
					:class="inputClass"
					:clearable="clearable"
					:placeholder="placeholder"
					outlined
					readonly
					hide-details="auto"
					class="tw-rounded-md"
					@click:clear="date = null"
				>
					<template v-if="prependInnerIcon" #prepend-inner>
						<IconCalendar class="tw-mr-2" />
					</template>
					<template v-if="appendIcon" #append>
						<IconCalendar class="tw-mr-7" />
					</template>
				</v-text-field>
			</template>
			<v-date-picker
				v-model="date"
				no-title
				scrollable
				:min="min"
				:max="max"
			>
				<v-spacer></v-spacer>
				<v-btn text @click="menu = false">{{ $t('cancel-label') }}</v-btn>
				<v-btn text @click="$refs.menuRef.save(date)">{{ $t('ok') }}</v-btn>
			</v-date-picker>
		</v-menu>
	</div>
</template>

<script>
export default {
	name: 'BaseDatePicker',
	props: {
		height: {
			type: [String, Number],
		},
		rules: {
			type: Array,
			default: () => [],
		},
		inputClass: {
			type: String,
		},
		appendIcon: {
			type: Boolean,
			default: true,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		prependInnerIcon: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
		},
		label: {
			type: String,
		},
		labelIcon: {
			type: String,
		},
		value: {
			required: true,
		},
		placeholder: {
			type: String,
		},
		min: {
			type: String,
		},
		max: {
			type: String,
		},
	},
	data: () => ({
		menu: false,
	}),
	computed: {
		date: {
			get() {
				return this.value
			},
			set(v) {
				this.$emit('input', v)
			},
		},
	},
}
</script>
