import { view } from '../helpers'
import { PERMISSION, ROLE } from '../consts'

import DefaultLayout from '@/layouts/Default.vue'
import SupplierDashboard from '@/views/Supplier/Dashboard.vue'

const supplierRoutes = {
	path: '/supplier',
	component: DefaultLayout,
	redirect: 'supplier/dashboard',
	meta: {
		requiresRole: [ROLE.SUPPLIER],
	},
	children: [
		{
			path: 'dashboard',
			name: 'SupplierDashboard',
			component: SupplierDashboard,
			meta: {
				requiresRole: [ROLE.SUPPLIER],
				requiresAuth: true,
			},
		},
		{
			path: 'establishment',
			name: 'supplierEstablishment',
			component: view('Supplier/Establishment'),
			meta: {
				requiresRole: [ROLE.SUPPLIER],
				requiresAuth: true,
			},
		},
		{
			path: 'establishment/update',
			name: 'SupplierEstablishmentUpdate',
			component: view('Supplier/Establishment'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.SUPPLIER],
			},
		},
		{
			path: 'branches',
			name: 'supplierBranches',
			component: view('Supplier/Branches'),
			meta: {
				requiresRole: [ROLE.SUPPLIER],
				requiresAuth: true,
			},
		},
		{
			path: 'create-branch',
			name: 'createBranch',
			component: view('Supplier/CreateBranch'),
			meta: {
				requiresRole: [ROLE.SUPPLIER],
			},
		},
		{
			path: 'users',
			name: 'supplierUsers',
			component: view('Users'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.SUPPLIER],
				requirePermissions: [PERMISSION.USERS],
			},
		},
		{
			path: 'products',
			name: 'supplierProducts',
			component: view('Supplier/Products'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.SUPPLIER],
				requirePermissions: [PERMISSION.PRODUCTS],
			},
		},
		{
			path: 'offers',
			name: 'supplierOffers',
			component: view('Supplier/Offers'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.SUPPLIER],
				requirePermissions: [PERMISSION.OFFERS],
			},
		},
		{
			path: 'orders',
			name: 'supplierOrders',
			component: view('Supplier/Orders'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.SUPPLIER],
				requirePermissions: [PERMISSION.ORDERS],
			},
		},
      {
			path: 'complaints',
			name: 'SupplierComplaints',
			component: view('Complaints'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.SUPPLIER],
				requirePermissions: [PERMISSION.COMPLAINT],
			},
		},
		{
			path: 'contracts',
			name: 'SupplierContracts',
			component: view('Admin/Contracts'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.SUPPLIER],
				requirePermissions: [PERMISSION.CONTRACT],
			},
		},
		{
			path: 'ads',
			name: 'supplierAds',
			component: view('Supplier/Ads'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.SUPPLIER],
				requirePermissions: [PERMISSION.ADS],
			},
		},
		{
			path: 'invoices',
			name: 'SupplierInvoices',
			component: view('Admin/Invoices'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.SUPPLIER],
			},
		},
		{
			path: 'invoices/:id',
			name: 'SupplierInvoice',
			component: view('Admin/Invoice'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.SUPPLIER],
			},
		},
		{
			path: 'settings',
			name: 'supplierSettings',
			component: view('Settings'),
			meta: {
				requiresRole: [ROLE.SUPPLIER],
				requiresAuth: true,
			},
		},
		{
			path: '*',
			name: 'SupplierNotFound',
			component: view('404'),
			meta: {
				requiresAuth: true,
				requiresRole: [ROLE.SUPPLIER],
			},
		},
	],
}

export default supplierRoutes
