<template>
	<svg
		id="building-4-svgrepo-com"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
	>
		<path
			id="Path_34279"
			data-name="Path 34279"
			d="M0,0H24V24H0Z"
			fill="none"
		/>
		<path
			id="Path_34280"
			data-name="Path 34280"
			d="M21,20h2v2H1V20H3V3A1,1,0,0,1,4,2H20a1,1,0,0,1,1,1Zm-2,0V4H5V20ZM8,11h3v2H8ZM8,7h3V9H8Zm0,8h3v2H8Zm5,0h3v2H13Zm0-4h3v2H13Zm0-4h3V9H13Z"
			fill="#fff"
		/>
		<rect
			id="Rectangle_17559"
			data-name="Rectangle 17559"
			width="3"
			height="2"
			transform="translate(8 7)"
			fill="#c9acb1"
		/>
		<rect
			id="Rectangle_17562"
			data-name="Rectangle 17562"
			width="3"
			height="2"
			transform="translate(8 11)"
			fill="#c9acb1"
		/>
		<rect
			id="Rectangle_17564"
			data-name="Rectangle 17564"
			width="3"
			height="2"
			transform="translate(8 15)"
			fill="#c9acb1"
		/>
		<rect
			id="Rectangle_17560"
			data-name="Rectangle 17560"
			width="3"
			height="2"
			transform="translate(13 7)"
			fill="#c9acb1"
		/>
		<rect
			id="Rectangle_17561"
			data-name="Rectangle 17561"
			width="3"
			height="2"
			transform="translate(13 11)"
			fill="#c9acb1"
		/>
		<rect
			id="Rectangle_17563"
			data-name="Rectangle 17563"
			width="3"
			height="2"
			transform="translate(13 15)"
			fill="#c9acb1"
		/>
	</svg>
</template>

<script>
export default {
	name: 'IconBuilding',
}
</script>
