export const getParentElement = (el, selector) => {
	if (typeof document === 'undefined') {
		throw new Error("Can't get element outside a document")
	}

	// PARENT OF CURRENT ELEMENT
	const parent = el.parentElement

	// REACHED AT HTML, NO HOPE TO FIND PARENT
	if (parent === document.documentElement) return

	// DOES GRAND PARENT HAVE THE CHILD WITH SELECTOR
	const isMatched = !!parent.parentElement.querySelector(selector)

	// FOUND THE PARENT
	if (isMatched) return parent

	// ITERATING TO NEXT LEVEL
	return getParentElement(parent, selector)
}
