import Vue from 'vue'
import Vuex from 'vuex'
import syncLocalStorage from 'vuex-persistedstate'
import createLogger from 'vuex/dist/logger'
import { importModules } from 'vuelpers'
import { _isDev } from '../consts'

const modules = importModules(
	require.context('./modules', false, /\.store\.(js|ts)$/)
)

let plugins = [
	syncLocalStorage({
		key: '__machla__',
		paths: [
			'auth',
			'global',
			'settings.language',
			'settings.currentPayment',
			'banners',
		],
	}),
]

if (_isDev) plugins.push(createLogger())

Vue.use(Vuex)

const store = new Vuex.Store({
	modules,
	plugins,
})

export default store
